import { AutoService } from '@ov-suite/services';
import {
  AppService,
  AreaService,
  DomainService,
  CustomerService,
  EquipmentTypeService,
  FactoryAreaTypeService,
  ProductService,
  ProductCategoryService,
  ProductSkuService,
  TagService,
  UserTypeService,
  TransporterService,
  VehicleClassService,
  WorkflowProcessService,
  VehicleService
} from '@ov-suite/services-angular';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  App,
  Domain,
  ProductCategory,
  Transporter,
  UserType,
  Vehicle,
  VehicleClass,
  WorkflowProcess
} from '@ov-suite/adminlink-models';
import { Area } from '@ov-suite/adminlink-models';
import { Customer } from '@ov-suite/adminlink-models';
import { Tag } from '@ov-suite/adminlink-models';
import { EquipmentType } from '@ov-suite/adminlink-models';
import { FactoryAreaType } from '@ov-suite/adminlink-models';
import { ProductSku } from '@ov-suite/adminlink-models';
import { Product } from '@ov-suite/adminlink-models';
import { OVEntity, OVField, PageReturn } from '@ov-suite/ov-metadata';

@OVEntity('GenericStatus', 'adminlink')
class GenericStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@Injectable()
export class GenericStatusService extends AutoService<GenericStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, GenericStatus, '', '', '');
  }
}

export class MockStatusService extends GenericStatus {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<GenericStatus>> {
  //   return { data: [], totalCount: 0 };
  // }
}
export class MockAppService extends AppService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<App>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockAreaService extends AreaService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<Area>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockCustomerService extends CustomerService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<Customer>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockDomainService extends DomainService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<Domain>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockEquipmentTypeService extends EquipmentTypeService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<EquipmentType>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockFactoryAreaTypeService extends FactoryAreaTypeService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<FactoryAreaType>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockProductCategoryService extends ProductCategoryService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<ProductCategory>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockProductService extends ProductService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<Product>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockProductSkuService extends ProductSkuService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<ProductSku>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockTagService extends TagService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<Tag>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockUserTypeService extends UserTypeService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<UserType>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockTransporterService extends TransporterService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<Transporter>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockVehicleService extends VehicleService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<Vehicle>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockVehicleClassService extends VehicleClassService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<VehicleClass>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockProcessInstanceService extends WorkflowProcessService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<WorkflowProcess>> {
  //   return { data: [], totalCount: 0 };
  // }
}
