import { UserType } from '../idmlink/user-type.model';
import {
  OVEntity,
  OVField,
  OVForm,
  OVSearchable,
  OVSidebar,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('MobileListConfigStatus', 'shared')
export class MobileListConfigStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('MobileListConfig', 'shared')
@OVForm([['name', 'icon'], ['userTypes']])
@OVSidebar([['status']])
@OVTable([
  {
    type: 'string',
    key: 'name',
    title: 'Name'
  }
])
export class MobileListConfig {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVField({
    type: () => [UserType],
    keys: ['id', 'name'],
    title: 'User Type',
    tooltip:
      'Add multiple user types. This determines a users permission throughout the site.',
    selectionType: 'multiple',
    flat: true,
    required: true
  })
  userTypes: UserType[];

  @OVField({
    type: 'string',
    title: 'Icon'
  })
  icon: string;

  @OVField({
    type: () => MobileListConfigStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true
  })
  status: MobileListConfigStatus;
}
