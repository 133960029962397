import {
  OVField,
  OVTable,
  OVSearchable,
  OVEntity,
  getTaggedClass
} from '@ov-suite/ov-metadata';
import moment from 'moment';
import { Plant } from './plant.model';
import { Customer } from '../adminlink/customer.model';
import { ShippingRoute } from './shipping-route.model';
import { ShipmentStatus, Shipment } from './shipment.model';
import { Factory } from '../adminlink/factory.model';

@OVEntity('ShipmentDelivery', 'ceramic-portal')
@OVTable<ShipmentDelivery>([
  {
    title: 'Shipment',
    type: 'other',
    action: col => col.shipment?.shipmentCode,
    keys: ['shipment.shipmentCode']
  },
  {
    key: 'serviceAgent',
    title: 'Service Agent',
    type: 'string'
  },
  {
    title: 'Factory',
    type: 'other',
    action: col => col.factory?.name,
    keys: ['factory.name']
  },
  {
    title: 'Plant',
    type: 'other',
    action: col => col.plant?.name,
    keys: ['plant.name']
  },
  {
    title: 'Sold To',
    type: 'other',
    action: col => col.customer?.name,
    keys: ['customer.name']
  },
  {
    title: 'Route',
    type: 'other',
    action: col => col.route?.name,
    keys: ['route.name']
  },
  {
    key: 'netWeight',
    title: 'Net Weight',
    type: 'number'
  },
  {
    title: 'Loading',
    type: 'other',
    action: col => moment(col.loading).format('YYYY-MM-DD HH:mm:ss'),
    keys: ['loading']
  },
  {
    title: 'Delivery',
    type: 'other',
    action: col => moment(col.delivery).format('YYYY-MM-DD HH:mm:ss'),
    keys: ['delivery']
  },
  {
    title: 'Dispatched',
    type: 'other',
    action: col => moment(col.dispatched).format('YYYY-MM-DD HH:mm:ss'),
    keys: ['dispatched']
  },
  {
    title: 'Actions',
    type: 'buttons',
    buttons: [
      {
        hide: item => item.podFileUrl !== null,
        title: 'Upload POD',
        classes: 'btn-sm m-1',
        action: (item, { ngbModal, apiService }) => {
          const modalRef = ngbModal.open(
            getTaggedClass('file-upload-popup', Object),
            { size: 'md' }
          );
          modalRef.componentInstance.name = 'FileUploadComponent';
          modalRef.componentInstance.data = item;
          modalRef.componentInstance.fileURLChange.subscribe(url => {
            const shippingDelivery = new ShipmentDelivery();
            shippingDelivery.id = item.id;
            shippingDelivery.podFileUrl = url;
            apiService.update(shippingDelivery);
          });
        }
      }
    ],
    keys: ['id']
  }
])
export class ShipmentDelivery {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: () => Shipment,
    title: 'Shipment',
    placeholder: 'Required',
    required: false
  })
  shipment: Shipment;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Service Agent',
    placeholder: 'Required',
    required: false
  })
  serviceAgent: string;

  @OVSearchable()
  @OVField({
    type: () => Plant,
    title: 'Plant',
    placeholder: 'Required',
    required: false
  })
  plant: Plant;

  @OVSearchable()
  @OVField({
    type: () => [Factory],
    keys: ['id', 'name'],
    title: 'Factory',
    placeholder: 'Required',
    required: false
  })
  factory: Factory;

  @OVSearchable()
  @OVField({
    type: () => Customer,
    keys: ['id', 'name'],
    title: 'Customer',
    placeholder: 'Required',
    required: false
  })
  customer: Customer;

  @OVSearchable()
  @OVField({
    type: () => ShippingRoute,
    title: 'Route',
    placeholder: 'Required',
    required: false
  })
  route: ShippingRoute;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Net Weight',
    placeholder: 'Required',
    required: false
  })
  netWeight: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Loading',
    placeholder: 'Required',
    required: false
  })
  loading: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Delivery',
    placeholder: 'Required',
    required: false
  })
  delivery: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Dispatched',
    placeholder: 'Required',
    required: false
  })
  dispatched: string;

  @OVField({
    type: 'string',
    dropdown: true,
    title: 'Proof of Delivery',
    sidebar: true,
    generated: true
  })
  podFileUrl: string;

  @OVField({
    type: () => ShipmentStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true
  })
  status: ShipmentStatus;
}
