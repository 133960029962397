import { Injectable } from '@angular/core';
import {
  MobileStartConfig,
  MobileStartConfigStatus
} from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class MobileStartConfigService extends AutoService<MobileStartConfig> {
  constructor(private apollo: Apollo) {
    super(apollo.use('warehouselink'), MobileStartConfig, 'MobileStartConfig', 'MobileStartConfigs', 'warehouselink');
  }
}

@Injectable()
export class MobileStartConfigStatusService extends AutoService<
  MobileStartConfigStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      MobileStartConfigStatus,
      'MobileStartConfigStatus',
      'MobileStartConfigStatuses', 'warehouselink'
    );
  }
}
