<div class="main-content">
  <ov-suite-table-nav-menu
    [tabs]="tabs"
    [metadata]="metadata"
    [downloadConstructor]="formClass"
    title="Shipping Management"
    (tabClick)="onTabPress($event)"
  >
  </ov-suite-table-nav-menu>

  <ov-suite-hierarchy-table
    [title]="'Shipping List'"
    [formClass]="formClass"
    [showFiller]="false"
    [service]="shipmentService"
    [showTopBar]="true"
    [filter]="filter"
    [hasBulkUpload]="false"
    [hideAddButton]="true"
    [excludeColumns]="4"
    (itemSelect)="onItemSelect($event)"
    [reFetch]="reFetch"
    [selectableRows]="true"
  >
  </ov-suite-hierarchy-table>

  <div class="row">
    <div class="col-md-12" *ngIf="errorItems.length">
      <div class="row">
        <div class="col-md-3" *ngFor="let err of errorItems">
          <div class="card">
            <div class="card-header">
              Error:
            </div>
            <div class="card-body">{{ err.shipmentCode }} {{ err.error }}.</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bottomActionButtonsVisible()" class="col-md-12">
      <button
        [disabled]="selectedItems.length === 0 || saving"
        class="btn btn-white btn-border"
        (click)="submit(false)"
      >
        <span
          *ngIf="saving"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <i class="fa fa-close mr-2"></i>Multiple Reject
      </button>
      <button
        [disabled]="selectedItems.length === 0 || saving"
        class="btn btn-white btn-border float-right"
        (click)="submit(true)"
      >
        <span
          *ngIf="saving"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <i class="fa fa-check mr-2"></i>Multiple Accept
      </button>
    </div>
  </div>
</div>
