import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  RateService,
  RateStatusService,
  DomainService,
  RateHistoryService,
  RateChangeService
} from '@ov-suite/services-angular';
import { RatePendingChangeRoutes } from './pending-rate-changes.routing';
import { PendingRateChangesListComponent } from './list/pending-rate-changes-list.component';
import { TableTopNavMenuModule } from '../../components/table-top-nav-menu/table-top-nav-menu.module';
import { PendingRateChangesDetailComponent } from './detail/pending-rate-changes-detail.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(RatePendingChangeRoutes),
    FormsModule,
    UiModule,
    TableTopNavMenuModule
  ],
  declarations: [
    PendingRateChangesListComponent,
    PendingRateChangesDetailComponent
  ],
  providers: [
    RateService,
    RateStatusService,
    RateChangeService,
    RateHistoryService,
    DomainService
  ]
})
export class PendingRateChangesModule {}
