import { Component } from '@angular/core';
import { RateHistory } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  RateHistoryService,
} from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-rate-history-add',
  templateUrl: './rate-history-add-or-edit.component.html',
  styleUrls: ['./rate-history-add-or-edit.component.scss']
})
export class RateHistoryAddOrEditComponent extends AddOrEditHelper<RateHistory> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public rateHistoryService: RateHistoryService,
  ) {
    super(route, router, RateHistory, rateHistoryService, ['rate-history']);
    this.dataSources = {
      ...this.dataSources,
    };
  }
}
