<label class="mt-2">
  {{ title }}
  <span
    *ngIf="tooltip"
    class="fa fa-info-circle"
    placement="right-top"
    [ngbTooltip]="!!tooltip ? tipContent : ''"
  ></span>
  <ng-template #tipContent>
    <div [innerHTML]="tooltip"></div>
  </ng-template>
</label>
<div>
  <div class="row pill-multi-select" [ngClass]="{ 'border-danger': danger }">
    <div class="pill-multi-select-body">
      <input
        [id]="name"
        class="form-control search-control"
        placeholder="Search"
        [(ngModel)]="searchValue"
        (change)="onSearchChange($event)"
        (click)="toggleList()"
      />
      <div *ngIf="!this.flat" class="ts-header">
        <button *ngIf="filteredPath.length" class="ts-back" (click)="back()">
          <i class="nc-icon nc-minimal-left"></i>
        </button>
        <div class="ts-title">{{ this.currentTitle }}</div>
      </div>
      <ng-container *ngIf="listVisible">
        <div class="selectable-list-container">
          <div class="selectable-list">
            <ng-container *ngFor="let item of currentData">
              <div [ngSwitch]="selectionMap[item.id]">
                <div class="selectable-item"
                     *ngSwitchCase="'not'"
                     (click)="add($event, item)">
                  <div>
                    <span *ngFor="let key of displayKeys">
                      {{ item | displayKey: key }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="pill-list-container">
      <div class="pill" *ngFor="let item of multiValue">
        <span *ngFor="let key of displayKeys">
          {{ item | displayKey: key }}
        </span>
        <i class="fa fa-close" (click)="remove($event, item)"></i>
      </div>
    </div>
  </div>
</div>
