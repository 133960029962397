import { Routes } from '@angular/router';
import { VehicleTypeAddOrEditComponent } from './add-or-edit/vehicle-type-add-or-edit.component';
import { VehicleTypeListComponent } from './list/vehicle-type-list.component';

export const VehicleTypeRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: VehicleTypeListComponent
      },
      {
        path: 'add',
        component: VehicleTypeAddOrEditComponent
      },
      {
        path: 'edit',
        component: VehicleTypeAddOrEditComponent
      }
    ]
  }
];
