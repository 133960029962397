import { Injectable } from '@angular/core';
import {
  MobileListConfig,
  MobileListConfigStatus
} from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class MobileListConfigService extends AutoService<MobileListConfig> {
  constructor(private apollo: Apollo) {
    super(apollo.use('warehouselink'), MobileListConfig, 'MobileListConfig', 'MobileListConfigs', 'warehouselink');
  }
}

@Injectable()
export class MobileListConfigStatusService extends AutoService<
  MobileListConfigStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      MobileListConfigStatus,
      'MobileListConfigStatus',
      'MobileListConfigStatuses', 'warehouselink'
    );
  }
}
