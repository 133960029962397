import { OVField, OVTable, OVEntity } from '@ov-suite/ov-metadata';
import { ColumnData } from '@ov-suite/helpers-shared';
import moment from 'moment';

@OVEntity('ExitEntryEvent', 'yardlink')
@OVTable<ExitEntryEvent>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number'
  },
  {
    key: 'identityDisplayName',
    title: 'Identifier Name',
    type: 'string'
  },
  {
    title: 'Access Code',
    type: 'other',
    action: col => col.accessCode ?? 'None',
    keys: ['accessCode']
  },
  {
    title: 'Entry Time',
    type: 'other',
    action: col => moment(col.entryTime).format('LLL'),
    keys: ['entryTime']
  },
  {
    title: 'Exit Time',
    type: 'other',
    action: col =>
      col?.exitTime ? moment(col.exitTime).format('LLL') : 'Not Available',
    keys: ['exitTime']
  },
  {
    title: 'Duration',
    type: 'other',
    action: col =>
      col?.exitTime
        ? moment
            .duration(
              moment(col.exitTime).diff(col.entryTime, 'minute'),
              'minute'
            )
            .humanize()
        : 'Not Available',
    keys: ['exitTime', 'entryTime']
  }
  // {
  //   title: 'Workflow Process',
  //   type: 'buttons',
  //   buttons: [
  //     {
  //       action: () => {},
  //       title: 'View'
  //     }
  //   ]
  // }
])
export class ExitEntryEvent {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'string',
    title: ' Access Code',
    placeholder: 'Required',
    required: false
  })
  accessCode?: string;

  @OVField({
    type: 'date',
    title: 'Entry Time',
    tooltip: 'Entry TIme',
    placeholder: 'Required',
    required: true
  })
  entryTime: Date;

  @OVField({
    type: 'date',
    title: 'Exit Time',
    required: true,
    placeholder: 'Required',
    tooltip: 'Exit Time'
  })
  exitTime: Date;

  @OVField({
    type: 'string',
    required: true,
    tooltip: 'Workflow process instance identifier',
    title: 'Process Instance ID'
  })
  processInstanceId?: string;

  @OVField({
    type: 'string',
    required: true,
    tooltip: 'Identifier Display Name',
    title: 'Identifier Display Name'
  })
  identityDisplayName?: string;
}
