<div class="main-content">
  <ov-suite-table-nav-menu
    title="Invoice Details"
    [backButtonLink]="'/invoice'"
    [metadata]="metadata"
    [downloadConstructor]="formClass"
  >
  </ov-suite-table-nav-menu>

  <ov-suite-hierarchy-table
    *ngIf="queriedTransporter"
    [title]="'Invoice Items'"
    [formClass]="formClass"
    [service]="invoiceItemService"
    [showFiller]="false"
    [showTopBar]="true"
    [hasBulkUpload]="false"
    [filter]="filter"
    [excludeColumns]="4"
    (itemSelect)="onItemSelect($event)"
    [selectableRows]="false"
    [hideAddButton]="true"
    [hideColumnKeys]="hideColumnKeys"
    (originalData)="getData($event)"
  >
  </ov-suite-hierarchy-table>
  <div class="row" *ngIf="!invoiceBlocked">
    <div class="col-md-12">
      <button class="btn btn-dark float-right" (click)="add()">
        Add<span class="float-right ml-3"
      ><i aria-hidden="true" class="nc-icon nc-simple-add"></i
      ></span>
      </button>
    </div>
  </div>
</div>
