import {
  Component,
  OnInit,
  Inject, EventEmitter, Output
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiOptions } from '@ov-suite/ui';
import { OVTag } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface UploadedFile {
  original: string;
}

@OVTag('file-upload-popup', Object)
@Component({
  selector: 'ov-suite-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  currentFiles: FileList;
  errors = [];
  loading: boolean;
  uploaded: boolean;
  @Output() fileURLChange = new EventEmitter();

  constructor(private modalService: NgbModal, @Inject('OPTIONS') private options: ApiOptions, protected http: HttpClient) {}

  ngOnInit() {}

  onFileChange(event: Event) {
    const target: DataTransfer = (event.target as unknown) as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot upload multiple files.');
    }
    this.currentFiles = (<HTMLInputElement>event?.target)?.files;
  }

  onFileUploadClick() {
    document.getElementById('general-file-reader').click();
  }

   async onFileSave() {
    this.uploaded = false;
    this.loading = true;

     const base64 = await this.convertToBase64(this.currentFiles[0]);
     await this.http.post<UploadedFile>(
       this.options.imageUploadUrl,
       base64.replace(`data:${this.currentFiles[0].type};base64,`, ''),
       {
         observe: 'response',
         headers: {
           'content-type': this.currentFiles[0].type
         }
       }
     ).subscribe(response => {
       this.fileURLChange.emit(response.body.original);
       this.uploaded = true;
       this.loading = false;
      }, error => {
         this.loading = false;
     });
   }

  async convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  onClose() {
    this.modalService.dismissAll();
    if(this.uploaded) {
      window.location.reload();
    }
  }

}
