import { Injectable } from '@angular/core';
import {
  MobileStartConfig,
  MobileStartConfigStatus
} from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class MobileStartConfigYardService extends AutoService<
  MobileStartConfig
> {
  constructor(private apollo: Apollo) {
    super(apollo.use('yardlink'), MobileStartConfig, 'MobileStartConfig', 'MobileStartConfigs', 'yardlink');
  }
}

@Injectable()
export class MobileStartConfigStatusYardService extends AutoService<
  MobileStartConfigStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('yardlink'),
      MobileStartConfigStatus,
      'MobileStartConfigStatus',
      'MobileStartConfigStatuses', 'yardlink'
    );
  }
}
