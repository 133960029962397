import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { ProductSku, ProductSkuStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class ProductSkuService extends AutoService<ProductSku> {
  constructor(private apollo: Apollo) {
    super(apollo, ProductSku, 'ProductSku', 'ProductSkus', 'adminlink');
  }
}

@Injectable()
export class ProductSkuStatusService extends AutoService<ProductSkuStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, ProductSkuStatus, 'ProductSkuStatus', 'ProductSkuStatuses', 'adminlink');
  }
}
