import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Factory, FactoryStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class FactoryService extends AutoService<Factory> {
  constructor(private apollo: Apollo) {
    super(apollo, Factory, 'Factory', 'Factories', 'adminlink');
  }
}

@Injectable()
export class FactoryStatusService extends AutoService<FactoryStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, FactoryStatus, 'FactoryStatus', 'FactoryStatuses', 'adminlink');
  }
}
