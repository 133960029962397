import { Component } from '@angular/core';
import { Vat } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';
import { VatService } from '@ov-suite/services-angular';

@Component({
  selector: 'ov-suite-vat-add',
  templateUrl: './vat-add-or-edit.component.html',
  styleUrls: ['./vat-add-or-edit.component.scss']
})
export class VatAddOrEditComponent extends AddOrEditHelper<Vat> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public vatService: VatService
  ) {
    super(route, router, Vat, vatService, ['vat']);
    this.dataSources = {
      ...this.dataSources,
    };
  }
}
