import { Component, Inject } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { OvGenericModuleParams } from '../ov-generic.types';
import { getFieldMetadata } from "@ov-suite/ov-metadata";

@Component({
  selector: 'ov-suite-mobile-start-config',
  templateUrl: './ov-generic-list.component.html',
  styleUrls: ['./ov-generic-list.component.scss']
})
export class OvGenericListComponent {

  public isHierarchy = false;

  constructor(
    public ovAutoService: OvAutoService,
    @Inject('ENTITY_OPTIONS') public options: OvGenericModuleParams
  ) {
    const metadata = getFieldMetadata(options.entity);
    const hierarchyMarkers = [];
    for (const field of metadata.fields) {
      if (field.propertyKey === 'parent' || field.propertyKey === 'children') {
        hierarchyMarkers.push(true);
      }
      if (hierarchyMarkers.length > 1) {
        this.isHierarchy = true;
        break;
      }
    }
  }
}
