import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  RateService,
  RateStatusService,
  DomainService,
  RateHistoryService,
  RateChangeService
} from '@ov-suite/services-angular';
import { RateHistoryRoutes } from './rate-history.routing';
import { RateHistoryAddOrEditComponent } from './add-or-edit/rate-history-add-or-edit.component';
import { RateHistoryListComponent } from './list/rate-history-list.component';
import { TableTopNavMenuModule } from '../../components/table-top-nav-menu/table-top-nav-menu.module';
import { RateHistoryDetailComponent } from './detail/rate-history-detail.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(RateHistoryRoutes),
    FormsModule,
    UiModule,
    TableTopNavMenuModule
  ],
  declarations: [
    RateHistoryListComponent,
    RateHistoryAddOrEditComponent,
    RateHistoryDetailComponent
  ],
  providers: [
    RateService,
    RateStatusService,
    RateChangeService,
    RateHistoryService,
    DomainService
  ]
})
export class RateHistoryModule {}
