import { Injectable } from '@angular/core';
import { OriginType, RateChange } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class OriginTypeService extends AutoService<OriginType> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), OriginType, 'OriginType', 'OriginTypes', 'ceramic-portal');
  }
}
