import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@ov-suite/helpers-shared';
import Amplify from '@aws-amplify/core';

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  aws_project_region: environment.cognito.region,
  aws_cognito_identity_pool_id: environment.cognito.identityPoolId,
  aws_cognito_region: environment.cognito.region,
  aws_user_pools_id: environment.cognito.userPoolId,
  aws_user_pools_web_client_id: environment.cognito.clientId,
  // oauth: {},
  aws_user_files_s3_bucket: environment.cognito.bucketId,
  aws_user_files_s3_bucket_region: environment.cognito.region
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
