import { Component, OnInit } from '@angular/core';;
import {AuditTrail} from "@ov-suite/adminlink-models";
import {AuditTrailService} from "@ov-suite/services-angular";

@Component({
  selector: 'ov-suite-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit {

  formClass = AuditTrail;
  constructor(public service: AuditTrailService) { }

  ngOnInit(): void {
  }

}
