import { Component } from '@angular/core';
import { Plant } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import { PlantService, ShipmentService } from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-plant-add',
  templateUrl: './plant-add-or-edit.component.html',
  styleUrls: ['./plant-add-or-edit.component.scss']
})
export class PlantAddOrEditComponent extends AddOrEditHelper<Plant> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public plantService: PlantService,
    public shipmentService: ShipmentService
  ) {
    super(route, router, Plant, plantService, ['plant']);

    this.shipmentService.list({}).then(result => {
      this.dataSources = {
        ...this.dataSources,
        shipment: result.data
      };
    });
  }
}
