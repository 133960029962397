import { OVField, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity('ShippingRoute', 'ceramic-portal')
export class ShippingRoute {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Route Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Route Code',
    placeholder: 'Required',
    required: true
  })
  routeCode: string;
}
