import { Injectable } from '@angular/core';
import { Area, AreaStatus } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class AreaService extends AutoService<Area> {
  constructor(private apollo: Apollo) {
    super(apollo, Area, 'Area', 'Areas', 'adminlink');
  }
}

@Injectable()
export class AreaStatusService extends AutoService<AreaStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, AreaStatus, 'AreaStatus', 'AreaStatuses', 'adminlink');
  }
}
