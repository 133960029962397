<div class="main-content">
  <ov-suite-hierarchy-table
    [title]="'Add Additional Cost'"
    [formClass]="formClass"
    [service]="ceramicInvoiceItemService"
    [showFiller]="false"
    [showTopBar]="true"
    [excludeColumns]="4"
    [filter]="filter"
    [hasBulkUpload]="false"
    [hideAddButton]="true"
    [selectableRows]="false"
    [hideColumnKeys]="hideColumnKeys"
    (originalData)="getData($event)"
    [refetch]="refetch"
  >
  </ov-suite-hierarchy-table>
  <div class="col-md-12 pl-4 pr-4">
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12">
            <ov-suite-input
              title="Select Apportion"
              type="dropdown"
              [label]="['name']"
              [data]="apportionData"
              [(ngModel)]="selectedApportion"
              (ngModelChange)="factoryAmountChange()"
            >
            </ov-suite-input>
          </div>
          <div class="col-md-12">
            <ov-suite-input
              title="Invoice Item"
              type="dropdown"
              [label]="['costType']"
              [data]="dropdownData.costType"
              [(ngModel)]="invoiceItem.costType"
            >
            </ov-suite-input>
          </div>
          <div class="col-md-12">
            <ov-suite-input
              title="Shipment"
              type="dropdown"
              [label]="['shipmentCode']"
              [data]="dropdownData.shipment"
              [(ngModel)]="invoiceItem.shipment"
              (ngModelChange)="getShipmentFactories(invoiceItem.shipment)"
            >
            </ov-suite-input>
          </div>
          <div class="col-md-12">
            <ov-suite-input
              title="Amount"
              type="number"
              step="0.10"
              [(ngModel)]="invoiceItem.totalCost"
            >
            </ov-suite-input>
          </div>
          <div class="col-md-12">
            <ov-suite-input
              title="Total Net Weight"
              [disabled]="selectedApportion.id === weightId"
              type="number"
              step="0.10"
              [(ngModel)]="invoiceItem.totalWeight"
            >
            </ov-suite-input>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6" *ngFor="let factory of allFactories">
            <ov-suite-input
              [title]="factory.factory.name + '(' + factory.factory.factoryCode + ')'"
              type="number"
              [placeholder]="selectedApportion.id === weightId ? 'KG' : 'Number'"
              [(ngModel)]="factory.amount"
              (ngModelChange)="factoryAmountChange()"
            >
            </ov-suite-input>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ov-suite-input
          title="Description"
          type="text"
          [(ngModel)]="invoiceItem.description"
        >
        </ov-suite-input>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div *ngIf="result" class="float-right mt-4">
        <i class="fa fa-check text-success mr-2"></i>Saved
      </div>
      <div *ngIf="error" class="float-right mt-4">
        <i class="fa fa-remove text-danger mr-2"></i>{{error}}
      </div>
    </div>
    <div class="col-md-6">
      <button
        [disabled]="
          !invoiceItem.totalCost ||
          !invoiceItem.shipment ||
          (selectedApportion.id === weightId && !invoiceItem.totalWeight) ||
          saving"
        class="btn btn-dark float-right mr-2"
        (click)="submit()"
      >
        Submit Costs<span class="float-right ml-3"
      ><i aria-hidden="true" class="nc-icon nc-simple-add"></i
      ></span>
      </button>
    </div>
  </div>
</div>
