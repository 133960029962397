import { Routes } from '@angular/router';
import { RateChangeListComponent } from './list/rate-change-list.component';
import { RateChangeAddOrEditComponent } from './add-or-edit/rate-change-add-or-edit.component';

export const RateChangeRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: RateChangeListComponent
      },
      {
        path: 'add',
        component: RateChangeAddOrEditComponent
      },
      {
        path: 'edit',
        component: RateChangeAddOrEditComponent
      }
    ]
  }
];
