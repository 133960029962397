import { NgModule } from '@angular/core';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '@ov-suite/helpers-shared';
import { setContext } from 'apollo-link-context';
import Auth from '@aws-amplify/auth';

@NgModule({
  exports: [ApolloModule, HttpLinkModule]
})
export class GraphQLModule {
  constructor(private apollo: Apollo, private httpLink: HttpLink) {
    const authLink = setContext(async (_, { headers }) => {
      try {
        const currentSession = await Auth.currentSession();
        const token = currentSession?.getIdToken().getJwtToken() ?? '';
        return {
          headers: {
            ...headers,
            authorization: token
          }
        };
      } catch (e) {
        return headers;
      }
    });

    apollo.createDefault({
      link: authLink.concat(
        httpLink.create({
          uri: `${environment.apiUrl.admin}/graphql`
        })
      ),
      cache: new InMemoryCache()
    });
    apollo.createNamed('idmlink', {
      link: authLink.concat(
        httpLink.create({
          uri: `${environment.apiUrl.idm}/graphql`
        })
      ),
      cache: new InMemoryCache()
    });
    apollo.createNamed('adminlink', {
      link: authLink.concat(
        httpLink.create({
          uri: `${environment.apiUrl.admin}/graphql`
        })
      ),
      cache: new InMemoryCache()
    });
    apollo.createNamed('warehouselink', {
      link: authLink.concat(
        httpLink.create({
          uri: `${environment.apiUrl.warehouse}/graphql`
        })
      ),
      cache: new InMemoryCache()
    });
    apollo.createNamed('yardlink', {
      link: authLink.concat(
        httpLink.create({
          uri: `${environment.apiUrl.yard}/graphql`
        })
      ),
      cache: new InMemoryCache()
    });
    apollo.createNamed('executionlink', {
      link: authLink.concat(
        httpLink.create({
          uri: `${environment.apiUrl.execution}/graphql`
        })
      ),
      cache: new InMemoryCache()
    });
    apollo.createNamed('ceramic-portal', {
      link: authLink.concat(
        httpLink.create({
          uri: `${environment.apiUrl.ceramicPortal}/graphql`
        })
      ),
      cache: new InMemoryCache()
    });
  }
}
