<div class="main-content">
  <ov-suite-table-nav-menu
    [tabs]="tabs"
    [active]="'rate-history'"
    [metadata]="metadata"
    [downloadConstructor]="formClass"
    title="Rate Management"
    (tabClick)="onTabPress($event)"
  >
  </ov-suite-table-nav-menu>
  <ov-suite-hierarchy-table
    [title]="'Rate History'"
    [formClass]="formClass"
    [showFiller]="false"
    [service]="rateHistoryService"
    [showTopBar]="true"
    [excludeColumns]="4"
    [hideColumnKeys]="['id', 'reason']"
    [hideAddButton]="true"
  >
  </ov-suite-hierarchy-table>
</div>
