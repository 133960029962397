import { OVField, OVEntity, OVTable } from '@ov-suite/ov-metadata';
import { CeramicFactory } from './ceramic-factory.model';

@OVEntity('SharedCost', 'ceramic-portal')
@OVTable<SharedCost>([
  {
    key: 'id',
    title: 'Shared Cost ID',
    type: 'number'
  },
  {
    key: 'amount',
    title: 'Amount',
    type: 'string'
  }
])
export class SharedCost {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: () => CeramicFactory,
    title: 'Factory',
    placeholder: 'Required',
    required: true
  })
  factory: CeramicFactory;

  @OVField({
    type: 'string',
    title: 'Amount',
    placeholder: 'Required',
    required: true
  })
  amount: number;

  @OVField({
    type: 'number',
    title: 'Net Weight',
    placeholder: 'Required',
    required: true
  })
  netWeight: number;

}
