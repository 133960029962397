import { Component, OnInit } from '@angular/core';
import { DropPenaltyBand, Rate } from '@ov-suite/adminlink-models';
import { DropPenaltyBandService, RateService } from '@ov-suite/services-angular';
import { HierarchyTab } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-area',
  templateUrl: './drop-penalty-band-list.component.html',
  styleUrls: ['./drop-penalty-band-list.component.scss']
})
export class DropPenaltyBandListComponent {
  parentId?: number;

  // Class - Required
  formClass = DropPenaltyBand;

  constructor(public dropPenaltyBandService: DropPenaltyBandService) {}
}
