import { Component } from '@angular/core';
import { RateChange } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  RateChangeService,
} from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-rate-change-add',
  templateUrl: './rate-change-add-or-edit.component.html',
  styleUrls: ['./rate-change-add-or-edit.component.scss']
})
export class RateChangeAddOrEditComponent extends AddOrEditHelper<RateChange> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public rateChangeService: RateChangeService,
  ) {
    super(route, router, RateChange, rateChangeService, ['rate-change']);
    this.dataSources = {
      ...this.dataSources,
    };
  }
}
