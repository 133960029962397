import {
  OVField,
  OVTable,
  OVSearchable,
  OVEntity
} from '@ov-suite/ov-metadata';
import { ColumnData } from '@ov-suite/helpers-shared';
import moment from 'moment';
import { CeramicTransporter } from './ceramic-transporter.model';

@OVEntity('CeramicInvoiceStatus', 'ceramic-portal')
export class CeramicInvoiceStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Invoice', 'ceramic-portal')
@OVTable<CeramicInvoice>([
  {
    key: 'reference',
    title: 'Reference',
    type: 'string'
  },
  {
    title: 'Service Agent No.',
    type: 'other',
    action: col => col.transporter?.transporter?.transporterCode,
    keys: ['transporter.transporterCode']
  },
  {
    title: 'Service Agent Name',
    type: 'other',
    action: col => col.transporter?.transporter?.name,
    keys: ['transporter.name']
  },
  {
    title: 'Date',
    type: 'other',
    action: col => moment(col.createdAt).format('YYYY-MM-DD'),
    keys: ['createdAt']
  },
  {
    key: 'total',
    title: 'Total',
    type: 'string'
  },
  {
    title: 'Actions',
    type: 'buttons',
    buttons: [
      {
        routerLink: invoice => ['/invoice/detail', `${invoice.id}`],
        title: 'View',
        classes: 'btn-sm m-1'
      }
    ],
    keys: ['id']
  },
  {
    keys: ['hardLockOn'],
    title: 'Locked',
    type: 'other',
    action: col => col.hardLockOn ?
      `<div class="badge badge-danger">
        <div class="fa fa-lock mr-2"></div>Locked</div>
      </div>` :
      `<div class="badge badge-success">
        <div class="fa fa-unlock mr-2"></div>Open</div>
      </div>`
  },
])
export class CeramicInvoice {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: '',
    placeholder: 'Required',
    required: false
  })
  reference: string;

  @OVSearchable()
  @OVField({
    type: () => CeramicTransporter,
    title: '',
    placeholder: 'Required',
    required: false,
    keys: [
      'transporter.id',
      'transporter.transporterCode',
      'transporter.name'
    ]
  })
  transporter: CeramicTransporter;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: '',
    placeholder: 'Required',
    required: false
  })
  createdAt: string;

  @OVSearchable()
  @OVField({
    type: 'boolean',
    title: 'Hard Lock',
    placeholder: '',
    required: false
  })
  hardLockOn: boolean;

  @OVSearchable()
  @OVField({
    type: 'boolean',
    title: 'Hard Lock',
    placeholder: '',
    required: false
  })
  isLatest: Boolean;

  @OVSearchable()
  @OVField({
    type: 'boolean',
    title: 'Hard Lock',
    placeholder: '',
    required: false
  })
  softLockOn: Boolean;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Total',
    placeholder: 'Required',
    required: false
  })
  total: number;

  @OVField({
    type: () => CeramicInvoiceStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true
  })
  status: CeramicInvoiceStatus;
}
