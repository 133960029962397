import { Routes } from '@angular/router';
import { RateHistoryAddOrEditComponent } from './add-or-edit/rate-history-add-or-edit.component';
import { RateHistoryListComponent } from './list/rate-history-list.component';

export const RateHistoryRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: RateHistoryListComponent
      },
      {
        path: 'add',
        component: RateHistoryAddOrEditComponent
      },
      {
        path: 'edit',
        component: RateHistoryAddOrEditComponent
      }
    ]
  }
];
