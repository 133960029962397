import {
  OVField,
  OVTable,
  OVSearchable,
  OVEntity,
  getTaggedClass
} from '@ov-suite/ov-metadata';
import moment from 'moment';
import { Customer } from '../adminlink/customer.model';
import { ShippingRoute } from './shipping-route.model';
import { Shipment } from './shipment.model';
import { Factory } from '../adminlink/factory.model';
import { CeramicFactory } from './ceramic-factory.model';

@OVEntity('DeliveryStatus', 'ceramic-portal')
export class DeliveryStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Delivery', 'ceramic-portal')
@OVTable<Delivery>([
  {
    title: 'Delivery Code',
    type: 'other',
    action: col => col.deliveryCode,
    keys: ['deliveryCode'],
    disableFiltering: true
  },
  {
    title: 'Shipment',
    type: 'other',
    action: col => col.shipment?.shipmentCode,
    keys: ['shipment.shipmentCode'],
    disableFiltering: true
  },
  {
    title: 'Factory',
    type: 'other',
    action: col => col.factory?.factory.name,
    keys: ['factory.name']
  },
  {
    title: 'Sold To',
    type: 'other',
    action: col => col.customer?.name,
    keys: ['customer.name']
  },
  {
    title: 'Route',
    type: 'other',
    action: col => col.route?.routeCode,
    keys: ['route.routeCode']
  },
  {
    key: 'netWeight',
    title: 'Net Weight',
    type: 'number'
  },
  // CP-121 Hide delivery and loading dates in the view more shipment details
  // Temporary solution until column headers are able to be toggled
  // {
  //   title: 'Loading Date',
  //   type: 'other',
  //   action: col => moment(col.loadingDate).format('DD MMM YYYY, HH:mm'),
  //   keys: ['loadingDate']
  // },
  // {
  //   title: 'Delivery Date',
  //   type: 'other',
  //   action: col => moment(col.deliveryDate).format('DD MMM YYYY, HH:mm'),
  //   keys: ['deliverDate']
  // },
  {
    title: 'Dispatched',
    type: 'other',
    action: col => moment(col.dispatchedDate).isValid() ? moment(col.dispatchedDate).format('YYYY-MM-DD, HH:mm:ss'): '',
    keys: ['dispatchedDate']
  },
  {
    title: 'Actions',
    type: 'buttons',
    buttons: [
      {
        title: 'Upload POD',
        classes: 'btn-sm m-1',
        action: (item, { ngbModal, apiService }) => {
          const modalRef = ngbModal.open(
            getTaggedClass('file-upload-popup', Object),
            { size: 'md' }
          );
          modalRef.componentInstance.name = 'FileUploadComponent';
          modalRef.componentInstance.data = item;
          modalRef.componentInstance.fileURLChange.subscribe(url => {
            const shippingDelivery = new Delivery();
            shippingDelivery.id = item.id;
            shippingDelivery.podFileUrl = url;
            apiService.update(shippingDelivery);
          });
        }
      },
      {
        hide: item => !item?.podFileUrl,
        title: 'View POD',
        classes: 'btn-sm m-1',
        action: (item) => {
          window.open(item.podFileUrl, '_blank');
        }
      },
    ],
    keys: ['id', 'podFileUrl'],
    disableFiltering: true
  },
  {
    type: 'status',
    title: 'Status',
    key: 'status',
    id: 'status',
    orderKey: 'status.name',
    disableFiltering: true
  },
])
export class Delivery {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: () => Shipment,
    keys: ['id', 'shipmentCode'],
    title: 'Shipment',
    placeholder: 'Required',
    required: false,
    bulkIgnore: true
  })
  shipment: Shipment;

  @OVSearchable()
  @OVField({
    type: () => CeramicFactory,
    keys: ['factory.name'],
    title: 'Factory',
    placeholder: 'Required',
    required: false,
    bulkIgnore: true
  })
  factory: CeramicFactory;

  @OVSearchable()
  @OVField({
    type: () => Customer,
    keys: ['id', 'name'],
    title: 'Customer',
    placeholder: 'Required',
    required: false,
    exportExcludedKeys: [
      'id',
      'status',
      'area',
      'imageUrl',
      'specialInstructions',
      'primaryEmail',
      'mobileNumber',
      'description'
    ]
  })
  customer: Customer;

  @OVSearchable()
  @OVField({
    type: () => ShippingRoute,
    title: 'Route',
    placeholder: 'Required',
    required: false,
    exportExcludedKeys: ['id']
  })
  route: ShippingRoute;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Net Weight',
    placeholder: 'Required',
    required: false
  })
  netWeight: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Loading Date',
    placeholder: 'Required',
    required: false
  })
  loadingDate: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Delivery Date',
    placeholder: 'Required',
    required: false
  })
  deliveryDate: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Dispatched Date',
    placeholder: 'Required',
    required: false
  })
  dispatchedDate: string;

  @OVField({
    type: 'string',
    dropdown: true,
    title: 'Proof of Delivery',
    sidebar: true,
    generated: true
  })
  podFileUrl: string;

  @OVField({
    type: () => DeliveryStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true
  })
  status: DeliveryStatus;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Delivery Code',
    placeholder: 'Required',
    required: false
  })
  deliveryCode: string;
}
