import {OVEntity, OVField, OVSearchable, OVTable} from "@ov-suite/ov-metadata";
@OVEntity('AuditTrail', 'ceramic-portal')
@OVTable<AuditTrail>([
  {
    key: 'dateUpdated',
    title: 'Date Updated',
    type: 'date-time'
  },
  {
    key: 'username',
    title: 'Username',
    type: 'string'
  },
  {
    key: 'invoiceNumber',
    title: 'Invoice Number',
    type: 'string'
  },
  {
    key: 'rateName',
    title: 'Rate Name',
    type: 'string'
  },
  {
    key: 'transporter',
    title: 'Transporter',
    type: 'string'
  },
  {
    key: 'featureUpdated',
    title: 'Feature Updated',
    type: 'string'
  },
  {
    key: 'fieldUpdated',
    title: 'Field Updated',
    type: 'string'
  },
  {
    key: 'fieldId',
    title: 'Field ID',
    type: 'string'
  },
  {
    key: 'previousValue',
    title: 'Previous Value',
    type: 'string'
  },
  {
    key: 'newValue',
    title: 'New Value',
    type: 'string'
  },
])

export class AuditTrail {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Date Updated',
    placeholder: 'Required',
    readonly: true
  })
  dateUpdated: Date;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Username',
    placeholder: 'Required',
    readonly: true
  })
  username: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Invoice Number',
    placeholder: 'Required',
    readonly: true
  })
  invoiceNumber: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Rate Name',
    placeholder: 'Required',
    readonly: true
  })
  rateName: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Transporter',
    placeholder: 'Required',
    readonly: true
  })
  transporter: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Feature Updated',
    placeholder: 'Required',
    readonly: true
  })
  featureUpdated: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Field Updated',
    placeholder: 'Required',
    readonly: true
  })
  fieldUpdated: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Field ID',
    placeholder: 'Required',
    readonly: true
  })
  fieldId: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Previous Value',
    placeholder: 'Required',
    readonly: true
  })
  previousValue: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'New Value',
    placeholder: 'Required',
    readonly: true
  })
  newValue: string;

}
