import {
  OVField,
  OVSearchable,
  OVEntity,
  OVForm,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('PickupPenaltyBand', 'ceramic-portal')
@OVForm([
  ['#Pickup Penalty Band'],
  ['ppBand', 'ppApplication'],
  ['ppFrom', 'ppTo', 'pickupPenalty']
])
// @OVSidebar([['status'], ['id']])
@OVTable<PickupPenaltyBand>([
  {
    key: 'id',
    title: 'Pickup Penalty Band ID',
    type: 'string'
  },
  {
    key: 'ppBand',
    title: 'Pickup Penalty Band',
    type: 'string'
  },
  {
    key: 'ppFrom',
    title: 'Pickup Penalty From',
    type: 'number'
  },
  {
    key: 'ppTo',
    title: 'Pickup Penalty To',
    type: 'number'
  },
  {
    key: 'ppApplication',
    title: 'Pickup Penalty Application',
    type: 'string'
  },
  {
    key: 'pickupPenalty',
    title: 'Pickup Penalty',
    type: 'number'
  }
])
export class PickupPenaltyBand {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Pickup Penalty Band',
    placeholder: '-',
    required: true
  })
  ppBand: string;

  @OVField({
    type: 'number',
    title: 'Pickup Penalty From',
    placeholder: '-',
    required: true
  })
  ppFrom: number;

  @OVField({
    type: 'number',
    title: 'Pickup Penalty To',
    placeholder: '-',
    required: true
  })
  ppTo: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Pickup Application',
    placeholder: 'Required',
    required: true
  })
  ppApplication: string;

  @OVField({
    type: 'number',
    title: 'Pickup Penalty',
    placeholder: '-',
    required: true
  })
  pickupPenalty: number;
}
