import {
  Component,
  EventEmitter, Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AutoService, DataSources, OvAutoService } from '@ov-suite/services';
import { FormComponent } from '../form/form.component';
import { getUpdate, getCreate } from '@ov-suite/graphql-helpers';
import {
  CompiledFieldData,
  FieldMetadata,
  getFieldMetadata,
  getCompiledSidebarFieldMetadata,
  Constructor,
  GenericHierarchy, isFieldParamsConstructor, getTypeMetadata
} from '@ov-suite/ov-metadata';

type GenericHierarchyType = GenericHierarchy;

export interface Status {
  id: number;
  title: string;
  color?: string;
}

@Component({
  selector: 'ov-suite-create-or-edit',
  templateUrl: './create-or-edit.component.html',
  styleUrls: ['./create-or-edit.component.scss']
})
export class CreateOrEditComponent<T extends GenericHierarchyType>
  implements OnInit {
  @Input() service: AutoService<T>;
  @Input() ovAutoService: OvAutoService;
  @Input() api: string;
  @Input() formClass: Constructor<T>;
  private _data: T;
  private original: T;

  set data(item: T) {
    this.original = { ...item };
    this._data = item;
    this.assignDataValues();
  }
  get data() {
    return this._data;
  }

  metadata: FieldMetadata<T>;
  sidebarFields: CompiledFieldData[][] = [];

  @Input() public title: string;
  @Input() public statuses: Status[];

  @Output() public save = new EventEmitter<T>();
  @Output() public delete = new EventEmitter();
  @Output() public edit = new EventEmitter();

  @Input() public dataSources: DataSources<GenericHierarchy> = {};

  @ViewChild('form') form: FormComponent;

  loadMap: Record<string, unknown> = {};

  constructor(
    private route: ActivatedRoute,
    @Inject('DEFAULT_API') private defaultApi: string
  ) {}

  ngOnInit() {
    this.metadata = getFieldMetadata(this.formClass);
    this.sidebarFields = getCompiledSidebarFieldMetadata(this.formClass);
    this.assignDataValues();

    this.route.queryParamMap.subscribe(response => {
      if (response.has('parentId')) {
        this.data = new this.formClass();
        this.data.parent = { id: Number(response.get('parentId')) };
      } else if (response.has('id')) {
        const id = Number(response.get('id'));
        if (this.service) {
          this.service.get(id).then(item => {
            this.data = item;
          });
        } else {
          this.ovAutoService.get(this.formClass, this.api, id).then(item => {
            this.data = item;
          });
        }
      } else {
        this.data = new this.formClass();
      }
    });
  }

  assignDataValues() {
    if (this.data) {
      this.fieldForEach(data => {
        if (this.data[data.propertyKey]) {
          data.value = this.data[data.propertyKey];
        }
      });
    }
  }

  private fieldForEach(callback: (data: CompiledFieldData) => void) {
    if (this.sidebarFields) {
      this.sidebarFields.forEach(row => {
        row.forEach(column => {
          callback(column);
        });
      });
    }
  }
  private async fieldForEachAsync(
    callback: (data: CompiledFieldData) => void
  ): Promise<void> {
    if (this.sidebarFields) {
      for (const row of this.sidebarFields) {
        for (const column of row) {
          await callback(column);
        }
      }
    }
  }

  public onDelete(): void {
    // this.delete.emit();
  }

  public onEdit(): void {
    window.history.back();
  }

  public async onSave(): Promise<T | undefined> {
    let proceed = true;
    let formData;

    formData = await this.form.submit(true);
    proceed = !!formData;

    const output = <T>formData ?? this.data ?? new this.formClass();
    let requiredFlag = false;

    const validateField = this.validateField;

    async function validate(data: CompiledFieldData): Promise<void> {
      if (data.propertyKey) {
        await validateField(data);
        if (data.danger) {
          requiredFlag = true;
        }
        if (!data.readonly) {
          output[data.propertyKey] = data.value;
        }
      }
    }

    await this.fieldForEachAsync(async data => await validate(data));

    if (!requiredFlag && proceed) {
      if (output.id) {
        if (this.service) {
          this.service
            .update(getUpdate(output, this.original))
            .then(res => this.save.emit(res));
        } else {
          this.ovAutoService
            .update(this.formClass, this.api, getUpdate(output, this.original))
            .then(res => this.save.emit(res));
        }
      } else {
        if (this.service) {
          this.service
            .create(getCreate(output))
            .then(res => this.save.emit(res));
        } else {
          this.ovAutoService
            .create(this.formClass, this.api, getCreate(output))
            .then(res => this.save.emit(res));
        }
      }
      return output;
    } else {
      return;
    }
  }

  validateField = async (data: CompiledFieldData) => {
    let danger = false;
    if (data.validator) {
      const [valid, errorMessage] = await data.validator(data, null);
      if (!valid) {
        danger = true;
      }
      data.currentErrorMessage = errorMessage;
    }
    if (data.required && (data.value ?? '') === '') {
      danger = true;
    }

    data.danger = danger;
  };

  getDataSource(data: CompiledFieldData): unknown {
    if (this.dataSources && this.dataSources[data.propertyKey]) {
      return this.dataSources[data.propertyKey];
    } else if (
      this.loadMap[data.propertyKey] &&
      this.loadMap[data.propertyKey] !== 'loading'
    ) {
      return this.loadMap[data.propertyKey];
    } else {
      if (!this.loadMap[data.propertyKey]) {
        if (isFieldParamsConstructor(data)) {
          console.log('called b')
          this.loadMap[data.propertyKey] = 'loading';
          const dataType = data.withQuantity ? data.subType : data.type;
          const { entity: remoteEntity, metadata: remoteMetadata } = getTypeMetadata(dataType);
          let remoteApi = remoteMetadata.api;
          if (!remoteApi || remoteApi === 'shared') {
            remoteApi = this.defaultApi;
          }
          this.ovAutoService
            .list({
              entity: remoteEntity,
              specifiedApi: remoteApi,
              limit: 1000
            })
            .then(response => {
              this.loadMap[data.propertyKey] = response.data;
            });
        } else {
          return null;
        }
      }
    }
  }
}
