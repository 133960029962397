import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  cognito: {
    region: `eu-west-1`,
    clientId: `3sqhickmn0628eaqvnksdiikpf`,
    identityPoolId: `eu-west-1:7972434e-b6ad-4733-9ef6-ab03e59fa1e4`,
    userPoolId: `eu-west-1_3z9MA15Nc`,
    bucketId: `ovsuitebucketdevelop-develop`,
  },
  apiUrl: {
    idm: 'https://dev.api.account.ovsuite.com',
    admin: 'https://dev.api.admin.ovsuite.com',
    warehouse: 'https://dev.api.warehouse.ovsuite.com',
    yard: 'https://dev.api.yard.ovsuite.com',
    execution: 'https://dev.api.execution.ovsuite.com',
    ceramicPortal: 'https://dev.api.ceramic-portal.ovsuite.com'
  },
  webUrl: {
    idm: 'https://dev.account.ovsuite.com',
    admin: 'https://dev.admin.ovsuite.com',
    warehouse: 'https://dev.warehouse.ovsuite.com',
    yard: 'https://dev.yard.ovsuite.com',
    execution: 'https://dev.execution.ovsuite.com',
    ceramicPortal: 'https://dev.ceramic-portal.ovsuite.com'
  },
  appId: {
    idm: 8,
    admin: 9,
    warehouse: 3,
    yard: 4,
    execution: 5,
    ceramicPortal: 7
  },
  other: {
    mapApiKey: `AIzaSyAd5CAIBIGzFYxj8SU1i1VN3pTXf3O8Ugs`,
    awsFileServiceApi: `https://uun21udnjd.execute-api.eu-central-1.amazonaws.com/dev`
  }
};
