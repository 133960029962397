import {
  OVField,
  OVTable,
  OVSearchable,
  OVEntity,
  OVForm,
  CompiledFieldData
} from '@ov-suite/ov-metadata';
import { Client } from './client.model';
import { Customer } from '../adminlink/customer.model';
import { ShippingRoute } from './shipping-route.model';
import { CeramicCustomer } from './ceramic-customer.model';
import moment from 'moment';
import { CeramicTransporter } from './ceramic-transporter.model';
import { Transporter } from '../adminlink';
import { Plant } from './plant.model';
import { Delivery } from './delivery.model';

@OVEntity('ShipmentStatus', 'ceramic-portal')
export class ShipmentStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

export enum ShipmentState {
  PENDING = 'PENDING',
  PLANNED = 'PLANNED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DISPATCHED = 'DISPATCHED',
  POD = 'POD',
  INVOICE = 'INVOICE',
}

@OVEntity('Shipment', 'ceramic-portal')
@OVForm([
  ['shipmentState', ''],
  ['acceptShipmentBtn', 'rejectShipmentBtn', '']
])
@OVTable<Shipment>([
  {
    key: 'shipmentCode',
    title: 'Shipment',
    type: 'string'
  },
  {
    title: 'Transporter',
    type: 'other',
    action: col =>
      col.transporters &&
      Array.from(new Set([...col.transporters.map(transporter => transporter.transporter.name)])).join(' '),
    keys: ['id', 'transporters.transporter.name'],
    disableFiltering: true,
    disableSorting: true
  },
  {
    title: 'Plants',
    type: 'other',
    action: col =>
      col.plants &&
      Array.from(new Set([...col.plants.map(plant => plant.name)])).join(' '),
    keys: ['plants.name'],
    disableFiltering: true,
    disableSorting: true
  },
  {
    title: 'Clients',
    type: 'other',
    action: col =>
      col.clients &&
      Array.from(new Set([...col.clients.map(client => client.name)])).join(
        ' '
      ),
    keys: ['clients.name'],
    disableFiltering: true,
    disableSorting: true
  },
  {
    title: 'Customer Code',
    type: 'other',
    action: col =>
      col.customers &&
      Array.from(
        new Set([...col.customers.map(customer => customer.customerCode)])
      ).join(' '),
    keys: ['customers.customerCode'],
    disableFiltering: true,
    disableSorting: true
  },
  {
    title: 'Route',
    type: 'other',
    action: col =>
      col.routes &&
      Array.from(new Set([...col.routes.map(route => route.routeCode)])).join(
        ' '
      ),
    keys: ['routes.routeCode'],
    disableFiltering: true,
    disableSorting: true
  },
  {
    title: 'Route Name',
    type: 'other',
    action: col =>
      col.routes &&
      Array.from(new Set([...col.routes.map(route => route.name)])).join(' '),
    keys: ['routes.name'],
    disableFiltering: true,
    disableSorting: true
  },
  {
    title: 'Net Weight',
    type: 'other',
    action: col => Number(col.netWeight).toFixed(2),
    keys: ['netWeight'],
    disableFiltering: true,
    disableSorting: true
  },
  {
    title: 'Loading Date',
    type: 'other',
    action: col => moment(col.loadingDate).isValid() ? moment(col.loadingDate).format("YYYY-MM-DD"): '',
    keys:['loadingDate'],
    disableSorting: true
  },
  {
    title: 'Delivery Date',
    type: 'other',
    action: col => moment(col.deliveryDate).isValid() ? moment(col.deliveryDate).format("YYYY-MM-DD"): '',
    keys:['deliveryDate'],
    disableSorting: true
  },
  {
    title: 'Dispatched Date',
    type: 'other',
    action: col => moment(col.dispatchedDate).isValid() ? moment(col.dispatchedDate).format("YYYY-MM-DD"): '',
    keys:['dispatchedDate'],
    disableSorting: true
  },
  // CP-120 Hide Created on in the shipment manager table
  // Temporary solution until column headers can be toggled
  // {
  //   title: 'Created On',
  //   type: 'other',
  //   action: col => moment(col.createdOn).format('L'),
  //   keys: ['createdOn']
  // },
  {
    title: 'Actions',
    type: 'buttons',
    buttons: [
      {
        routerLink: (data, url) => ['/shipping/edit'],
        queryParams: item => ({ id: item.id }),
        hide: item =>
          item.shipmentState === ShipmentState.ACCEPTED ||
          item.shipmentState === ShipmentState.REJECTED,
        title: 'Accept/Reject',
        classes: 'btn-sm m-1 btn-info'
      },
      {
        routerLink: shipment => ['/shipping/detail', `${shipment.id}`],
        hide: item => item.shipmentState === ShipmentState.REJECTED,
        title: 'View More',
        classes: 'btn-sm m-1'
      }
    ],
    keys: ['id', 'shipmentState'],
    disableFiltering: true
  },
  {
    type: 'status',
    title: 'Status',
    key: 'status',
    id: 'status',
    orderKey: 'status.name',
    disableFiltering: true
  },
])
export class Shipment {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Shipment',
    placeholder: 'Required',
    readonly: true
  })
  shipmentCode: string;

  @OVSearchable()
  @OVField({
    type: () => [Plant],
    title: 'Plant',
    placeholder: 'Required',
    readonly: true,
    bulkIgnore: true
  })
  plants: Plant[];

  @OVSearchable()
  @OVField({
    type: () => [Client],
    title: 'Client',
    placeholder: 'Required',
    readonly: true,
    exportExcludedKeys: ['id', 'domainId', 'clientCode'],
    exportUnique: true
  })
  clients: Client[];

  @OVSearchable()
  @OVField({
    type: () => [CeramicCustomer],
    title: 'Customer',
    placeholder: 'Required',
    readonly: true,
    exportExcludedKeys: ['id'],
    exportUnique: true
  })
  customers: CeramicCustomer[];

  @OVSearchable()
  @OVField({
    type: () => [ShippingRoute],
    keys: ['id', 'name', 'routeCode'],
    title: 'Route',
    placeholder: 'Required',
    generated: true,
    readonly: true,
    exportExcludedKeys: ['id'],
    exportUnique: true
  })
  routes: ShippingRoute[];

  @OVSearchable()
  @OVField({
    type: () => [CeramicTransporter],
    keys: ['transporter.id', 'transporter.name'],
    title: 'Transporter',
    placeholder: 'Required',
    readonly: true,
    bulkIgnore: true
  })
  transporters: [CeramicTransporter];

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Net Weight',
    placeholder: 'Required',
    readonly: true
  })
  netWeight: number;

  @OVSearchable()
  @OVField({
    type: 'date-time',
    title: 'Loading',
    placeholder: 'Required',
    readonly: true
  })
  loadingDate: Date;

  @OVSearchable()
  @OVField({
    type: 'date-time',
    title: 'Delivery',
    placeholder: 'Required',
    readonly: true
  })
  deliveryDate: Date;

  @OVSearchable()
  @OVField({
    type: 'date-time',
    title: 'Dispatched',
    placeholder: 'Required',
    readonly: true
  })
  dispatchedDate: Date;

  @OVSearchable()
  @OVField({
    type: 'date-time',
    title: 'CreatedOn',
    placeholder: 'Required',
    readonly: true
  })
  createdOn: Date;

  @OVSearchable()
  @OVField({
    type: 'date-time',
    title: 'Updated At',
    placeholder: 'Required',
    readonly: true
  })
  updatedAt: Date;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Shipping State',
    placeholder: 'Required',
    classes: ['disabled']
  })
  shipmentState: ShipmentState;

  @OVField({
    type: () => ShipmentStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true
  })
  status: ShipmentStatus;

  @OVField({
    type: 'button',
    title: 'Accept Shipment',
    placeholder: 'Required',
    classes: ['btn', 'btn-success', 'text-center', 'btn-round', ''],
    unnecessary: true,
    bulkIgnore: true,
    action: (data: CompiledFieldData[][]) => {
      let shipmentState: CompiledFieldData;
      for (const fieldRow of data) {
        if (!shipmentState) {
          shipmentState = fieldRow.find(
            item => item.propertyKey === 'shipmentState'
          );
        }
      }

      shipmentState.value = ShipmentState.ACCEPTED;
    }
  })
  acceptShipmentBtn: string;

  @OVField({
    type: 'button',
    title: 'Reject Shipment',
    placeholder: 'Required',
    classes: ['btn', 'btn-danger', 'text-center', 'btn-round', ''],
    unnecessary: true,
    bulkIgnore: true,
    action: (data: CompiledFieldData[][]) => {
      let shipmentState: CompiledFieldData;
      for (const fieldRow of data) {
        if (!shipmentState) {
          shipmentState = fieldRow.find(
            item => item.propertyKey === 'shipmentState'
          );
        }
      }

      shipmentState.value = ShipmentState.REJECTED;
    }
  })
  rejectShipmentBtn: string;
}
