import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  DomainService,
  VatService
} from '@ov-suite/services-angular';
import { VatRoutes } from './vat.routing';
import { VatListComponent } from './list/vat-list.component';
import { VatAddOrEditComponent } from './add-or-edit/vat-add-or-edit.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(VatRoutes),
    FormsModule,
    UiModule
  ],
  declarations: [
    VatListComponent,
    VatAddOrEditComponent,
  ],
  entryComponents: [
  ],
  providers: [
    VatService,
    DomainService
  ]
})
export class VatModule {}
