import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  DomainService,
  PickupPenaltyBandService
} from '@ov-suite/services-angular';
import { PickupPenaltyBandRoutes } from './pickup-penalty-band.routing';
import { PickupPenaltyBandAddOrEditComponent } from './add-or-edit/pickup-penalty-band-add-or-edit.component';
import { PickupPenaltyBandListComponent } from './list/pickup-penalty-band-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(PickupPenaltyBandRoutes),
    FormsModule,
    UiModule
  ],
  declarations: [
    PickupPenaltyBandListComponent,
    PickupPenaltyBandAddOrEditComponent
  ],
  providers: [
    PickupPenaltyBandService,
    DomainService
  ]
})
export class PickupPenaltyBandModule {}
