import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RateRoutes } from './rate.routing';
import { RateListComponent } from './list/rate-list.component';
import { UiModule } from '@ov-suite/ui';
import { RateAddOrEditComponent } from './add-or-edit/rate-add-or-edit.component';
import {
  RateService,
  RateStatusService,
  DomainService,
  RateHistoryService,
  RateChangeService,
  PlantService,
  ShippingRouteService,
  CostTypeService,
  VehicleTypeService,
  PickupPenaltyBandService,
  DropPenaltyBandService,
  CeramicTransporterService,
  RateValidatorService
} from '@ov-suite/services-angular';
import { ViewRateComponent } from './modals/view-more/view-rate.component';
import { RateChangeComponent } from './modals/change-rate/rate-change.component';
import { TableTopNavMenuModule } from '../../components/table-top-nav-menu/table-top-nav-menu.module';
import { RateDetailComponent } from './detail/rate-detail.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(RateRoutes),
    FormsModule,
    UiModule,
    TableTopNavMenuModule
  ],
  declarations: [
    RateListComponent,
    RateAddOrEditComponent,
    RateChangeComponent,
    ViewRateComponent,
    RateDetailComponent
  ],
  providers: [
    RateService,
    RateStatusService,
    RateChangeService,
    RateHistoryService,
    DomainService,
    PlantService,
    ShippingRouteService,
    CostTypeService,
    VehicleTypeService,
    PickupPenaltyBandService,
    DropPenaltyBandService,
    CeramicTransporterService,
    RateValidatorService
  ],
  exports: [RateListComponent]
})
export class RateModule {}
