<div class="m-2">
  <div class="row justify-content-between align-content-between">
    <div class="col-sm-2 my-auto d-flex flex-row" [ngClass]="{'col-sm-10': !tabs.length}">
      <a class="icon icon-primary my-auto" [routerLink]="backButtonLink">
        <i aria-hidden="true" class="nc-icon font-weight-bold h3 text-info nc-minimal-left my-auto"></i>
      </a>
      <h5 *ngIf="title" class="my-auto ml-4 text-info"> {{title}} </h5>
    </div>
    <div class="col-sm-8" *ngIf="tabs.length">

      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <button
          *ngFor="let item of tabs; let i = index"
          (click)="onTabClick(item.key)"
          [ngClass]="{ 'active': item.key === active }"
          [class]="item.iconClass" class="btn btn-outline-info">
          {{ item?.title }}
        </button>
      </div>

    </div>
    <div class="col-sm-2" *ngIf="downloadConstructor">
      <button (click)="onDownload()" class="btn btn-block btn-info text-center"> Download </button>
      <div class="hide">
        <ov-suite-bulk-upload
          [metadata]="metadata"
          [className]="downloadConstructor"
          [callExport]="trigger"
        >
        </ov-suite-bulk-upload>
      </div>
    </div>
  </div>
</div>
