import { Component, OnInit } from '@angular/core';
import {
  CeramicFactory,
  CeramicInvoice,
  CeramicInvoiceItem,
  SharedCost,
  Shipment,
  Vat
} from '@ov-suite/adminlink-models';
import {
  AuditTrailUseService,
  CeramicFactoryService,
  CeramicInvoiceItemService,
  CeramicInvoiceService,
  CostTypeService,
  ShipmentService,
  VatService
} from '@ov-suite/services-angular';
import { getCreate } from '@ov-suite/graphql-helpers';
import { getTransporterIds } from '@ov-suite/authguard-angular';
import moment from 'moment';
interface Apportion {
  id: number,
  name: string
}

@Component({
  selector: 'ov-suite-add-invoice-item',
  templateUrl: './add-invoice-item.component.html',
  styleUrls: ['./add-invoice-item.component.scss']
})
export class AddInvoiceItemComponent implements OnInit {
  invoiceId: number;
  parentId?: number;
  percentageId = 1;
  weightId = 2;
  // Class - Required
  formClass = CeramicInvoiceItem;
  invoiceItem = new CeramicInvoiceItem();
  invoiceReference = null;
  transporter = null;
  hideColumnKeys: string[] = ['view', 'actions'];
  filter = {};
  data: CeramicInvoiceItem[] = [];
  dropdownData = {
    costType: [],
    shipment: []
  };
  allFactories: CeramicFactory[] = [];
  apportionData: Apportion[] = [
    { id: 1, name: 'Percentage %' },
    { id: 2, name: 'Weight' }
  ];
  selectedApportion: Apportion = { id: this.percentageId, name: 'percentage' };
  saving = false;
  result = null;
  refetch = 0;
  error = '';
  shipmentFactories: CeramicFactory[] = [];
  vat: Vat[] = [];

  constructor(
    public ceramicInvoiceService: CeramicInvoiceService,
    public ceramicInvoiceItemService: CeramicInvoiceItemService,
    public costTypeService: CostTypeService,
    public shipmentService: ShipmentService,
    private factoryService: CeramicFactoryService,
    private vatService: VatService,
    private auditTrailUseService: AuditTrailUseService
  ) {}

  ngOnInit(): void {
    getTransporterIds().then((res) => {
      if (!res.length) {
        delete this.hideColumnKeys[1];
      }
    });
    this.filter = { 'invoice.id': [this.invoiceId] };
    this.costTypeService.list({ limit: 10000 }).then(response => {
      this.dropdownData.costType = response.data;
    });
    this.ceramicInvoiceService.get(this.invoiceId).then(res => {
      this.invoiceReference = res.reference;
      this.transporter = res.transporter;
      this.shipmentService
        .getAllShipmentsByTransporter(res.transporter.transporter.id)
        .then(response => {
          this.dropdownData.shipment = response.data;
        });
    }, null);
    this.vatService.list({ limit: 10000 }).then(res => this.vat = res.data);
  }

  getData(data: CeramicInvoiceItem[]) {
    this.data = data;
    const factories = data
          .map(invoiceItem => invoiceItem.factories)
          .filter(i => !!i)
          .reduce((prev, cur) =>
              Array.isArray(cur) ? [...prev, ...cur] : [...prev, cur], []);
    this.allFactories = Array.from(new Set(factories.map(factory => factory.id)))
      .map(factoryId => factories.find(factory => factory.id === factoryId));
  }

  getShipmentFactories(shipment: Shipment) {
    if (shipment?.id) {
      this.factoryService.getAllFactoriesByShipment(shipment.id).then(res => {
        this.shipmentFactories = res.data;
        this.shipmentFactories.forEach(factory => {
          if (!this.allFactories.some(af => af.id === factory.id)) {
            this.allFactories.push(factory);
          }
        });
      });
    }
  }

  async submit() {
    const sharedCosts = [];
    const factoriesWithAmounts = this.allFactories.filter(f => !!f['amount']);
    if (factoriesWithAmounts.length && this.correctFactoryAmounts(factoriesWithAmounts)) {
      this.saving = true;
      this.result = null;
      for (const factory of factoriesWithAmounts) {
        const sharedCost = new SharedCost();
        sharedCost.factory = factory;
        switch (this.selectedApportion.id) {
          case this.percentageId: {
            const percentage = factory['amount'] / 100;
            sharedCost.amount = this.invoiceItem.totalCost * percentage;
            sharedCost.netWeight = (this.invoiceItem.totalWeight ?? 0) * percentage;
            break;
          }
          case this.weightId: {
            // Convert to Tons.
            const factoryWeight = factory['amount'] / 1000;
            const totalWeight = this.invoiceItem.totalWeight / 1000;
            sharedCost.amount = this.invoiceItem.totalCost * (factoryWeight / totalWeight);
            sharedCost.netWeight = factory['amount']; // If kilograms
            break;
          }
          default:
        }
        sharedCosts.push(sharedCost);
      }
      this.invoiceItem.invoice = { id: this.invoiceId } as CeramicInvoice;
      this.invoiceItem.totalWeight = this.invoiceItem.totalWeight ?? 0;
      this.invoiceItem.invoicePrice = this.invoiceItem.totalCost;
      let vat = 0;
      if (this.vat?.length) {
        const currentVat = this.vat.find(v => moment().isBetween(v.validFrom, v.validTo) && v.country === 'RSA');
        if (!!currentVat) {
          vat = currentVat.vat;
        }
      }
      this.invoiceItem.invoicePriceInclVat = this.invoiceItem.totalCost + (this.invoiceItem.totalCost * vat);
      const costCreate = sharedCosts.map(cost => getCreate<SharedCost>(cost));
      let invoiceItemCreate = getCreate<CeramicInvoiceItem>(this.invoiceItem);
      delete invoiceItemCreate['sharedCostsIdList'];
      invoiceItemCreate = { ...invoiceItemCreate, sharedCosts: costCreate };
      const audit = {
        invoiceNumber: this.invoiceReference,
        id: invoiceItemCreate['invoiceId'],
        transporter: this.transporter
      };
      this.auditTrailUseService.generateAudit(null, audit, "Invoice Additional Cost Creation").then();
      this.result = await this.ceramicInvoiceItemService.create(
        invoiceItemCreate
      );
      this.error = null;
      this.refetch++;
      this.saving = false;
      this.invoiceItem = new CeramicInvoiceItem();
    } else {
      this.error = 'Please specify the correct factory values';
    }
  }

  correctFactoryAmounts(factories: CeramicFactory[]): boolean {
    if (this.selectedApportion.id === this.percentageId) {
      return factories.reduce((a, b) => a + b['amount'], 0) === 100;
    }
    return true;
  }

  factoryAmountChange() {
    if (this.selectedApportion.id === this.weightId) {
      const factoriesWithAmounts = this.allFactories.filter(f => !!f['amount']);
      this.invoiceItem.totalWeight = factoriesWithAmounts.reduce((a, b) => a + b['amount'], 0)
    }
  }
}
