import { Injectable } from '@angular/core';
import {
  MobileListConfig,
  MobileListConfigStatus
} from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class MobileListConfigYardService extends AutoService<MobileListConfig> {
  constructor(private apollo: Apollo) {
    super(apollo.use('yardlink'), MobileListConfig, 'MobileListConfig', 'MobileListConfigs', 'yardlink');
  }
}

@Injectable()
export class MobileListConfigStatusYardService extends AutoService<
  MobileListConfigStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('yardlink'),
      MobileListConfigStatus,
      'MobileListConfigStatus',
      'MobileListConfigStatuses', 'yardlink'
    );
  }
}
