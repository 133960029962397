import { Component, OnInit } from '@angular/core';
import { WeightBand } from '@ov-suite/adminlink-models';
import { WeightBandService } from '@ov-suite/services-angular';
import { HierarchyTab } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-weight-band-list',
  templateUrl: './weight-band-list.component.html',
  styleUrls: ['./weight-band-list.component.scss']
})
export class WeightBandListComponent {
  parentId?: number;

  // Class - Required
  formClass = WeightBand;

  constructor(public weightBandService: WeightBandService) {}
}
