import { Injectable } from '@angular/core';
import { CeramicTransporter } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class CeramicTransporterService extends AutoService<CeramicTransporter> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), CeramicTransporter, 'CeramicTransporter', 'CeramicTransporters', 'ceramic-portal');
  }
}
