import { Component, OnInit } from '@angular/core';
import { OVTag } from '@ov-suite/ov-metadata';
import {
  CeramicTransporter,
  Plant,
  Rate,
  CeramicVehicleType,
  OriginType,
  Domain,
  Client,
  RateChange
} from '@ov-suite/adminlink-models';
import { NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CeramicTransporterService,
  ClientService,
  DomainService,
  OriginTypeService,
  PlantService,
  RateChangeService,
  VehicleTypeService
} from '@ov-suite/services-angular';

@OVTag('fuel-rate-change-popup', Rate)
@Component({
  selector: 'ov-suite-fuel-rate-change-modal',
  templateUrl: './fuel-rate-change.component.html',
  styleUrls: ['./fuel-rate-change.component.scss'],
  providers: [NgbAlertConfig]
})
export class FuelRateChangeComponent implements OnInit {
  ratesToChange: Rate[] = [];
  ratesChanged: RateChange[] = [];

  rateAdjustment = 0;
  adjustmentType = '%';

  Transporter = CeramicTransporter;
  selectedTransporters: CeramicTransporter[] = [];

  Plant = Plant;
  selectedPlants: Plant[] = [];

  VehicleType = CeramicVehicleType;
  selectedVehicleTypes: CeramicVehicleType[] = [];

  OriginType = OriginType;
  selectedOriginTypes: OriginType[] = [];

  Domain = Domain;
  selectedDomains: Domain[] = [];

  Client = Client;
  selectedClients: Client[] = [];

  applicationFrom = new Date();
  applicationTo = new Date();
  validFrom = new Date();
  validTo = new Date();

  name = '';
  loading: boolean;
  onSuccess: boolean;
  errors: [];

  constructor(
    protected alertConfig: NgbAlertConfig,
    public rateChangeService: RateChangeService,
    public transporterService: CeramicTransporterService,
    public plantService: PlantService,
    public vehicleTypeService: VehicleTypeService,
    public originService: OriginTypeService,
    public domainService: DomainService,
    public clientService: ClientService,
    protected modalService: NgbModal
  ) {
    alertConfig.type = 'success';
    alertConfig.dismissible = false;
  }

  ngOnInit(): void {
    this.selectedTransporters = this.getUnique('transporter');
    this.selectedPlants = this.getUnique('plant');
    this.selectedVehicleTypes = this.getUnique('vehicleType');
    this.ratesToChange = null;
  }

  getUnique(key: string) {
    return Array.from(
      new Set(this.ratesToChange.map(rate => rate[key].id))
    ).map(id => this.ratesToChange.find(rate => rate[key].id === id)[key]);
  }

  onSave() {
    this.loading = true;
    this.onSuccess = false;
    this.rateChangeService
      .adjustFuelRates(
        this.rateAdjustment / 100,
        this.adjustmentType,
        this.selectedTransporters,
        this.selectedPlants,
        this.selectedVehicleTypes,
        this.applicationFrom,
        this.applicationTo,
        this.validFrom,
        this.validTo
      )
      .then(res => {
        this.ratesChanged = res.data;
        this.loading = false;
        this.onSuccess = true;
      })
      .catch(() => {
        this.loading = false;
        this.onSuccess = true;
      });
  }

  close = () => {
    this.rateAdjustment = 0;
    this.adjustmentType = '%';
    this.ratesToChange = [];
    this.selectedTransporters = [];
    this.selectedPlants = [];
    this.selectedVehicleTypes = [];
    this.modalService.dismissAll();
  };
}
