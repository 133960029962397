import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { AutoService, OvAutoService } from '@ov-suite/services';
import {
  AbstractValueAccessor,
  MakeProvider
} from '../input/abstruct-value-accessor';
import { buildQuery } from '@ov-suite/graphql-helpers';
import {
  getFieldMetadata,
  FieldMetadata,
  SearchableMetadata,
  Constructor,
  GenericHierarchy,
  getSearchableMetadata,
  FieldParamsConstructor, getTypeMetadata
} from '@ov-suite/ov-metadata';
import { HasId } from '@ov-suite/ui';

type GenericHierarchyType = GenericHierarchy;

export interface WithQuantity<T> {
  quantity: number;
  [key: string]: T | number;
}

@Component({
  selector: 'ov-suite-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  providers: [MakeProvider(TreeSelectComponent)],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TreeSelectComponent<T extends GenericHierarchyType>
  extends AbstractValueAccessor<
    T | T[] | WithQuantity<T> | WithQuantity<T>[] | (T | WithQuantity<T>)[]
  >
  implements OnInit {
  @Input() title = '';

  @Input() tooltip = '';

  @Input() danger = false;

  @Input() flat = false;

  @Input() single = false;

  @Input() subItem: FieldParamsConstructor;

  private _withQuantity = false;
  @Input() set withQuantity(input: boolean) {
    this._withQuantity = !!input;
    if (this.metadata) {
      this.quantityKey = this.metadata.fields.find(
        item => item.propertyKey !== 'quantity' && item.propertyKey !== 'id'
      )?.propertyKey;
    }
  }
  get withQuantity() {
    return this._withQuantity;
  }

  get multiValue() {
    if (Array.isArray(this.val)) {
      return this.val;
    }
    if (!this.val) {
      return [];
    }
    return [this.val];
  }

  name: string;
  _formClass: Constructor;
  quantityKey: string;
  @Input() set formClass(formClass: Constructor | Constructor[]) {
    this._formClass = Array.isArray(formClass) ? formClass[0] : formClass;
    if (formClass) {
      this.metadata = getFieldMetadata(this._formClass);
      this.name = this.metadata.name;
      if (this.withQuantity) {
        this.quantityKey = this.metadata.fields.find(
          item => item.propertyKey !== 'quantity' && item.propertyKey !== 'id'
        ).propertyKey;
      }
    }
    if (this.name) {
      this.searchableMetadata = getSearchableMetadata(this._formClass);
    }
  }

  get formClass() {
    return this._formClass;
  }

  metadata: FieldMetadata;
  searchableMetadata: SearchableMetadata;

  currentParentId: number | string = null;

  _currentData: T[] = [];
  set currentData(data: T[]) {
    this._currentData = data;
    if (data?.length) {
      this.currentTitle = data[0].parent?.name ?? 'All';
    }
    this.calculateSelection();
  }
  get currentData() {
    return this._currentData;
  }

  currentTitle = this.title;

  filteredPath: number[] = [];

  selectedData: (T | WithQuantity<T>)[] = [];

  searchValue: string;
  searchQuery = '';

  selectionMap: {
    [key: number]: 'selected' | 'not' | 'partial' | 'unavailable';
  } = {};

  _ovAutoService: OvAutoService;
  @Input() set ovAutoService(service: OvAutoService) {
    this._ovAutoService = service;
    if (service) {
      this.reset();
    }
  }

  get ovAutoService() {
    return this._ovAutoService;
  }

  _service: AutoService<T>;
  @Input() set service(service: AutoService<T>) {
    this._service = service;
    if (service) {
      this.reset();
    }
  }

  get service() {
    return this._service;
  }

  constructor(@Inject('DEFAULT_API') private defaultApi: string) {
    super();
  }

  ngOnInit() {}

  calculateSelection() {
    this.currentData?.forEach(item => {
      this.selectionMap[item.id] = this.getSelectionType(item);
    });
  }

  getSelectionType(item: T): 'selected' | 'not' | 'partial' | 'unavailable' {
    if (this.flat) {
      return this.multiValue.some(entry => entry.id === item.id)
        ? 'selected'
        : 'not';
    }
    const path = item.path.slice(0, -1).split('.');
    path.pop();
    const unavailable = this.multiValue.some(entry =>
      path.includes(entry.id.toString())
    );
    if (unavailable) {
      return 'unavailable';
    }
    const found = this.multiValue.filter(entry =>
      this.withQuantity
        ? (<T>(<WithQuantity<T>>entry)[this.quantityKey]).path.includes(
            item.id + '.'
          )
        : (<T>entry).path.includes(item.id + '.')
    );
    if (found?.length) {
      if (found.some(entry => entry.id === item.id)) {
        return 'selected';
      }
      return 'partial';
    }
    return 'not';
  }

  writeValue(
    value:
      | T
      | T[]
      | WithQuantity<T>
      | WithQuantity<T>[]
      | (T | WithQuantity<T>)[]
  ) {
    this.val = value;
    this.onChange(value);
    this.calculateSelection();
  }

  getSearch(input?: number | string): Record<string, string[]> {
    // const query = this.flat
    //   ? ''
    //   : buildQuery(this.name, { parentId: { eq: input ?? null } });
    // return this.searchQuery ? this.searchQuery : query;

    return this.flat ? {} : { parentId: [input ? input.toString() : null] };
  }

  async select(item: T) {
    if (!this.flat && this.selectionMap[item.id] === 'not') {
      this.filteredPath = item.path
        .slice(0, -1)
        .split('.')
        .map(entry => Number(entry));
      this.currentTitle = item.name;
      this.searchValue = '';
      this.searchQuery = '';
      if (this.service) {
        this.service
          .list({
            limit: 100,
            query: this.getSearch(item.id)
          })
          .then(response => {
            this.currentData = response.data;
            this.currentParentId = item.id;
          });
      } else {
        console.log('called c');
        let api = this.metadata.api;
        if (!api || api === 'shared') {
          api = this.defaultApi;
        }
        this.ovAutoService
          .list({
            entity: this.formClass as Constructor<HasId>,
            limit: 100,
            query: this.getSearch(item.id),
            specifiedApi: api
          })
          .then(response => {
            this.currentData = response.data as T[];
            this.currentParentId = item.id;
          });
      }
    }
  }

  async back() {
    if (this.flat) {
      return;
    }
    this.filteredPath.pop();
    const parentId = this.filteredPath.length
      ? this.filteredPath[this.filteredPath.length - 1]
      : undefined;
    if (this.service) {
      this.service
        .list({
          search: {}, //this.getSearch(parentId),
          query: this.getSearch(parentId),
          limit: 100,
          offset: 0
        })
        .then(response => {
          this.currentData = response.data;
          this.currentParentId = parentId ?? null;
        });
    } else {
      console.log('called D')
      let api = this.metadata.api;
      if (!api || api === 'shared') {
        api = this.defaultApi;
      }
      this.ovAutoService
        .list({
          entity: this.formClass as Constructor<{ id: string | number }>,
          specifiedApi: api,
          search: {}, //this.getSearch(parentId),
          query: this.getSearch(parentId),
          limit: 100,
          offset: 0
        })
        .then(response => {
          this.currentData = response.data as T[];
          this.currentParentId = parentId ?? null;
        });
    }
  }

  async reset() {
    if (this.service) {
      this.service
        .list({
          search: {}, //this.getSearch(parentId),
          query: this.getSearch(),
          limit: 100,
          offset: 0
        })
        .then(response => {
          this.currentData = response.data;
        });
    } else {
      console.log('called E', this.subItem, this.title);
      const subItemType = this.subItem.withQuantity ? this.subItem.subType : this.subItem.type;
      const { entity } = getTypeMetadata(subItemType);
      let api = this.metadata.api;
      if (!api || api === 'shared') {
        api = this.defaultApi;
      }
      this.ovAutoService
        .list({
          entity,
          specifiedApi: api,
          search: {}, //this.getSearch(parentId), // todo fix this page
          query: this.getSearch(),
          limit: 100,
          offset: 0
        })
        .then(response => {
          this.currentData = response.data as T[];
        });
    }
  }

  onSearchChange(event: Event): void {
    if ((<HTMLInputElement>event?.target)?.value) {
      this.search((<HTMLInputElement>event.target).value);
    } else {
      this.searchQuery = '';
      this.reset();
    }
  }

  search(text: string): void {
    let filter = '';
    this.searchableMetadata.fields.forEach(field => {
      if (filter) {
        filter += ` OR "${this.name}"."${field.propertyKey}" ILIKE '%${text}%'`;
      } else {
        filter = `"${this.name}"."${field.propertyKey}" ILIKE '%${text}%'`;
      }
    });

    this.searchQuery = `(${filter})`;

    this.reset();
  }

  async cautionAdd(event, item: T) {
    event?.stopPropagation();
    if (
      item &&
      confirm('This will replace other items further down the tree')
    ) {
      await this.add(event, item);
    }
    return;
  }

  async add(event, item: T) {
    event?.stopPropagation();
    let actualItem: T | WithQuantity<T> = item;

    if (this.withQuantity) {
      actualItem = { quantity: 1, [this.quantityKey]: item };
    }

    if (this.single) {
      this.writeValue(actualItem);
      return;
    }
    this.selectedData.push(actualItem);
    let val;
    if (!this.val) {
      val = [actualItem];
    } else {
      if (this.flat) {
        val = [...this.multiValue, actualItem];
      } else {
        val = [
          ...this.multiValue.filter(existing =>
            this.withQuantity
              ? !existing[this.quantityKey].path.includes(`${actualItem.id}.`)
              : !(<T>existing).path.includes(`${actualItem.id}.`)
          ),
          actualItem
        ];
      }
    }

    this.writeValue(val);
  }

  remove(event, item: T | WithQuantity<T>) {
    event?.stopPropagation();
    if (this.single) {
      this.writeValue(null);
      return;
    }
    this.writeValue(
      this.multiValue.filter(selected =>
        this.withQuantity
          ? (<T>(<WithQuantity<T>>selected)[this.quantityKey])?.id !==
            (<T>(<WithQuantity<T>>item)[this.quantityKey])?.id
          : (<T>selected).id !== (<T>item).id
      )
    );
  }

  forceUpdate() {
    this.onChange(this.value);
  }
}
