<div class="main-content">
  <ov-suite-table-nav-menu
    [tabs]="tabs"
    [active]="'rate-change'"
    [metadata]="metadata"
    [downloadConstructor]="formClass"
    title="Rate Management"
    (tabClick)="onTabPress($event)"
  >
  </ov-suite-table-nav-menu>
  <ov-suite-hierarchy-table
    [title]="'Rate Change Requests'"
    [formClass]="formClass"
    [showFiller]="false"
    [service]="rateChangeService"
    [showTopBar]="true"
    [excludeColumns]="4"
    [hideAddButton]="true"
    [hideColumnKeys]="['status']"
    [filter]="filter"
    (itemSelect)="onItemSelect($event)"
    [selectableRows]="true"
  >
  </ov-suite-hierarchy-table>
  <div class="row">
    <div class="col-md-6">
      <button
        [disabled]="selectedItems.length === 0"
        class="btn btn-white btn-border"
        (click)="decline()"
      >
        <i class="nc-icon nc-simple-remove"></i>&nbsp;Reject Changes
      </button>
    </div>
    <div class="col-md-6">
      <button
        [disabled]="selectedItems.length === 0"
        class="btn btn-white btn-border float-right"
        (click)="approve()"
      >
        <i class="nc-icon nc-check-2"></i>&nbsp;Accept Changes
      </button>
    </div>
  </div>
</div>
