import { Injectable } from '@angular/core';
import {
  MobileStartConfig,
  MobileStartConfigStatus
} from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class MobileStartConfigExecutionService extends AutoService<
  MobileStartConfig
> {
  constructor(private apollo: Apollo) {
    super(apollo.use('executionlink'), MobileStartConfig, 'MobileStartConfig', 'MobileStartConfigs', 'executionlink');
  }
}

@Injectable()
export class MobileStartConfigStatusExecutionService extends AutoService<
  MobileStartConfigStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('executionlink'),
      MobileStartConfigStatus,
      'MobileStartConfigStatus',
      'MobileStartConfigStatuses',
      'executionlink'
    );
  }
}
