import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { AccessCode } from '@ov-suite/adminlink-models';

@Injectable()
export class AccessCodeService extends AutoService<AccessCode> {
  constructor(private apollo: Apollo) {
    super(apollo.use('yardlink'), AccessCode, 'AccessCode', 'AccessCodes', 'yardlink');
  }
}
