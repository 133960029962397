import { Component } from '@angular/core';
import { WeightBand } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  WeightBandService,
} from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-weight-band-add',
  templateUrl: './weight-band-add-or-edit.component.html',
  styleUrls: ['./weight-band-add-or-edit.component.scss']
})
export class WeightBandAddOrEditComponent extends AddOrEditHelper<WeightBand> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public weightBandService: WeightBandService,
  ) {
    super(route, router, WeightBand, weightBandService, ['weight-band']);
    this.dataSources = {
      ...this.dataSources,
    };
  }
}
