import { Component, OnInit } from '@angular/core';
import { CostType, Rate } from '@ov-suite/adminlink-models';
import { CostTypeService, RateService } from '@ov-suite/services-angular';
import { HierarchyTab } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-cost-type-list',
  templateUrl: './cost-type-list.component.html',
  styleUrls: ['./cost-type-list.component.scss']
})
export class CostTypeListComponent {
  parentId?: number;

  // Class - Required
  formClass = CostType;

  constructor(public costTypeService: CostTypeService) {}
}
