<div class="main-content">
  <ov-suite-table-nav-menu
    [tabs]="tabs"
    [active]="'pending-rate-changes'"
    [metadata]="metadata"
    [downloadConstructor]="formClass"
    title="Rate Management"
    (tabClick)="onTabPress($event)"
  >
  </ov-suite-table-nav-menu>
  <ov-suite-hierarchy-table
    [title]="'Pending Rate Changes'"
    [formClass]="formClass"
    [showFiller]="false"
    [service]="rateChangeService"
    [filter]="filter"
    [additionalSearch]="search"
    [showTopBar]="true"
    [excludeColumns]="4"
    [hideColumnKeys]="['id', 'reason', 'actions']"
    [hideAddButton]="true"
  >
  </ov-suite-hierarchy-table>
</div>
