<div class="main-content">
  <ov-suite-table-nav-menu
    title="Delivery"
    [metadata]="metadata"
    [downloadConstructor]="formClass"
    [filterQuery]="filter"
    [backButtonLink]="'/shipping'">
  </ov-suite-table-nav-menu>

  <ov-suite-hierarchy-table
    [title]="'Delivery List'"
    [formClass]="formClass"
    [service]="deliveryService"
    [showFiller]="false"
    [showTopBar]="true"
    [filter]="filter"
    [hasBulkUpload]="false"
    [excludeColumns]="false"
    (itemSelect)="onItemSelect($event)"
    [hideAddButton]="true"
    [selectableRows]="true"
  >
  </ov-suite-hierarchy-table>

  <div class="row">
    <div class="col-md-12">
      <button [disabled]="selectedItems.length === 0"
              class="btn btn-white btn-border"
              (click)="onUpload()">
        <i class="fa fa-upload mr-2"></i>Upload Multiple PODs'
      </button>
    </div>
  </div>

</div>
