<div class="user">
  <div class="photo">
    <img *ngIf="!userAvatar" src="./assets/img/placeholder.jpg" alt="avatar" />
    <img *ngIf="!!userAvatar" [src]="userAvatar" alt="avatar" />
  </div>
  <div class="info">
    <a
      id="user-profile-link"
      data-toggle="collapse"
      (click)="navigate()"
      class="collapsed"
      placement="bottom"
      ngbTooltip="Click here to edit your profile."
    >
     <span class="collapsed"> {{ userFullName }} </span>
    </a>
    <div class="clearfix"></div>
  </div>
</div>
