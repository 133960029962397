import { Component, OnInit } from '@angular/core';
import { CeramicInvoice, InvoiceItemStatus } from '@ov-suite/adminlink-models';
import {AuditTrailUseService, CeramicInvoiceService} from '@ov-suite/services-angular';
import { TableTopNavTab } from '../../../components/table-top-nav-menu/table-top-nav-menu-tabs.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { hasFeaturePermission, PermissionAction } from '@ov-suite/authguard-angular';
import { FeaturesConfig } from '../../../features.config';
import { getFieldMetadata } from '@ov-suite/ov-metadata';
import swal from 'sweetalert2';

@Component({
  selector: 'ov-suite-shipping',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit{
  // Class - Required
  formClass = CeramicInvoice;
  metadata = getFieldMetadata(this.formClass);
  selectedItems: CeramicInvoice[] = [];
  canFinalizeInvoice:  boolean;
  tabs: TableTopNavTab[] = [
    { title: 'All', key: 'all' },
    { title: 'Unpaid Invoice', key: 'UNPAID' },
    { title: 'Paid Invoice', key: 'PAID' }
  ];

  constructor(
    public ceramicInvoiceService: CeramicInvoiceService,
    private ngbModal: NgbModal,
    private auditTrailUseService: AuditTrailUseService
  ) {}

  ngOnInit(): void {
    hasFeaturePermission(FeaturesConfig.Invoice_Finalize, PermissionAction.UPDATE).then(result=> {
      this.canFinalizeInvoice = result;
    });
    }

  onItemSelect(data) {
    this.selectedItems = data;
  }

  async onMarkAsFinalized() {
    swal
      .fire({
        title: 'Finalize Invoice',
        text: 'Are you sure, you want to finalize this invoice?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      })
      .then(async reason => {
        if (reason.value) {
          for (const row of this.selectedItems) {
            const index = this.selectedItems.indexOf(row);
            if (row.hardLockOn === false) {
              const invoice = new CeramicInvoice();
              invoice.id = row.id;
              invoice.hardLockOn = true;
              invoice.softLockOn = true;
              await this.ceramicInvoiceService.update(invoice).then(async () =>
                await this.auditTrailUseService.generateAudit(null, row, 'Finalised Invoice').then(() => console.log('in audit'))
              ).catch(err => {
                console.log(`Invoice: (${index}), Error: ${err}`);
              });
            }
          }
        }
      }).catch()
      .finally(() =>
        swal.fire({
          title: 'Finalized',
          type: 'success',
          confirmButtonText: 'Done',
        }).then().catch()
      );
  }
}
