import { OVField, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity('CeramicCustomer', 'ceramic-portal')
export class CeramicCustomer {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Transporter Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Code',
    placeholder: 'Required',
    required: true
  })
  customerCode: string;
}
