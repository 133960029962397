import { Component, OnInit } from '@angular/core';
import { OVTag } from '@ov-suite/ov-metadata';
import { RateChange } from '@ov-suite/adminlink-models';
import { NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RateChangeService } from '@ov-suite/services-angular';
import { getUpdate } from '@ov-suite/graphql-helpers';
import { cloneDeep } from 'lodash';

@OVTag('rate-decline-popup', RateChange)
@Component({
  selector: 'ov-suite-rate-decline-modal',
  templateUrl: './rate-decline.component.html',
  styleUrls: ['./rate-decline.component.scss'],
  providers: [NgbAlertConfig]
})
export class RateDeclineComponent implements OnInit {
  name = '';
  rateChanges: RateChange[] = [];
  reason: '';
  loading: boolean;
  onSuccess: boolean;
  errors = [];

  constructor(
    protected alertConfig: NgbAlertConfig,
    private rateChangeService: RateChangeService,
    protected modalService: NgbModal
  ) {
    alertConfig.type = 'success';
    alertConfig.dismissible = false;
  }

  ngOnInit(): void {
    if (Object.keys(this.rateChanges[0]).includes('isSelected')) {
      this.rateChanges = cloneDeep(this.rateChanges);
      this.rateChanges.forEach(rate => delete rate['isSelected']);
    }
  }

  onSave() {
    this.loading = true;
    this.onSuccess = false;
    const change = this.rateChanges[0];
    const old = { ...change };
    change.changeStatus = 'REJECTED';
    change.reason = this.reason;
    this.rateChangeService
      .updateMultipleRateChanges(getUpdate(change, old), this.rateChanges)
      .then(res => {
        if (!res.data.length) {
          this.errors.push('Not updated something went wrong');
        }
        this.loading = false;
        this.onSuccess = true;
      })
      .catch(() => {
        this.loading = false;
        this.onSuccess = true;
      });
  }

  close = () => {
    this.rateChanges = [];
    this.modalService.dismissAll();
  };
}
