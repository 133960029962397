import {
  OVField,
  OVSearchable,
  OVEntity,
  OVForm,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('Plant', 'ceramic-portal')
@OVForm([['id', 'name', 'plantCode']])
// @OVSidebar([['status'], ['id']])
@OVTable<Plant>([
  {
    key: 'id',
    title: 'Plant ID',
    type: 'string'
  },
  {
    key: 'name',
    title: 'Plant Name',
    type: 'string'
  },
  {
    key: 'plantCode',
    title: 'Plant Code',
    type: 'string'
  }
])
export class Plant {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Plant Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Plant Code',
    placeholder: 'Required',
    required: true
  })
  plantCode: string;
}
