<!--Example Usage-->
<!--
  <ov-suite-input [type]="'text'"
                  [(ngModel)]="someText" [placeholder]="'Please enter some text'"
                  (ngModelChange)="test(someText)" name="df">
  </ov-suite-input>
  <ov-suite-input [data]="genderData" [type]="'multi-select'" [label]="'name'"
                  [(ngModel)]="selectedGender" [placeholder]="'Please select a val'"
                  (ngModelChange)="test(selectedGender)" name="dfsdfds">
  </ov-suite-input>
  <ov-suite-input [type]="'number'"
                  [(ngModel)]="number" [placeholder]="0"
                  (ngModelChange)="test(number)" name="sat">
  </ov-suite-input>
-->
<div *ngIf="type !== 'checkbox'" class="form-group">
  <ng-container [ngSwitch]="type">
    <label *ngIf="inputLabel || title" class="mt-2" [for]="id"
      >{{ inputLabel || title }}
      <span
        *ngIf="tooltip"
        aria-hidden="true"
        class="fa fa-info-circle"
        placement="right-top"
        [ngbTooltip]="!!tooltip ? tipContent : ''"
      ></span>
      <ng-template #tipContent><div [innerHTML]="tooltip"></div></ng-template>
    </label>
    <div
      [ngClass]="{
        'input-parent': type !== 'map',
        'input-parent-disabled': disabled,
        'border-danger': danger
      }"
    >
      <div *ngSwitchCase="'date'">
        <div class="form-group">
          <ov-suite-date-time
            [id]="id"
            [ngModel]="value"
            [showTime]="false"
            (ngModelChange)="valueChange($event)"
            [disabled]="disabled"
          ></ov-suite-date-time>
        </div>
      </div>

      <div *ngSwitchCase="'date-time'">
        <div class="form-group">
          <ov-suite-date-time
            [id]="id"
            [ngModel]="value"
            (ngModelChange)="valueChange($event)"
            [disabled]="disabled"
          ></ov-suite-date-time>
        </div>
      </div>

      <div *ngSwitchCase="'text'">
        <input
          [id]="id"
          [type]="type"
          class="form-control"
          [disabled]="disabled"
          [placeholder]="!!placeholder ? placeholder : ''"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)"
        />
      </div>
      <div *ngSwitchCase="'password'">
        <input
          [id]="id"
          [type]="type"
          class="form-control"
          autocomplete="new-password"
          [disabled]="disabled"
          [placeholder]="!!placeholder ? placeholder : ''"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)"
        />
      </div>
      <div *ngSwitchCase="'email'">
        <input
          [id]="id"
          [type]="type"
          class="form-control"
          [disabled]="disabled"
          [placeholder]="!!placeholder ? placeholder : ''"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)"
        />
      </div>
      <div *ngSwitchCase="'number'">
        <input
          [id]="id"
          [type]="type"
          [step]="step"
          class="form-control"
          [placeholder]="placeholder"
          [ngModel]="value"
          [disabled]="disabled"
          (ngModelChange)="valueChange(+$event)"
        />
      </div>
      <div *ngSwitchCase="'dropdown'">
        <div
          aria-hidden="true"
          class="fa fa-circle status-icon"
          *ngIf="!!statusColor"
          [ngStyle]="{ color: statusColor }"
        ></div>
        <select
          [id]="id"
          class="form-control"
          [compareWith]="compare"
          [ngModel]="value"
          [disabled]="disabled"
          (ngModelChange)="valueChange($event)"
        >
          <option *ngIf="!!placeholder" value="" disabled selected>{{
            placeholder
          }}</option>
          <option *ngFor="let item of data" [ngValue]="item">
            <span *ngIf="label.length === 0">
              {{item['name']}}
            </span>
            <span *ngFor="let key of label">
              {{ item | displayKey: key}}
            </span>
          </option>
        </select>
      </div>
      <div *ngSwitchCase="'map'">
        <ov-suite-agm-map
          [id]="id"
          [disabled]="disabled"
          [danger]="danger"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)"
        ></ov-suite-agm-map>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="type === 'checkbox'" class="ov-checkbox">
  <input [id]="id" type="checkbox" [checked]="value" (change)="check()" />
  <label [for]="id">{{ inputLabel || title }}</label>
</div>
