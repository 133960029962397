import { OVEntity, OVField, OVForm } from '@ov-suite/ov-metadata';

@OVEntity('InventoryThreshold', 'adminlink')
@OVForm([['amberTriggerValue', 'redTriggerValue']])
export class InventoryThreshold {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'number',
    title: 'Amber Trigger Value',
    tooltip: 'Amber Trigger Value'
  })
  amberTriggerValue: number;

  @OVField({
    type: 'number',
    title: 'Red Trigger Value',
    tooltip: 'Red Trigger Value'
  })
  redTriggerValue: number;

  @OVField({
    type: 'number',
    title: 'Sku Id',
    tooltip: 'Sku Identification Number'
  })
  skuId: number;
}
