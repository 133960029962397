import { Component } from '@angular/core';
import { RateChange } from '@ov-suite/adminlink-models';
import { RateChangeService } from '@ov-suite/services-angular';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RateTabs } from '../../../components/table-top-nav-menu/tabs/rate-tabs';
import { RateDeclineComponent } from '../modals/rate-decline/rate-decline.component';
import { RateApprovalComponent } from '../modals/rate-approval/rate-approval.component';
import { getFieldMetadata } from '@ov-suite/ov-metadata';

@Component({
  selector: 'ov-suite-rate-change-list',
  templateUrl: './rate-change-list.component.html',
  styleUrls: ['./rate-change-list.component.scss']
})
export class RateChangeListComponent {
  parentId?: number;
  // Class - Required
  formClass = RateChange;
  selectedItems: RateChange[] = [];
  tabs = RateTabs;
  filter = { changeStatus: ['PENDING'] };
  metadata = getFieldMetadata(this.formClass);

  constructor(
    public rateChangeService: RateChangeService,
    private router: Router,
    private ngbModal: NgbModal
  ) {}

  onItemSelect(event: RateChange[]) {
    this.selectedItems = event;
  }

  onTabPress(event: string) {
    this.router.navigate([event]).then();
  }

  approve() {
    const modalRef = this.ngbModal.open(RateApprovalComponent);
    modalRef.componentInstance.name = 'Approve Rate Changes';
    modalRef.componentInstance.rateChanges = this.selectedItems;
  }

  decline() {
    const modalRef = this.ngbModal.open(RateDeclineComponent);
    modalRef.componentInstance.name = 'Reject Rate Change';
    modalRef.componentInstance.rateChanges = this.selectedItems;
  }
}
