import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Equipment, EquipmentStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class EquipmentService extends AutoService<Equipment> {
  constructor(private apollo: Apollo) {
    super(apollo, Equipment, 'Equipment', 'Equipment', 'adminlink');
  }
}

@Injectable()
export class EquipmentStatusService extends AutoService<EquipmentStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, EquipmentStatus, 'EquipmentStatus', 'EquipmentStatuses', 'adminlink');
  }
}
