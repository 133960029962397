import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AutoService, DataSources } from '@ov-suite/services';
import { Directive, OnInit } from '@angular/core';
import { Constructor, GenericHierarchy } from '@ov-suite/ov-metadata';

@Directive()
export class AddOrEditHelper<T extends GenericHierarchy> implements OnInit {
  testClass: Constructor<T>;
  data: T;

  parentId?: number;
  id?: number;

  dataSources: DataSources<GenericHierarchy> = {};

  constructor(
    private helperRoute: ActivatedRoute,
    private helperRouter: Router,
    public entity: Constructor<T>,
    private primaryService: AutoService<T>,
    private listRouterLink: string[]
  ) {
    this.testClass = entity;
  }

  ngOnInit() {
    this.helperRoute.queryParamMap.subscribe(params => {
      if (params.has('parentId')) {
        this.parentId = Number(params.get('parentId')) ?? null;
      } else if (params.has('id')) {
        this.id = Number(params.get('id')) ?? null;
        if (this.id) {
          this.primaryService.get(this.id).then(response => {
            this.data = response;
          });
        }
      } else {
        this.helperRoute.url.subscribe(url => {
          if (url?.length && url[0].path === 'edit') {
            this.helperRouter.navigate(this.listRouterLink);
          }
        });
      }
    });
  }

  navigateBack() {
    const options: NavigationExtras = {};
    if (this.parentId) {
      options.queryParams = { parentId: this.parentId };
    }
    this.helperRouter.navigate(this.listRouterLink, options);
  }
}
