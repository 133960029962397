import { Routes } from '@angular/router';
import { OvGenericListComponent } from './list/ov-generic-list.component';
import { OvGenericAddOrEditComponent } from './add-or-edit/ov-generic-add-or-edit.component';

export const OvGenericRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: OvGenericListComponent
      },
      {
        path: 'add',
        component: OvGenericAddOrEditComponent
      },
      {
        path: 'edit',
        component: OvGenericAddOrEditComponent
      }
    ]
  }
];
