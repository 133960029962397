<div class="modal-body pt-0 pr-3 pl-3">
  <div class="row">
    <div class="col-md-12">
      <button
        type="button"
        class="btn btn-link float-left mr-3"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true" class="nc-icon nc-minimal-left"></span>
      </button>
      <h5 id="modal-basic-title" class="mb-0 mt-2 pt-1">
        {{ name }}
      </h5>
    </div>
  </div>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <ov-suite-input
          id="rate"
          type="number"
          [step]="0.01"
          [title]="'Adjust Fuel Rate (%)'"
          [tooltip]="'All rates will be adjusted according to selected values.'"
          [(ngModel)]="rateAdjustment"
        >
        </ov-suite-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ov-suite-pill-multi-select
          [tooltip]="
            'All rates with the same transporter will receive the change.'
          "
          [displayKeys]="['transporter.name']"
          [single]="false"
          [flat]="true"
          [title]="'Transporter'"
          [formClass]="Transporter"
          [service]="transporterService"
          [(ngModel)]="selectedTransporters"
        >
        </ov-suite-pill-multi-select>
      </div>
      <div class="col-md-6">
        <ov-suite-pill-multi-select
          [tooltip]="'All rates with the same plant will receive the change.'"
          [single]="false"
          [flat]="true"
          [title]="'Plant'"
          [formClass]="Plant"
          [service]="plantService"
          [(ngModel)]="selectedPlants"
        >
        </ov-suite-pill-multi-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ov-suite-pill-multi-select
          [tooltip]="
            'All rates with the same vehicle type will receive the change.'
          "
          [single]="false"
          [flat]="true"
          [displayKeys]="['name']"
          [title]="'Vehicle Class'"
          [formClass]="VehicleType"
          [service]="vehicleTypeService"
          [(ngModel)]="selectedVehicleTypes"
        >
        </ov-suite-pill-multi-select>
      </div>
      <!--      <div class="col-md-6">-->
      <!--        <ov-suite-pill-multi-select-->
      <!--          [tooltip]="'All rates with the same Client type will receive the change.'"-->
      <!--          [single]="false"-->
      <!--          [flat]="true"-->
      <!--          [title]="'Client'"-->
      <!--          [formClass]="Client"-->
      <!--          [service]="clientService"-->
      <!--          [(ngModel)]="selectedClients"-->
      <!--        >-->
      <!--        </ov-suite-pill-multi-select>-->
      <!--      </div>-->
    </div>
    <!--    <div class="row">-->
    <!--      <div class="col-md-6">-->
    <!--        <ov-suite-pill-multi-select-->
    <!--          [tooltip]="'All rates with the same Domains will receive the change.'"-->
    <!--          [single]="false"-->
    <!--          [flat]="true"-->
    <!--          [title]="'Domain'"-->
    <!--          [formClass]="Domain"-->
    <!--          [service]="domainService"-->
    <!--          [(ngModel)]="selectedDomains"-->
    <!--        >-->
    <!--        </ov-suite-pill-multi-select>-->
    <!--      </div>-->
    <!--      <div class="col-md-6">-->
    <!--        <ov-suite-pill-multi-select-->
    <!--          [tooltip]="'All rates with the same Origin Types will receive the change.'"-->
    <!--          [single]="false"-->
    <!--          [flat]="true"-->
    <!--          [displayKeys]="['className']"-->
    <!--          [title]="'Origin Type'"-->
    <!--          [formClass]="OriginType"-->
    <!--          [service]="originService"-->
    <!--          [(ngModel)]="selectedOriginTypes"-->
    <!--        >-->
    <!--        </ov-suite-pill-multi-select>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="row">
      <div class="col-md-6">
        <ov-suite-input
          id="applicationFrom"
          [type]="'date-time'"
          [title]="'Select the application start range that should apply.'"
          [tooltip]="'Application From'"
          [danger]="!applicationFrom"
          [(ngModel)]="applicationFrom"
        >
        </ov-suite-input>
      </div>
      <div class="col-md-6">
        <ov-suite-input
          id="applicationTo"
          [type]="'date-time'"
          [title]="'Select the application end range that should apply.'"
          [tooltip]="'Application To'"
          [danger]="!applicationTo"
          [(ngModel)]="applicationTo"
        >
        </ov-suite-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ov-suite-input
          id="rateFrom"
          [type]="'date-time'"
          [title]="'Select New Validity From Date Range'"
          [tooltip]="'Valid From'"
          [danger]="!validFrom"
          [(ngModel)]="validFrom"
        >
        </ov-suite-input>
      </div>
      <div class="col-md-6">
        <ov-suite-input
          id="rateTo"
          [type]="'date-time'"
          [title]="'Select New Validity To Date Range'"
          [tooltip]="'Valid To'"
          [danger]="!validTo"
          [(ngModel)]="validTo"
        >
        </ov-suite-input>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-12">
      <button
        *ngIf="!onSuccess"
        [disabled]="
          loading ||
          !validTo ||
          !validFrom ||
          !applicationFrom ||
          !applicationTo
        "
        (click)="onSave()"
        class="btn btn-secondary float-right"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span *ngIf="loading" class="sr-only">Loading...</span>
        <span *ngIf="!loading" class="">Adjust</span>
      </button>
      <div *ngIf="!loading && onSuccess" class="row">
        <div class="text-center col-md-12">
          <h6>
            <i class="text-success fa fa-check"></i>&nbsp; Adjusted
            {{
              ratesChanged.length === 1
                ? '1 Rate'
                : ratesChanged.length + ' Rates'
            }}.
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
