<div class="main-content">
<ov-suite-hierarchy-table
  [title]="'Audit Trail'"
  [formClass]="formClass"
  [showFiller]="false"
  [service]="service"
  [showTopBar]="true"
  [hideAddButton]="true"
  [showScroll]="true"
  [excludeColumns]="0"
  [hasBulkUpload]="false"
>
</ov-suite-hierarchy-table>
</div>
