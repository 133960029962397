import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import moment from 'moment';
import {
  ColumnData,
  ColumnDataButton,
  ColumnDataColumns,
  mapDeepObjects
} from '@ov-suite/helpers-shared';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HasId } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T extends HasId> implements OnInit {
  constructor(private router: Router, public ngbModal: NgbModal) {}

  selectedIndex = -1;

  pages: number[] = [];
  filler: null[] = [];

  // public columnHider = false;

  public _columnHider = false;

  @Input()
  set columnHider(val: boolean) {
    this.toggleColumnHider();
    this._columnHider = val;
  }

  get columnHider(): boolean {
    return this._columnHider;
  }

  filteredColumns: string[] = [];

  @Input() keyStore: string;

  @Input() service;

  @Input() loading = false;

  @Input() showIndex = true;

  @Input() currentPage = 0;

  @Input() striped = true;

  @Input() selectableRows = false;

  @Input() showFiller = true;

  @Input() editableRows = false;
  isEditable = 'isEditable';
  hasChanged = 'hasChanged';
  editItemTip = `
    Double click to make modifications.
    Double click on the row again to confirm the changes.`;
  newItemCount = 0;

  @Input() dropdownData = {};

  @Output() itemsEdited = new EventEmitter<T[]>();

  @Output() itemSelect = new EventEmitter<T[]>();

  @Output() filterChange = new EventEmitter<Record<string, string[]>>();

  @Output() orderChange = new EventEmitter<{
    column: string;
    direction: 'ASC' | 'DESC';
    data: ColumnData<T>;
  }>();

  filterMap: Record<string, string[]> = {};

  @Input() order: {
    column: string;
    direction: 'ASC' | 'DESC';
    data: ColumnData<T>;
  } = {
    column: 'id',
    direction: 'ASC',
    data: {
      key: 'id',
      type: 'string',
      title: ''
    }
  };

  isSelected = 'isSelected';

  private _totalCount = 10;
  @Input()
  set totalCount(num: number) {
    this._totalCount = num;
    this.currentPage = 0;
    this.updatePages();
  }
  get totalCount() {
    return this._totalCount;
  }
  private _pageSize = 10;
  @Input()
  set pageSize(num: number) {
    this._pageSize = num;
    this.updatePages();
  }
  get pageSize() {
    return this._pageSize;
  }

  _data: T[];

  @Input() set data(data: T[]) {
    this._data = data;
    if (this.showFiller && data?.length < this.pageSize) {
      this.filler = new Array(this.pageSize - data.length).fill(null);
    } else {
      this.filler = [];
    }
    if (this.selectableRows) {
      this._data.forEach(item => (item[this.isSelected] = false));
    }
    if (this.editableRows) {
      this._data.forEach(item => (item[this.isEditable] = false));
    }
    this.getExtraColumns();
  }
  get data() {
    return this._data;
  }

  extraColumns: { data: string[]; columnData: ColumnDataColumns<T> } = {
    data: [],
    columnData: null
  };
  filteredColumnData: ColumnData<T>[];
  @Input() columnData: ColumnData<T>[];

  @Output() changePage = new EventEmitter();
  @Output() changePageSize = new EventEmitter();

  totalPages = 1;

  defaultHiddenKeys = []
  @Input()
  set hideColumnKeys(event: string[]) {
    this.defaultHiddenKeys = event;
    if (!!event && event.length) {
      this.columnData = this.columnData.filter(
        col => !this.defaultHiddenKeys.some(dhk => dhk === col.hideColumnKey || dhk === col.id)
      );
    }
  }

  routerLink(button: ColumnDataButton<T>, data: T): void {
    if (!button.routerLink) {
      return;
    }
    const url = this.router.url;
    const options: NavigationExtras = {
      queryParams: button.queryParams ? button.queryParams(data, url) : {}
    };
    this.router.navigate(button.routerLink(data, url), options);
  }

  updatePageSize(value){
    this.changePageSize.emit(value);
  }

  updatePages(): void {
    const pagesRaw = this._totalCount / this._pageSize;
    let pages = Math.floor(pagesRaw);
    if (pages !== pagesRaw) {
      pages += 1;
    }
    this.totalPages = pages;
    if (pages > 5) {
      this.pages = new Array(5)
        .fill('test')
        .map((x, i) => i + this.currentPage);
    } else {
      this.pages = new Array(pages).fill('test').map((x, i) => i);
    }
  }

  @Input() select: (item: T) => void = () => {};
  @Input() back: (item?: T) => void = () => {};

  shiftPages() {
    if (this.totalPages > 5) {
      if (this.pages[0] >= 0 && this.pages[4] <= this.totalPages + 1) {
        let factor = 0;
        if (this.currentPage - 2 <= 0) {
          factor = 2 - this.currentPage;
        } else if (this.currentPage >= this.totalPages - 2) {
          factor = this.totalPages - this.currentPage - 3;
        }
        this.pages = [
          this.currentPage - 2 + factor,
          this.currentPage - 1 + factor,
          this.currentPage + factor,
          this.currentPage + 1 + factor,
          this.currentPage + 2 + factor
        ];
      }
    }
  }

  getDefaultFilteredColumns(): string[] {
    return this.columnData
      .filter(col => col.startHidden || this.defaultHiddenKeys.includes(col.hideColumnKey))
      .map(col => col.id);
  }

  ngOnInit() {
    if (this.keyStore) {
      try {
        this.filteredColumns =
          JSON.parse(localStorage.getItem(this.keyStore)) ??
          this.getDefaultFilteredColumns();
        if (this.filteredColumns.length) {
          this.filteredColumnData = this.columnData.filter(
            col => !this.filteredColumns.includes(col.id)
          );
        }else {
          this.filteredColumnData = this.columnData;
        }
      } catch (e) {
        localStorage.removeItem(this.keyStore);
        this.filteredColumnData = this.columnData;
      }
    }

    if (!this.data) {
      throw new TypeError("'data' is required");
    }
    if (!this.columnData) {
      throw new TypeError("'columnData' is required");
    }
  }

  persistColumnFilters() {
    if (this.keyStore) {
      localStorage.setItem(this.keyStore, JSON.stringify(this.filteredColumns));
    }
  }

  public getDate(date?: Date): string {
    if (!date) {
      return '';
    }
    return moment(date).format('DD MMM YYYY');
  }

  public getDateTime(date?: Date): string {
    if (!date) {
      return '';
    }
    return moment(date).format('DD MMM YYYY, HH:mm');
  }

  public pageForward(): void {
    this.selectPage(this.currentPage + 1);
  }

  public pageLast(): void {
    this.selectPage(this.totalPages - 1);
  }

  public pageBack(): void {
    this.selectPage(this.currentPage - 1);
  }

  public pageFirst(): void {
    this.selectPage(0);
  }

  public selectPage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.changePage.emit(page);
      this.currentPage = page;
      this.shiftPages();
      this.selectedIndex = -1;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent & { target: { localName: string } }) {
    if (event.target?.localName === 'input') {
      return;
    }

    switch (event.key) {
      case 'ArrowRight':
        event.stopPropagation();
        event.preventDefault();
        this.pageForward();
        break;
      case 'ArrowLeft':
        event.stopPropagation();
        event.preventDefault();
        this.pageBack();
        break;
      case 'ArrowDown':
        event.stopPropagation();
        event.preventDefault();
        this.selectedDown();
        break;
      case 'ArrowUp':
        event.stopPropagation();
        event.preventDefault();
        this.selectedUp();
        break;
      case ' ':
      case 'Enter':
        event.stopPropagation();
        event.preventDefault();
        if (this.selectedIndex >= 0 && this.selectedIndex < this.pageSize) {
          this.select(this.data[this.selectedIndex]);
        }
        break;
      case 'Backspace':
        event.stopPropagation();
        event.preventDefault();
        this.back();
    }
  }

  selectedDown() {
    if (
      this.selectedIndex <
      Math.min(this.pageSize, this.data?.length ?? 0) - 1
    ) {
      this.selectedIndex += 1;
    } else {
      this.selectedIndex = -1;
    }
  }

  selectedUp() {
    if (this.selectedIndex > -1) {
      this.selectedIndex -= 1;
    } else {
      this.selectedIndex = Math.min(this.pageSize, this.data?.length ?? 0) - 1;
    }
  }

  onSelect(event, item?: T): void {
    if (!item) {
      if (event.target.checked) {
        this.data.forEach(d => (d[this.isSelected] = true));
      } else {
        this.data.forEach(d => (d[this.isSelected] = false));
      }
    } else {
      item[this.isSelected] = !item[this.isSelected];
    }
    const selection: T[] = [...this.data.filter(d => d[this.isSelected])];
    this.itemSelect.emit(selection);
  }

  isAllSelected(): boolean {
    return this.data.every(item => item[this.isSelected] === true);
  }

  addNewItem(): void {
    const copy = {} as T;
    copy['newId'] = ++this.newItemCount;
    copy[this.isEditable] = true;
    this.data.push(copy as T);
  }

  itemChanged(item: T): void {
    item[this.hasChanged] = true;
  }

  editItems(item: T): void {
    if (this.editableRows) {
      item[this.isEditable] = !item[this.isEditable];
    }
    if (item[this.isEditable] === false) {
      this.emitItemChanges();
    } else {
      if (this.extraColumns.data.length) {
        // matches ngModel when editing.
        this.extraColumns.data.forEach(column => {
          if (item['column_' + column] && item['column_' + column] >= 0) {
            item['column_' + column] = item['column_' + column];
          } else {
            item['column_' + column] = this.extraColumns.columnData?.rowAction(
              item,
              column
            );
          }
        });
      }
    }
  }

  emitItemChanges(): void {
    this.itemsEdited.emit([...this.data.filter(d => d[this.hasChanged])]);
  }

  cancel(item: T): void {
    if (item['newId']) {
      this.data = this.data.filter(d => d['newId'] !== item['newId']);
    } else {
      item[this.hasChanged] = false;
    }
    this.emitItemChanges();
  }

  hasChanges(): boolean {
    return this.data.some(d => d[this.hasChanged] === true);
  }

  getExtraColumns(): void {
    const column = this.columnData?.find(
      d => d.type === 'column'
    ) as ColumnDataColumns<T>;
    if (column) {
      this.extraColumns.columnData = column;
      const propertyItems = this.data.map(d => d[column.key]).filter(i => !!i);
      this.extraColumns.data = Array.from(
        new Set(
          propertyItems
            .reduce(
              (prev, cur) =>
                Array.isArray(cur) ? [...prev, ...cur] : [...prev, cur],
              []
            )
            .map(item => mapDeepObjects(column.columnKey, item) as string)
        )
      );
    }
  }

  toggleColumnHider() {
    if (this.columnHider) {
      this.filteredColumnData = this.columnData.filter(
        col => !this.filteredColumns.includes(col.id)
      );
    } else {
      this.filteredColumnData = this.columnData;
    }
    // this.columnHider = !this.columnHider;
  }

  isColVisible(id: string) {
    return !this.filteredColumns.includes(id);
  }

  toggleCol(id: string, event?: Event) {
    event?.stopPropagation();
    if (this.filteredColumns.includes(id)) {
      this.filteredColumns = this.filteredColumns.filter(item => item !== id);
    } else {
      this.filteredColumns.push(id);
    }
    this.persistColumnFilters();
  }

  onFilterChange(columnData: ColumnData<T>, event) {
    const value = event?.target?.value;
    if (columnData.filterKey) {
      this.filterMap[columnData.filterKey] = [value];
    } else if (columnData.type === 'buttons' || columnData.type === 'other') {
      columnData.keys.forEach(key => {
        if (value) {
          this.filterMap[key] = [value];
        } else {
          delete this.filterMap[key];
        }
      });
    } else if (columnData.type === 'status') {
      this.filterMap['status.name'] = [value];
    } else {
      this.filterMap[columnData.key as string] = [value];
    }
    this.filterChange.emit(this.filterMap);
  }

  onColumnHeaderClick(column: ColumnData<T>) {
    if (!this.columnHider) {
      if (this.order.column === column.id) {
        this.order.direction = this.order.direction === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.order.column = column.id;
        this.order.direction = 'ASC';
        this.order.data = column;
      }
      this.orderChange.emit(this.order);
    } else {
      this.toggleCol(column.id);
    }
  }
}
