import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { ExitEntryEvent } from '@ov-suite/adminlink-models';

@Injectable()
export class ExitEntryEventService extends AutoService<ExitEntryEvent> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('yardlink'),
      ExitEntryEvent,
      'ExitEntryEvent',
      'ExitEntryEvents', 'yardlink'
    );
  }
}
