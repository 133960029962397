import { Injectable } from '@angular/core';
import { RateHistory } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class RateHistoryService extends AutoService<RateHistory> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), RateHistory, 'RateHistory', 'RateHistory', 'ceramic-portal');
  }
}
