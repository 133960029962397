import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService, OvAutoServiceListParams } from '@ov-suite/services';
import { WorkflowProcess } from '@ov-suite/adminlink-models';
import gql from 'graphql-tag';
import { PageReturn } from '@ov-suite/ov-metadata';

@Injectable()
export class WorkflowProcessService extends AutoService<WorkflowProcess> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      WorkflowProcess,
      'ProcessInstance',
      'ProcessInstances',
      'warehouselink'
    );
  }

  async list(params: Omit<OvAutoServiceListParams<WorkflowProcess>, 'entity'>): Promise<PageReturn<WorkflowProcess>> {
   const { keys: {}, ...param } = params;

    const response = this.apollo.use('warehouselink').query({
      query: gql(`query listProcessInstances($params: ListParamsInput!){
        listProcessInstances(params: $params){
         data {
            id
            name
            ended
            processDefinitionId
            processDefinitionName
            processDefinitionDescription
            startTime
            completed
          }
          total
          start
          sort
          size
        }
      }`),
     variables: { params: { ...param } }
    });

    const responseData = await response.toPromise();
    const totalCount = responseData?.data['listProcessInstances']['total'];
    return { data: responseData?.data['listProcessInstances']['data'], totalCount } as PageReturn<WorkflowProcess>;
  }

  public getById = (id: string) => {
    return this.apollo.use('warehouselink').query({
      query: gql(`query getProcessInstance($id: String!){
        getProcessInstance(id: $id){
        id
        url
        name
        suspended
        ended
        processDefinitionId
        processDefinitionUrl
        processDefinitionName
        processDefinitionDescription
        activityId
        startUserId
        startTime
        callbackId
        callbackType
        tenantId
        completed
        }
      }`),
      variables: { id: id }
    });
  };

  public deleteProcess = (processInstanceId: string) => {
    return this.apollo.use('warehouselink').mutate({
      mutation: gql(`mutation deleteProcessInstance($processInstanceId: String!){
        deleteProcessInstance(processInstanceId: $processInstanceId)
      }`),
      variables: { processInstanceId: processInstanceId }
    });
  };
}
