import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  AbstractValueAccessor,
  MakeProvider
} from '../input/abstruct-value-accessor';
import { App, Feature, UserTypeFeature } from '@ov-suite/adminlink-models';
import { AutoService } from '@ov-suite/services';

@Component({
  selector: 'ov-suite-permission-matrix',
  templateUrl: './permission-matrix.component.html',
  styleUrls: ['./permission-matrix.component.scss'],
  providers: [MakeProvider(PermissionMatrixComponent)],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PermissionMatrixComponent
  extends AbstractValueAccessor<UserTypeFeature[]>
  implements OnInit {
  @Input() service: AutoService<App>;

  allApps: App[];

  constructor() {
    super();
  }

  val: UserTypeFeature[] = [];

  helperMap: { [key: number]: UserTypeFeature } = {};

  writeValue(value: UserTypeFeature[]) {
    this.val = value;
    this.onChange(value);

    if (!value) {
      this.val = [];
    }
    this.val.forEach((userTypeFeature: UserTypeFeature) => {
      this.helperMap[userTypeFeature.feature.id] = userTypeFeature;
    });
  }

  ngOnInit() {
    this.service.list({ limit: 1000 }).then(res => {
      this.allApps = res.data;
      this.synchronise();
    });
  }

  synchronise() {
    if (this.allApps?.length) {
      const newFeatures = [];
      this.allApps.forEach(app => {
        app.features.forEach(feature => {
          if (!this.helperMap[feature.id]) {
            const newUserTypeFeature = new UserTypeFeature();
            newUserTypeFeature.feature = feature;
            newUserTypeFeature.permission = 0;
            newFeatures.push(newUserTypeFeature);
          }
        });
      });
      this.writeValue([...this.val, ...newFeatures]);
    }
  }

  hasPermission(value: number, column: 1 | 2 | 4 | 8): boolean {
    return (value | column) === value;
  }

  toggleAll(feature: Feature): void {
    this.helperMap[feature.id].permission =
      this.helperMap[feature.id].permission !== 15 ? 15 : 0;
  }

  checkPermission(feature: Feature, column: 1 | 2 | 4 | 8): void {
    if (
      (this.helperMap[feature.id].permission | column) ===
      this.helperMap[feature.id].permission
    ) {
      this.helperMap[feature.id].permission -= column;
    } else {
      this.helperMap[feature.id].permission += column;
    }
  }
}
