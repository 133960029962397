import { Pipe, PipeTransform } from '@angular/core';
import {
  camelCaseToTitleCase,
  kebabCaseToNormalCase,
  propKeyToTitle,
  toTitleCase
} from '@ov-suite/helpers-shared';

@Pipe({ name: 'normalise'})
export class NormaliseString implements PipeTransform {

  transform(value: string, caseType: 'camalCase' | 'kebabCase' | 'kebabAndTitleCase'): string {
    switch (caseType) {
      case 'camalCase': return value.includes('.') ? propKeyToTitle(value) : camelCaseToTitleCase(value);
      case 'kebabCase': return kebabCaseToNormalCase(value);
      case 'kebabAndTitleCase': return toTitleCase(kebabCaseToNormalCase(value));
      default : return toTitleCase(value);
    }
  }
}
