import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from './abstruct-value-accessor';
import moment from 'moment';

@Component({
  selector: 'ov-suite-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [MakeProvider(InputComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent<T> extends AbstractValueAccessor<T | boolean> {
  @Input() id: string;
  @Input() data: T[] | number | string | boolean;
  @Input() type: string;
  @Input() placeholder: string | number;
  @Input() tooltip: string;
  @Input() disabled: boolean;
  @Input() inputLabel: string;
  @Input() danger: boolean;
  @Input() step = 0;
  @Input() title: string; // todo: cleanup title and inputLabel

  // Exclusive to Multi-Select
  @Input() label: string[];
  @Input() statusColor: string;

  constructor() {
    super();
  }

  valueChange(item: T) {
    this.writeValue(item);
  }

  check() {
    this.val = !this.val;
    this.onChange(this.val);
  }

  compare(object1, object2): boolean {
    return object1 && object2 ? object1.id === object2.id : object1 === object2;
  }

  formatDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }
}
