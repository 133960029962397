import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { ReasonCode } from '@ov-suite/adminlink-models';

@Injectable()
export class ReasonCodeService extends AutoService<ReasonCode> {
  constructor(private apollo: Apollo) {
    super(apollo, ReasonCode, 'ReasonCode', 'ReasonCodes', 'adminlink');
  }
}
