<div class="main-content">
  <ov-suite-table-nav-menu
    title="Invoice Management"
  >
  </ov-suite-table-nav-menu>

  <ov-suite-hierarchy-table
    [title]="'Invoice List'"
    [formClass]="formClass"
    [showFiller]="false"
    [service]="ceramicInvoiceService"
    [showTopBar]="true"
    [hideAddButton]="true"
    [showScroll]="true"
    [hasBulkUpload]="false"
    [excludeColumns]="4"
    (itemSelect)="onItemSelect($event)"
    [selectableRows]="true"
    [api]="'ceramic-portal'"
  >
  </ov-suite-hierarchy-table>
  <div class="row">
    <div class="col-md-6">
      <button *ngIf="canFinalizeInvoice"
        [disabled]="selectedItems.length === 0"
        class="btn btn-white btn-border"
        (click)="onMarkAsFinalized()"
      >
        <i class="fa fa-lock mr-2"></i>Admin Finalize
      </button>
    </div>
  </div>
</div>
