import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';
import { Vehicle } from '../adminlink/vehicle.model';

@OVEntity('Trip', 'executionlink')
@OVTable<Trip>([
  {
    key: 'id',
    title: 'Trip ID',
    type: 'string'
  },
  {
    key: 'plannedDate',
    title: 'Planned Date',
    type: 'string'
  },
  {
    title: 'Vehicle',
    type: 'other',
    action: data => data?.vehicle?.name,
    keys: ['vehicle.name']
  },
  {
    title: 'Stops',
    type: 'number',
    key: 'stops'
  }
])
export class Trip {
  @OVField({
    type: 'string',
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: () => Vehicle,
    tooltip: 'Select a vehicle.',
    keys: ['id', 'name'],
    looselyCoupled: true
  })
  vehicle: Vehicle;

  @OVField({
    type: 'number'
  })
  stops: number;

  @OVField({ type: 'string' })
  plannedDate: string;
}
