<div class="main-content overlap">
  <div class="overlap">
    <ov-suite-create-or-edit
      [ovAutoService]="ovAutoService"
      [api]="options.api"
      [formClass]="options.entity"
      [title]="options.createTitle"
      (save)="navigateBack()"
    >
    </ov-suite-create-or-edit>
  </div>
</div>
