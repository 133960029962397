import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ShippingManagementRoutes } from './shipping-management.routing';
import { ShippingListComponent } from './list/shipping-list.component';
import { UiModule } from '@ov-suite/ui';
import { DeliveryService, ShipmentService } from '@ov-suite/services-angular';
import { ShippingAddOrEditComponent } from './add-or-edit/shipping-add-or-edit.component';
import { DeliveryListComponent } from './delivery-list/delivery-list.component';
import { TableTopNavMenuModule } from '../../components/table-top-nav-menu/table-top-nav-menu.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ShippingManagementRoutes),
    FormsModule,
    UiModule,
    TableTopNavMenuModule
  ],
  declarations: [ShippingListComponent, ShippingAddOrEditComponent, DeliveryListComponent],
  providers: [ShipmentService, DeliveryService]
})
export class ShippingManagementModule {}
