import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import {Shipment, ShipmentStatus } from '@ov-suite/adminlink-models';
import { mapToClass, PageReturn } from '@ov-suite/ov-metadata';
import gql from 'graphql-tag';

@Injectable()
export class ShipmentService extends AutoService<Shipment> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), Shipment, 'Shipment', 'Shipments', 'ceramic-portal');
  }
  async getAllShipmentsByTransporter(
    id: number,
  ): Promise<PageReturn<Shipment>> {
    const name = `listShipmentsByTransporterWithCount`;
    return new Promise((resolve, reject) => {
      this.apollo.use('ceramic-portal')
        .query({
          query: gql(
            `query ` +
            `${name}($transporterId: Int!) {\n` +
            `${name}(transporterId: $transporterId) {\n` +
            `data {\n` +
              `id
              shipmentCode` +
            `}\n` +
            `totalCount\n` +
            `\n}\n}`
          ),
          variables: { transporterId: id},
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data[name].data;
          const totalCount = response.data[name].totalCount;
          const data = rawData.map(item => mapToClass(Shipment, item));
          resolve({ data, totalCount });
        }, reject);
    });
  }
}

@Injectable()
export class ShipmentStatusService extends AutoService<ShipmentStatus> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), ShipmentStatus, 'ShipmentStatus', 'ShipmentStatuses', 'ceramic-portal');
  }
}
