<div class="hierarchy-breadcrumb">
  <div
    *ngFor="let item of data; let i = index"
    class="hb-block"
    [ngClass]="{ 'hb-selected': i === data.length - 1 }"
  >
    <a
      [routerLink]="[]"
      [queryParams]="{ parentId: item?.id }"
      (mouseover)="viewDropdown(i)"
      (mouseout)="setDropdownTimeout(i)"
    >
      {{ item?.name }}
      <i
        aria-hidden="true"
        class="fa"
        [ngClass]="showTooltip[i] === true ? 'fa-angle-up' : 'fa-angle-down'"
      ></i>
    </a>
    <div
      class="hb-dropdown"
      [hidden]="!showTooltip[i]"
      (mouseover)="viewDropdown(i)"
      (mouseout)="setDropdownTimeout(i)"
    >
      <div class="hb-dropdown-item" *ngFor="let child of item.children">
        <a
          [routerLink]="[]"
          [queryParams]="{ parentId: child?.id }"
          (mouseover)="viewDropdown(i)"
          (mouseout)="setDropdownTimeout(i)"
        >
          {{ child?.name }}
        </a>
      </div>
    </div>
  </div>
</div>
