import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { FormioModel } from '@ov-suite/helpers-shared';

@OVEntity('MobileUserConfigList', 'shared')
export class MobileUserConfigLabelModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  label: string;

  @OVField({ type: 'string' })
  value: string;
}

@OVEntity('MobileUserConfig', 'shared')
export class MobileUserConfigModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: "string" })
  name: string;

  @OVField({ type: "string" })
  icon: string;

  @OVField({ type: "string" })
  processDefinitionKey: string;

  @OVField({ type: "string" })
  scanToStart: string;

  @OVField({ type: "string" })
  scanToClaim: string;

  @OVField({ type: "boolean" })
  tapToStart: boolean;

  @OVField({ type: "boolean" })
  tapToContinue: boolean; // Can continue task by tapping

  @OVField({ type: "boolean" })
  scanToContinue: boolean; // Can continue task by tapping

  @OVField({ type: 'json' })
  emptyReadyForm: FormioModel;

  @OVField({ type: "string" })
  emptyReadyFormKey: string; // Displays formio form when ready task list is empty

  @OVField({ type: 'json' })
  emptyActiveForm: FormioModel;

  @OVField({ type: "string" })
  emptyActiveFormKey: string; // Displays formio form when active task list is empty

  @OVField({ type: "string" })
  readyTabLabel: string;

  @OVField({ type: "string" })
  activeTabLabel: string;

  @OVField({ type: () => [MobileUserConfigLabelModel] })
  labels: MobileUserConfigLabelModel[]
}
