import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import {
  FactoryArea,
  FactoryAreaStatus,
  FactoryAreaType
} from '@ov-suite/adminlink-models';

@Injectable()
export class FactoryAreaService extends AutoService<FactoryArea> {
  constructor(private apollo: Apollo) {
    super(apollo, FactoryArea, 'FactoryArea', 'FactoryAreas', 'adminlink');
  }
}

@Injectable()
export class FactoryAreaStatusService extends AutoService<FactoryAreaStatus> {
  constructor(private apollo: Apollo) {
    super(
      apollo,
      FactoryAreaStatus,
      'FactoryAreaStatus',
      'FactoryAreaStatuses',
      'adminlink'
    );
  }
}
@Injectable()
export class FactoryAreaTypeService extends AutoService<FactoryAreaType> {
  constructor(private apollo: Apollo) {
    super(apollo, FactoryAreaType, 'FactoryAreaType', 'FactoryAreaType', 'adminlink');
  }
}
