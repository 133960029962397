import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Product, ProductStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class ProductService extends AutoService<Product> {
  constructor(private apollo: Apollo) {
    super(apollo, Product, 'Product', 'Products', 'adminlink');
  }
}

@Injectable()
export class ProductStatusService extends AutoService<ProductStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, ProductStatus, 'ProductStatus', 'ProductStatuses', 'adminlink');
  }
}
