import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  VehicleTypeService,
  DomainService,
} from '@ov-suite/services-angular';
import { VehicleTypeAddOrEditComponent } from './add-or-edit/vehicle-type-add-or-edit.component';
import { VehicleTypeRoutes } from './vehicle-type.routing';
import { VehicleTypeListComponent } from './list/vehicle-type-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(VehicleTypeRoutes),
    FormsModule,
    UiModule
  ],
  declarations: [
    VehicleTypeListComponent,
    VehicleTypeAddOrEditComponent,
  ],
  providers: [
    VehicleTypeService,
    DomainService
  ]
})
export class VehicleTypeModule {}
