import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { UiModule, GraphQLModule } from '@ov-suite/ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor } from '@ov-suite/authguard-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '@ov-suite/helpers-shared';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DomainService, UserService } from '@ov-suite/services-angular';

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  imports: [
    HttpClientModule,
    GraphQLModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    UiModule.forRoot({
      apiUrl: environment.apiUrl.ceramicPortal,
      imageUploadUrl: environment.other.awsFileServiceApi
    })
  ],
  providers: [
    UserService,
    DomainService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: 'DEFAULT_API',
      useValue: 'ceramic-portal'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
