import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AutoService, OvAutoService } from '@ov-suite/services';
import {
  Constructor,
  FieldMetadata,
  GenericHierarchy,
  getFieldMetadata,
  getSearchableMetadata,
  SearchableMetadata
} from '@ov-suite/ov-metadata';
import { ColumnData } from '@ov-suite/helpers-shared';
import {
  hasPermissionByFeature,
  PermissionAction,
  verifyPermission
} from '@ov-suite/authguard-angular';
import { cloneDeep } from 'lodash';

type GenericHierarchyType = GenericHierarchy;

@Component({
  selector: 'ov-suite-hierarchy-table',
  templateUrl: './hierarchy-table.component.html',
  styleUrls: ['./hierarchy-table.component.scss']
})
export class HierarchyTableComponent<T extends GenericHierarchyType>
  implements OnInit {
  // To be Deprecated
  @Input() service: AutoService<T>;
  @Input() ovAutoService: OvAutoService;
  // To be Deprecated in favour of Fetching from Metadata
  @Input() api: string;

  @Input() title: string;
  @Input() formClass: Constructor<T>;

  @Input() hasBulkUpload = true;

  @Input() excludeColumns = 1;

  @Input() showTopBar = false;

  @Input() selectableRows = false;

  @Input() hideAddButton = false;

  @Input() showFiller = true;

  @Input() showScroll = false;

  @Input() editableRows = false;

  @Input() dropdownData = {};

  @Input()
  set reFetch(reFetch: number) {
    if (reFetch > 0) {
      this.getData();
    }
  }

  @Output() itemSelect = new EventEmitter<T[]>();

  @Output() editedItems = new EventEmitter<T[]>();

  @Output() originalData = new EventEmitter<T[]>();

  loading = false;

  customizing = false;

  _idFilter: Record<string, (string | number)[]>;
  _hideColumnKeys: string[] = [];

  hasDeletePermission = false;
  hasEditPermission = false;
  hasCreatePermission = false;
  hasPermissionsSet = false;

  @Input()
  set filter(event) {
    this._idFilter = event;
    this.getData();
  }

  get filter() {
    return this._idFilter;
  }

  @Input()
  set additionalSearch(event) {
    // this.searchFilter = event;
    // this.getData();
  }

  @Input()
  set refetch(refetch: number) {
    if (refetch > 0) {
      this.getData();
    }
  }

  @Input()
  set hideColumnKeys(event: string[]) {
    this._hideColumnKeys = event;
  }
  get hideColumnKeys() {
    return this._hideColumnKeys;
  }

  data: T[] = [];

  // Paging Variables
  totalCount = 0;

  _page = 0;
  set page(page: number) {
    this._page = page;
    this.getData();
  }

  get page() {
    return this._page;
  }

  pageSize = 10;
  searchableMetadata: SearchableMetadata;

  // End Paging Variables
  _parentId: number | null = null;

  set parentId(num: number) {
    this._parentId = num ?? null;
    this.getData();
  }

  get parentId() {
    return this._parentId;
  }

  metadata: FieldMetadata<T>;

  searchFilter = '';

  searchMap: Record<string, string[]> = {};

  filterMap: Record<string, string[]> = {};

  orderColumn = 'id';
  orderDirection: 'ASC' | 'DESC' = 'ASC';

  @Input() import: () => void = () => {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.route.data.subscribe(perm => {
      if (perm?.feature?.id) {
        this.hasPermissionsSet = true;
        hasPermissionByFeature(perm.feature.id).then(permission => {
          this.hasDeletePermission = verifyPermission(
            permission,
            PermissionAction.DELETE
          );
          this.hasEditPermission = verifyPermission(
            permission,
            PermissionAction.UPDATE
          );
          this.hasCreatePermission = verifyPermission(
            permission,
            PermissionAction.CREATE
          );
        });
      }
    });
  }

  pageSizeInput(pageSize: number) {
    if (pageSize > 0) {
      this.pageSize = +pageSize;
      this.getData();
    }
  }

  changePage(page: number): void {
    this.page = page;
  }

  getData(): void {
    const metadata = getFieldMetadata<T>(this.formClass);

    let filter = '';
    if (metadata.fields.some(f => f.propertyKey === 'parent')) {
      this._idFilter = {
        ...this._idFilter,
        parentId: [this.parentId || null]
      };
      // const query = this.parentId
      //   ? { parentId: { eq: this.parentId } }
      //   : { parentId: { eq: null } };

      // filter = buildQuery(metadata.name, query);
    }
    if (this.searchFilter) {
      filter += (!!filter ? ' AND ' : '') + this.searchFilter;
    }

    if (this.filter) {
      filter += this.filter;
    }

    const keys: string[] = ['id'];

    metadata.table.map(col => {
      switch (col.type) {
        case 'other':
        case 'buttons':
          keys.push(...col.keys);
          break;
        case 'column':
          keys.push(...col.keys);
          keys.push(col.key as string);
          break;
        default:
          keys.push(col.key as string);
      }
    });

    this.loading = true;

    if (this.service) {
      this.service
        .list({
          search: this.searchMap,
          filter: this.filterMap,
          query: this._idFilter, //TODO rename idFilter
          specifiedApi: this.api,
          limit: this.pageSize,
          offset: this.page * this.pageSize,
          orderDirection: this.orderDirection,
          orderColumn: this.orderColumn,
          keys
        })
        .then(result => {
          this.data = result.data;
          this.totalCount = result.totalCount;
          this.loading = false;
          this.originalData.emit(this.data);
        })
        .catch(() => (this.loading = false));
    } else {
      this.ovAutoService
        .list({
          search: this.searchMap,
          filter: this.filterMap,
          query: this._idFilter,
          entity: this.formClass,
          specifiedApi: this.api,
          limit: this.pageSize,
          offset: this.page * this.pageSize,
          orderDirection: this.orderDirection,
          orderColumn: this.orderColumn,
          keys
        })
        .then(result => {
          this.data = result.data;
          this.totalCount = result.totalCount;
          this.loading = false;
          this.originalData.emit(this.data);
        })
        .catch(() => (this.loading = false));
    }
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.parentId = Number(params.get('parentId')) ?? null;
    });

    this.getMetadata();

    this.searchableMetadata = getSearchableMetadata(this.formClass);
  }

  getMetadata(): void {
    const metadata = getFieldMetadata<T>(this.formClass);
    const extraFields: ColumnData<T>[] = [];
    if (this.excludeColumns >= 1) {
      const buttons = [];
      const editButton = {
        classes: 'btn-primary btn-sm nc-icon nc-settings-gear-65',
        action: (item: T) => {
          const options: NavigationExtras = {
            queryParams: { id: item.id }
          };
          this.router.navigate(
            [this.router.url.slice(1).split('?')[0], 'edit'],
            options
          );
        }
      };
      const deleteButton = {
        classes: 'btn-danger btn-sm nc-icon nc-simple-remove',
        action: async (item: T) => {
          if (confirm('Are you sure you want to delete this?')) {
            if (this.service) {
              await this.service.delete(item?.id);
            } else {
              await this.ovAutoService.delete(
                this.formClass,
                this.api,
                item?.id
              );
            }
            this.getData();
          }
        }
      };

      if (this.excludeColumns === 1) {
        if (this.hasPermissionsSet) {
          if (this.hasEditPermission) {
            buttons.push(editButton);
          }

          if (this.hasDeletePermission) {
            buttons.push(deleteButton);
          }
        } else {
          buttons.push(editButton);
          buttons.push(deleteButton);
        }
      }

      if (this.excludeColumns === 2) {
        if (this.hasPermissionsSet) {
          if (this.hasDeletePermission) {
            buttons.push(deleteButton);
          }
        } else {
          buttons.push(deleteButton);
        }
      }

      if (this.excludeColumns === 3) {
        if (this.hasPermissionsSet) {
          if (this.hasEditPermission) {
            buttons.push(editButton);
          }
        } else {
          buttons.push(editButton);
        }
      }

      if (this.excludeColumns < 4 && buttons.length) {
        extraFields.push({
          type: 'buttons',
          title: 'Fast Actions',
          buttons,
          keys: [],
          disableFiltering: true,
          disableSorting: true,
          id: 'fast_actions'
        });
      }
    }
    if (this.excludeColumns === 0) {
      extraFields.push({
        type: 'status',
        title: 'Status',
        key: 'status',
        id: 'status'
      });
    }

    this.metadata = { ...metadata, table: [...metadata.table, ...extraFields] };
    // this.hideColumnKeys = this.metadata.table.filter(item => !!item.hideColumnKey).map(item => item.hideColumnKey);
  }

  onSearchChange(event: Event): void {
    if ((<HTMLInputElement>event?.target)?.value) {
      this.search((<HTMLInputElement>event?.target)?.value);
    } else {
      this.searchFilter = '';
      this.searchMap = {};
      this.getData();
    }
  }

  search(text: string): void {
    let filter = '';
    const name = this.metadata.name;
    this.searchableMetadata.fields.forEach(field => {
      this.searchMap[field.propertyKey] = [text];

      if (filter) {
        filter += ` OR "${name}"."${field.propertyKey}" ILIKE '%${text}%'`;
      } else {
        filter = `"${name}"."${field.propertyKey}" ILIKE '%${text}%'`;
      }
    });

    this.searchFilter = `(${filter})`;

    this.page = 0;
    this.getData();
  }

  customize() {
    this.customizing = !this.customizing;
  }

  add(): void {
    const options: NavigationExtras = {};

    if (this.parentId) {
      options.queryParams = { parentId: this.parentId };
    }

    this.router.navigate(
      [this.router.url.slice(1).split('?')[0], 'add'],
      options
    );
  }

  select = (item: T) => {
    const options: NavigationExtras = {
      queryParams: {
        parentId: item.id
      }
    };

    this.router.navigate([this.router.url.slice(1).split('?')[0]], options);
  };

  back = () => {
    if (this.parentId) {
      this.location.back();
    }
  };

  onItemSelected(event: T[]) {
    this.itemSelect.emit(event);
  }

  filterChange(filters: Record<string, string[]>) {
    this.filterMap = filters;
    this._idFilter = filters;
    this.page = 0;
    this.getData();
  }

  orderChange(order: {
    column: string;
    direction: 'ASC' | 'DESC';
    data: ColumnData<unknown>;
  }) {
    if (order.data.type === 'buttons' || order.data.type === 'other') {
      this.orderColumn = order.data.orderKey;
    } else {
      this.orderColumn = order.data.orderKey;
    }
    this.orderDirection = order.direction;
    this.getData();
  }

  onItemEdit(items: T[]) {
    let copy: T[] = [];
    if (
      items.some(item =>
        Object.keys(item).some(
          key => key === 'hasChanged' || key === 'isEditable' || key === 'newId'
        )
      )
    ) {
      copy = cloneDeep(items);
      copy.forEach(item => {
        if (Object.keys(item).some(key => key === 'hasChanged')) {
          delete item['hasChanged'];
        }
        if (Object.keys(item).some(key => key === 'isEditable')) {
          delete item['isEditable'];
        }
        if (Object.keys(item).some(key => key === 'newId')) {
          delete item['newId'];
        }
      });
    }
    this.editedItems.emit(copy);
    this.originalData.emit(this.data);
  }

  uploaded(uploaded: boolean) {
    if (uploaded) {
      this.getData();
    }
  }
}
