import { Routes } from '@angular/router';
import { OriginTypeListComponent } from './list/origin-type-list.component';
import { OriginTypeAddOrEditComponent } from './add-or-edit/origin-type-add-or-edit.component';

export const OriginTypeRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: OriginTypeListComponent
      },
      {
        path: 'add',
        component: OriginTypeAddOrEditComponent
      },
      {
        path: 'edit',
        component: OriginTypeAddOrEditComponent
      }
    ]
  }
];
