import { Component } from '@angular/core';
import { Shipment } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentService } from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-rate',
  templateUrl: './shipping-add-or-edit.component.html',
  styleUrls: ['./shipping-add-or-edit.component.scss']
})
export class ShippingAddOrEditComponent extends AddOrEditHelper<Shipment> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public shipmentService: ShipmentService
  ) {
    super(route, router, Shipment, shipmentService, ['/shipping']);
    this.dataSources = {
      ...this.dataSources
    };
  }
}
