import { OVField, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';
import { Vat } from './vat.model';

@OVEntity('ShippingRoute', 'ceramic-portal')
export class ShippingRoute {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Route Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Description',
    placeholder: 'Required',
    required: false
  })
  description: string;

  @OVField({
    type: 'string',
    title: 'Route Code',
    placeholder: 'Required',
    required: false
  })
  routeCode: string;

  @OVField({
    type: () => Vat,
    title: 'Vat',
    placeholder: 'Required',
    required: true
  })
  vat: Vat;
}
