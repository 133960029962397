import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { FeatureStatus } from './feature-status.model';

@OVEntity('Feature', 'idmlink')
export class Feature {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  // @Field({type: App, unnecessary: true})
  // app: App;
  @OVField({ type: () => FeatureStatus })
  status: FeatureStatus;
}
