import { Apollo } from 'apollo-angular';
import {
  createMutationKeys,
  updateMutationKeys,
  deleteMutationKeys,
  getQueryKeys,
  getAncestorQueryKeys,
  listQueryKeys,
  getByIdsQueryKeys
} from '@ov-suite/graphql-helpers';
import {
  Constructor,
  FieldMetadata,
  getFieldMetadata,
  mapToClass
} from '@ov-suite/ov-metadata';
import { Inject, Injectable } from '@angular/core';
import gql from 'graphql-tag';

interface HasId {
  id: number | string;
}

interface PageReturn<T extends HasId> {
  data: T[];
  totalCount: number;
}

export interface OvAutoServiceListParams<T> {
  entity: Constructor<T>;
  specifiedApi?: string;
  limit?: number;
  offset?: number;
  orderColumn?: string;
  orderDirection?: 'ASC' | 'DESC';
  search?: Record<string, string[]>;
  filter?: Record<string, string[]>;
  keys?: string[];
  query?: Record<string, unknown>;
}

@Injectable()
export class OvAutoService {
  constructor(
    private apollo: Apollo,
    @Inject('DEFAULT_API') private defaultApi: string
  ) {}

  //todo memoize
  getMetadata<T extends HasId>(entity: Constructor<T>): FieldMetadata<T> {
    return getFieldMetadata(entity);
  }

  async list<T extends HasId>(
    params: OvAutoServiceListParams<T>
  ): Promise<PageReturn<T>> {
    const {
      entity,
      specifiedApi,
      offset = 0,
      limit = 100,
      query,
      search,
      filter,
      keys = [],
      orderColumn = 'id',
      orderDirection = 'ASC'
    } = params;
    const metadata = this.getMetadata(entity);
    const name = `list${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      const gqlQuery = listQueryKeys({ name, input: entity, metadata, specificKeys: keys, api: this.defaultApi });
      this.apollo
        .use(api)
        .query({
          query: gqlQuery,
          variables: {
            params: {
              limit,
              offset,
              orderColumn,
              orderDirection,
              search,
              filter,
              query
            }
          },
          fetchPolicy: 'no-cache'
        })
        .subscribe(
          response => {
            const rawData = response.data[name].data;
            const totalCount = response.data[name].totalCount;
            const data = rawData.map(item => mapToClass(entity, item));
            resolve({ data, totalCount });
          },
          // reject
          error => {
            console.log({ error });
          }
        );
    });
  }

  async get<T extends HasId>(
    entity: Constructor<T>,
    specifiedApi: string,
    id: number
  ): Promise<T> {
    const metadata = this.getMetadata(entity);
    const name = `get${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .query({
          query: getQueryKeys({ name, input: entity, metadata, api: this.defaultApi }),
          variables: { id },
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(entity, rawData);
          resolve(data);
        }, reject);
    });
  }

  async getAll<T extends HasId>(
    entity: Constructor<T>,
    ids: number[],
    specifiedApi?: string
  ): Promise<T[]> {
    const metadata = this.getMetadata(entity);
    const name = `get${metadata.name}ByIds`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .query({
          query: getByIdsQueryKeys({ name, input: entity, metadata, api: this.defaultApi }),
          variables: { ids },
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = rawData.map(item => mapToClass<T>(entity, item));
          resolve(data);
        }, reject);
    });
  }

  async create<T extends HasId>(
    entity: Constructor<T>,
    specifiedApi: string,
    item: Omit<T, 'id'>
  ): Promise<T> {
    const metadata = this.getMetadata(entity);
    const name = `create${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .mutate({
          mutation: createMutationKeys({ name, input: entity, metadata, api: this.defaultApi }),
          variables: { data: item }
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(entity, rawData);
          resolve(data);
        });
    });
  }

  async update<T extends HasId>(
    entity: Constructor<T>,
    specifiedApi: string,
    item: Partial<T> & HasId
  ): Promise<T> {
    const metadata = this.getMetadata(entity);
    const name = `update${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .mutate({
          mutation: updateMutationKeys({ name, input: entity, metadata, api: this.defaultApi }),
          variables: { data: item }
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(entity, rawData);
          resolve(data);
        }, reject);
    });
  }

  async delete<T extends HasId>(
    entity: Constructor<T>,
    specifiedApi: string,
    id: number | string
  ): Promise<void> {
    const metadata = this.getMetadata(entity);
    const name = `delete${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .mutate({
          mutation: deleteMutationKeys({name}),
          variables: { id }
        })
        .subscribe(() => resolve(), reject);
    });
  }

  async listAncestors<T extends HasId>(
    entity: Constructor<T>,
    specifiedApi: string,
    id: number
  ): Promise<T[]> {
    const metadata = this.getMetadata(entity);
    const name = `list${metadata.name}Ancestors`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .query({
          query: getAncestorQueryKeys({ name, input: entity, metadata, api: this.defaultApi }),
          variables: { id },
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = rawData.map(item => mapToClass<T>(entity, item));
          resolve(data);
        }, reject);
    });
  }
}
