import { Component } from '@angular/core';
import { RateChange } from '@ov-suite/adminlink-models';
import { RateChangeService } from '@ov-suite/services-angular';
import { Router } from '@angular/router';
import { RateTabs } from '../../../components/table-top-nav-menu/tabs/rate-tabs';
import { getFieldMetadata } from '@ov-suite/ov-metadata';

@Component({
  selector: 'ov-suite-pending-rate-changes-list',
  templateUrl: './pending-rate-changes-list.component.html',
  styleUrls: ['./pending-rate-changes-list.component.scss']
})
export class PendingRateChangesListComponent {
  parentId?: number;
  // Class - Required
  formClass = RateChange;
  tabs = RateTabs;
  filter = {
    rateApplied: [false]
  }
  search = {
    // changeStatus: ['APPROVED', 'FUEL_ADJUSTMENT']
  }
  metadata = getFieldMetadata(this.formClass);

  constructor(
    public rateChangeService: RateChangeService,
    private router: Router
  ) {}

  onTabPress(event) {
    this.router.navigate([event]).then();
  }
}
