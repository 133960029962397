import { Injectable } from '@angular/core';
import { MobileSod, MobileSodStatus } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class MobileSodYardService extends AutoService<MobileSod> {
  constructor(private apollo: Apollo) {
    super(apollo.use('yardlink'), MobileSod, 'MobileSod', 'MobileSods', 'yardlink');
  }
}

@Injectable()
export class MobileSodStatusYardService extends AutoService<MobileSodStatus> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('yardlink'),
      MobileSodStatus,
      'MobileSodStatus',
      'MobileSodStatuses', 'yardlink'
    );
  }
}
