import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Domain, DomainStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class DomainService extends AutoService<Domain> {
  constructor(private apollo: Apollo) {
    super(apollo, Domain, 'Domain', 'Domains', 'adminlink');
  }
}

@Injectable()
export class DomainStatusService extends AutoService<DomainStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, DomainStatus, 'DomainStatus', 'DomainStatuses', 'adminlink');
  }
}
