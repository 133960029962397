import { Component, OnInit } from '@angular/core';
import { CeramicInvoiceItem } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import { CeramicInvoiceItemService } from '@ov-suite/services-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddInvoiceItemComponent } from '../modal/add-invoice-item/add-invoice-item.component';
import { getTransporterIds } from '@ov-suite/authguard-angular';
import { getFieldMetadata } from '@ov-suite/ov-metadata';

@Component({
  selector: 'ov-suite-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css']
})
export class InvoiceDetailComponent implements OnInit {
  id: string;
  formClass = CeramicInvoiceItem;
  metadata = getFieldMetadata(this.formClass);
  filter = {};
  selectedItems: CeramicInvoiceItem[] = [];
  hideColumnKeys: string[] = null;
  queriedTransporter = false;
  invoiceBlocked = true;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public invoiceItemService: CeramicInvoiceItemService,
    private ngbModal: NgbModal
  ) {
    this.route.params.subscribe(param => {
      this.id = param['id'];
    });
  }

  ngOnInit() {
    getTransporterIds().then((res) => {
      if (res.length) {
        this.hideColumnKeys = ['actions'];
      }
      this.queriedTransporter = true;
    });
    // filter by shipment
    this.filter = { 'invoice.id': [+this.id] };
  }

  onItemSelect(data) {
    this.selectedItems = data;
  }

  add(): void {
    const modalRef = this.ngbModal.open(AddInvoiceItemComponent, {
      size: 'xl'
    });
    modalRef.componentInstance.invoiceId = +this.id;
  }

  getData(invoices: CeramicInvoiceItem[]) {
    if (invoices.some(invoice => !invoice.invoice.softLockOn)) {
      this.invoiceBlocked = false;
    }
  }
}
