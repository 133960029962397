import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Transporter } from '@ov-suite/adminlink-models';

@Injectable()
export class TransporterService extends AutoService<Transporter> {
  constructor(private apollo: Apollo) {
    super(apollo, Transporter, 'Transporter', 'Transporters', 'adminlink');
  }
}
