import { Component } from '@angular/core';
import { PickupPenaltyBand } from '@ov-suite/adminlink-models';
import { PickupPenaltyBandService } from '@ov-suite/services-angular';

@Component({
  selector: 'ov-suite-pickup-penalty-band-list',
  templateUrl: './pickup-penalty-band-list.component.html',
  styleUrls: ['./pickup-penalty-band-list.component.scss']
})
export class PickupPenaltyBandListComponent {
  parentId?: number;

  // Class - Required
  formClass = PickupPenaltyBand;

  constructor(public pickupPenaltyBandService: PickupPenaltyBandService) {}
}
