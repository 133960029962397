import { Routes } from '@angular/router';
import {AuditTrailComponent} from "./list/audit-trail.component";

export const AuditTrailRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AuditTrailComponent
      }
    ]
  }
];
