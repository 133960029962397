import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { CustomerGroup, CustomerGroupStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class CustomerGroupService extends AutoService<CustomerGroup> {
  constructor(private apollo: Apollo) {
    super(apollo, CustomerGroup, 'CustomerGroup','CustomerGroups', 'adminlink');
  }
}

@Injectable()
export class CustomerGroupStatusService extends AutoService<
  CustomerGroupStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo,
      CustomerGroupStatus,
      'CustomerGroupStatus',
      'CustomerGroupStatuses',
      'adminlink'
    );
  }
}
