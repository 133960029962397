<div class="main-content">
  <ov-suite-table-nav-menu
    *ngIf="tabs.length"
    [tabs]="tabs"
    [active]="activeTab"
    [metadata]="metadata"
    [downloadConstructor]="formClass"
    title="Rate Management"
    (tabClick)="onTabPress($event)"
  >
  </ov-suite-table-nav-menu>
  <ov-suite-hierarchy-table
    [title]="'Current Rates List'"
    [formClass]="formClass"
    [service]="rateService"
    [showFiller]="false"
    [showTopBar]="true"
    [hideAddButton]="true"
    (itemSelect)="onItemSelect($event)"
    [selectableRows]="true"
    [hideColumnKeys]="hideColumnKeys"
  >
  </ov-suite-hierarchy-table>
  <div class="row">
    <div class="col-md-12">
      <button
        *ngIf="activeTab === 'rate'"
        [disabled]="selectedItems.length === 0"
        class="btn btn-white btn-border"
        (click)="swapRates()"
      >
        <i class="fa fa-compress mr-2"></i>
        Rate Change
      </button>
      <button
        *ngIf="activeTab === 'fuel-rate' && canAddNewRate"
        class="btn btn-white btn-border"
        (click)="swapRates()"
      >
        <i class="fa fa-compress mr-2"></i>
        Adjust Fuel Rate
      </button>
      <button *ngIf="canAddNewRate" class="btn btn-white btn-border float-right" (click)="add()">
        Add
        <i class="nc-icon nc-simple-add ml-4"></i>
      </button>
    </div>
  </div>
</div>
