<div class="main-content">
  <ov-suite-hierarchy-table
    [title]="'Weight Bands Management'"
    [formClass]="formClass"
    [showFiller]="false"
    [service]="weightBandService"
    [showTopBar]="true"
    [excludeColumns]="1"
  >
  </ov-suite-hierarchy-table>
</div>
