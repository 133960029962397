import {
  OVField,
  OVForm,
  OVSidebar,
  OVTable,
  OVSearchable,
  OVEntity
} from '@ov-suite/ov-metadata';
import { Tag } from '../adminlink/tag.model';
import { Plant } from '../ceramic-portal/plant.model';

@OVEntity('ReasonCode', 'yardlink')
@OVForm([['#Reason Code Details'], ['code'], ['description'], ['tags']])
@OVSidebar([['id']])
@OVTable<ReasonCode>([
  {
    key: 'code',
    title: 'Reason Code',
    type: 'string'
  }
])
export class ReasonCode {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Reason Code Name',
    placeholder: 'Required',
    required: true
  })
  code: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({
    type: () => [Tag],
    selectionType: 'multiple',
    title: 'Tags',
    required: true,
    flat: true
  })
  tags: Tag[];
}
