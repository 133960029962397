import { Component } from '@angular/core';
import { Shipment, ShipmentState } from '@ov-suite/adminlink-models';
import { ShipmentService } from '@ov-suite/services-angular';
import { TableTopNavTab } from '../../../components/table-top-nav-menu/table-top-nav-menu-tabs.model';
import { getUpdate } from '@ov-suite/graphql-helpers';
import { cloneDeep } from 'lodash';
import { getFieldMetadata } from '@ov-suite/ov-metadata';

@Component({
  selector: 'ov-suite-shipping',
  templateUrl: './shipping-list.component.html',
  styleUrls: ['./shipping-list.component.scss']
})
export class ShippingListComponent {
  // Class - Required
  formClass = Shipment;
  filter: Record<string, unknown[]>;
  selectedItems: Shipment[] = [];
  metadata = getFieldMetadata(this.formClass);
  currentTabKey = '';
  reFetch = 0;

  tabs: TableTopNavTab[] = [
    { title: 'All', key: '' },
    { title: 'Planned', key: 'PLANNED' },
    { title: 'Accepted', key: 'ACCEPTED' },
    { title: 'Dispatched', key: 'DISPATCHED' },
    { title: 'POD', key: 'POD' },
    { title: 'Invoice', key: 'INVOICE' },
    { title: 'Rejected', key: 'REJECTED' },
  ];
  saving = false;
  errorItems = [];

  constructor(public shipmentService: ShipmentService) {}

  onTabPress(data) {
    this.currentTabKey = data;
    if (data !== '') {
      this.filter = { 'shipmentState': [data] };
    } else {
      this.filter = {};
    }
  }

  onItemSelect(selectedItems: Shipment[]) {
    this.selectedItems = selectedItems;
  }

  bottomActionButtonsVisible(): boolean{
    return this.currentTabKey === '' || this.currentTabKey === 'PLANNED';
  }

  async submit(accept: boolean) {
    this.errorItems = [];
    for (const item of this.selectedItems) {
      this.saving = true;
      const newItem = cloneDeep(item);
      delete newItem['isSelected'];
      if (newItem.shipmentState === ShipmentState.PLANNED) {
        newItem.shipmentState = accept
          ? ShipmentState.ACCEPTED
          : ShipmentState.REJECTED;
        await this.shipmentService
          .update(getUpdate(newItem, item))
          .then(() => {this.saving = false; this.reFetch++; })
          .catch(() => {
            newItem['error'] = 'Shipment may be missing a transporter code.';
            this.errorItems.push(newItem);
            this.saving = false;
          });
      } else {
        item['error'] = 'Shipment has already been ' + item.shipmentState;
        this.errorItems.push(item);
        this.saving = false;
      }
    }
  }
}
