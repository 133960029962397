import { Component, OnInit } from '@angular/core';
import { RateHistory } from '@ov-suite/adminlink-models';
import { RateHistoryService } from '@ov-suite/services-angular';
import { Router } from '@angular/router';
import { getTransporterIds } from '@ov-suite/authguard-angular';
import { TableTopNavTab } from '../../../components/table-top-nav-menu/table-top-nav-menu-tabs.model';
import { getFieldMetadata } from '@ov-suite/ov-metadata';

@Component({
  selector: 'ov-suite-rate-history-list',
  templateUrl: './rate-history-list.component.html',
  styleUrls: ['./rate-history-list.component.scss']
})
export class RateHistoryListComponent implements OnInit {
  parentId?: number;
  // Class - Required
  formClass = RateHistory;
  tabs: TableTopNavTab[] = [];
  metadata = getFieldMetadata(this.formClass);

  constructor(
    public rateHistoryService: RateHistoryService,
    private router: Router
  ) {}

  ngOnInit(): void {
    getTransporterIds().then(res => {
      if (res.length > 0) {
        this.tabs = [
          { title: 'CURRENT RATES', key: 'rate' },
          { title: 'HISTORY', key: 'rate-history' }
        ];
      } else {
        this.tabs = [
          { title: 'CURRENT RATES', key: 'rate' },
          { title: 'FUEL RATES', key: 'fuel-rate' },
          { title: 'NEW REQUESTS', key: 'rate-change' },
          { title: 'PENDING APPROVAL', key: 'pending-rate-changes' },
          { title: 'HISTORY', key: 'rate-history' }
        ];
      }
    });
  }

  onTabPress(event) {
    this.router.navigate([event]).then();
  }
}
