import { IHistoricProcess } from '@ov-suite/helpers-shared';
import gql from 'graphql-tag';

export function listHistoricProcessInstances(keys: (keyof IHistoricProcess)[]) {
  return gql(`query ListHistoricProcessInstances($query: ListHistoricProcessInstanceInput!) {
    listHistoricProcessInstances(query: $query) {
      data {
        ${keys.join(`\n`)}
      }
      total
      start
      sort
      order
      size
     }
   }`);
}

export function queryHistoricProcessInstances(
  keys: (keyof IHistoricProcess)[]
) {
  return gql(`query QueryHistoricProcessInstances($query: QueryHistoricProcessInstanceInput!) {
    queryHistoricProcessInstances(query: $query) {
      data {
        ${keys.join(`\n`)}
      }
      total
      start
      sort
      order
      size
     }
   }`);
}
