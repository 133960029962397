<ng-template #content let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h5 class="modal-title" id="modal-basic-title">Bulk Upload</h5>
  </div>
  <div class="modal-body p-2">
    <div class=" margin-auto">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12">
            <button
              class="btn btn-primary btn-round width-100p"
              (click)="exportAll('/api/bulk-upload/template', 'Template')"
            >
              DOWNLOAD TEMPLATE
            </button>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <button
              class="btn btn-info btn-round width-100p"
              (click)="exportAll('/api/bulk-upload/export', 'Export')"
            >
              EXPORT ALL
            </button>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="font-weight-bold">IMPORT TEMPLATE</div>
            <div class="upload-drop font-weight-bold">
              <div class="upload-text">DRAG DOCUMENT</div>

              <input
                id="xlxs-file-reader"
                class="hide-xlsx-reader"
                type="file"
                (change)="onFileChange($event)"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <button
              class="btn btn-info btn-round width-100p"
              (click)="onFileUploadClick()"
            >
              UPLOAD DOCUMENT
            </button>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="card-text width-100p" *ngIf="!uploaded">
              {{ currentFiles?.item(0)?.name }} available to save:
              {{ currentFiles?.item(0)?.size / 1024 }} KB
            </div>
            <div class="card-text width-100p" *ngIf="uploaded">
              {{ currentFiles?.item(0)?.name }} uploaded and saved.
              <i aria-hidden="true" class="fa  fa-check text-success fa-2x"></i>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="currentFiles?.item(0)?.name.length > 0" class="col-12">
            <button
              (click)="onFileSave()"
              class="btn btn-success btn-round width-100p"
            >
              <span
                [ngClass]="{ 'spinner-border': loading == true }"
                class="spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span> SAVE </span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-primary btn-round width-100p"
              (click)="back()"
            >
              BACK
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="!!errors && errors.length > 0" id="tempErrorView">
        <div id="tempErrorViewClose" (click)="errors = []"></div>
        <ul>
          <li *ngFor="let error of errors">
            <span class="tempErrorColor">
              Row: {{ error.row || '-' }}, Column: {{ error.col || '-' }},
              Message: {{ error.error }}</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
<button class="btn btn-dark btn-block btn-round" (click)="open(content)">
  Import<span class="float-right mr-2"
    ><i aria-hidden="true" class="nc-icon nc-cloud-upload-94"></i
  ></span>
</button>
