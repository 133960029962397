import { Routes } from '@angular/router';
import { PlantListComponent } from './list/plant-list.component';
import { PlantAddOrEditComponent } from './add-or-edit/plant-add-or-edit.component';

export const PlantRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: PlantListComponent
      },
      {
        path: 'add',
        component: PlantAddOrEditComponent
      },
      {
        path: 'edit',
        component: PlantAddOrEditComponent
      }
    ]
  }
];
