<div class="main-content">
  <ov-suite-create-or-edit
    [service]="rateService"
    [formClass]="entity"
    [title]="'Rates'"
    (save)="navigateBack(); saveRateCreate($event)"
    [dataSources]="dataSources"
  >
  </ov-suite-create-or-edit>
</div>
