import { Injectable } from '@angular/core';
import { DropPenaltyBand, RateChange } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class DropPenaltyBandService extends AutoService<DropPenaltyBand> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), DropPenaltyBand, 'DropPenaltyBand', 'DropPenaltyBands', 'ceramic-portal');
  }
}
