import { Injectable } from '@angular/core';
import { MobileEod, MobileEodStatus } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class MobileEodYardService extends AutoService<MobileEod> {
  constructor(private apollo: Apollo) {
    super(apollo.use('yardlink'), MobileEod, 'MobileEod', 'MobileEods', 'yardlink');
  }
}

@Injectable()
export class MobileEodStatusYardService extends AutoService<MobileEodStatus> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('yardlink'),
      MobileEodStatus,
      'MobileEodStatus',
      'MobileEodStatuses', 'yardlink'
    );
  }
}
