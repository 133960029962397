import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { DataSources, OvAutoService } from '@ov-suite/services';
import {
  CompiledFieldData,
  Constructor,
  getCompiledFieldMetadata,
  GenericHierarchy,
  FieldParamsConstructor,
  getFieldMetadata, isFieldParamsConstructor, getTypeMetadata
} from '@ov-suite/ov-metadata';

@Component({
  selector: 'ov-suite-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent<T = unknown> implements OnInit {
  @Input() dataSources: DataSources<GenericHierarchy> = {};
  // @Input() ovAutoService: OvAutoService;
  @Input() sideBarMetadata;
  @Input() formClass: Constructor<T>;
  _data: T;
  @Input() set data(item: T) {
    this._data = item;
    this.assignDataValues();
  }
  get data() {
    return this._data;
  }

  @Input() showSave = true;

  @Output() save = new EventEmitter();

  @Output() formChange = new EventEmitter();

  metadata: CompiledFieldData[][];

  showRequired = false;

  loadMap: Record<string, unknown> = {};

  constructor(
    @Inject('DEFAULT_API') private defaultApi: string,
    public ovAutoService: OvAutoService
  ) {}

  ngOnInit() {
    this.metadata = getCompiledFieldMetadata(this.formClass);
    this.assignDataValues();
  }

  getDataSource(data: CompiledFieldData): unknown {
    if (this.dataSources && this.dataSources[data.propertyKey]) {
      return this.dataSources[data.propertyKey];
    } else if (
      this.loadMap[data.propertyKey] &&
      this.loadMap[data.propertyKey] !== 'loading'
    ) {
      return this.loadMap[data.propertyKey];
    } else {
      if (!this.loadMap[data.propertyKey]) {
        if (isFieldParamsConstructor(data)) {
          console.log('called a');
            const dataType = data.withQuantity ? data.subType : data.type;
            this.loadMap[data.propertyKey] = 'loading';
            const { entity: remoteEntity, metadata: remoteMetadata } = getTypeMetadata(dataType);
            let remoteApi = remoteMetadata.api;
            if (!remoteApi || remoteApi === 'shared') {
              remoteApi = this.defaultApi;
            }
            this.ovAutoService
              .list({
                entity: remoteEntity,
                specifiedApi: remoteApi,
                limit: 1000
              })
              .then(response => {
                this.loadMap[data.propertyKey] = response.data;
              });
        } else {
          return null;
        }
      }
    }
  }

  assignDataValues() {
    if (this.data) {
      this.fieldForEach(data => {
        if (this.data[data.propertyKey]) {
          data.value = this.data[data.propertyKey];
        }
      });
    }
  }

  private fieldForEach(callback: (data: CompiledFieldData) => void) {
    this.metadata?.forEach(row => {
      row?.forEach(column => {
        callback(column);
      });
    });
  }
  private async fieldForEachAsync(
    callback: (data: CompiledFieldData) => void
  ): Promise<void> {
    for (const row of this.metadata) {
      for (const column of row) {
        await callback(column);
      }
    }
  }

  async submit(noEmit?: boolean): Promise<T | undefined> {
    const output = this.data ?? new this.formClass();
    let requiredFlag = false;

    const validateField = this.validateField;

    async function validate(data: CompiledFieldData): Promise<void> {
      if (data.propertyKey) {
        await validateField(data);
        if (data.danger) {
          requiredFlag = true;
        }
        if (data.type !== 'title' && data.type !== 'blank') {
          output[data.propertyKey] = data.value;
        }
      }
    }

    await this.fieldForEachAsync(async data => await validate(data));

    if (!requiredFlag) {
      if (!noEmit) {
        this.save.emit(output);
      }
      this.showRequired = false;

      return output;
    } else {
      this.showRequired = true;
      return;
    }
  }

  async validateArrayField(data: CompiledFieldData) {
    if (data.value) {
      this.modelChange(data);
      await this.validateField(data);
    }
  }

  dataQuery = (key: string): unknown => {
    for (const row of this.metadata) {
      for (const col of row) {
        if (col.propertyKey === key) {
          return col.value;
        }
      }
    }

    for (const row of this.sideBarMetadata) {
      for (const col of row) {
        if (col.propertyKey === key) {
          return col.value;
        }
      }
    }
  };

  validateField = async (data: CompiledFieldData) => {
    let danger = false;
    if (data.validator) {
      const [valid, errorMessage] = await data.validator(data, this.dataQuery);
      if (!valid) {
        danger = true;
      }
      data.currentErrorMessage = valid ? '' : errorMessage;
    }
    if (data.required) {
      if (Array.isArray(data.value) && !data.value.length) {
        danger = true;
      } else if ((data.value ?? '') === '') {
        danger = true;
      }
    }
    if ((<FieldParamsConstructor>data).withQuantity) {
      if (Array.isArray(data.value)) {
        if (data.value.some(item => (item.quantity ?? 0) <= 0)) {
          danger = true;
          data.currentErrorMessage = 'Missing Quantity';
        }
      } else {
        if (
          data.value &&
          (((<unknown>data.value) as { quantity: number }).quantity ?? 0) <= 0
        ) {
          danger = true;
          data.currentErrorMessage = 'Missing Quantity';
        }
      }
    }
    data.danger = danger;
  };

  modelChange(subItem: unknown) {
    this.formChange.emit(subItem);
  }

  buttonAction(subItem: CompiledFieldData, item: CompiledFieldData[][]) {
    subItem.action(item);
  }

  cancel() {
    window.history.back();
  }
}
