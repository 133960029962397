import { Component } from '@angular/core';
import { OriginType } from '@ov-suite/adminlink-models';
import { OriginTypeService } from '@ov-suite/services-angular';

@Component({
  selector: 'ov-suite-origin-type-list',
  templateUrl: './origin-type-list.component.html',
  styleUrls: ['./origin-type-list.component.scss']
})
export class OriginTypeListComponent {
  parentId?: number;

  // Class - Required
  formClass = OriginType;

  constructor(public originTypeService: OriginTypeService) {}
}
