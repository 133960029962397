import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Vehicle, VehicleStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class VehicleService extends AutoService<Vehicle> {
  constructor(private apollo: Apollo) {
    super(apollo, Vehicle, 'Vehicle', 'Vehicles', 'adminlink');
  }
}

@Injectable()
export class VehicleStatusService extends AutoService<VehicleStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, VehicleStatus, 'VehicleStatus', 'VehicleStatuses', 'adminlink');
  }
}
