import { Component, OnInit } from '@angular/core';
import { Plant } from '@ov-suite/adminlink-models';
import { PlantService } from '@ov-suite/services-angular';

@Component({
  selector: 'ov-suite-plant-list',
  templateUrl: './plant-list.component.html',
  styleUrls: ['./plant-list.component.scss']
})
export class PlantListComponent {
  parentId?: number;

  // Class - Required
  formClass = Plant;

  constructor(public plantService: PlantService) {}
}
