import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouteInfo } from '@ov-suite/ui';
import Auth from '@aws-amplify/auth';
import { environment } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menuItems: RouteInfo[] = [];

  @Input() routes: Promise<RouteInfo[]>;
  @Input() projectName = 'ADMINLINK';

  @Output() signOutEvent = new EventEmitter();

  ngOnInit() {
     this.routes.then(data => { this.menuItems = data });
  }

  signOut() {
    this.signOutEvent.emit();
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));

    document.getElementById('auth-frame')['contentWindow'].postMessage(
      {
        action: 'logout'
      },
      '*'
    );

    window.location.href = `${environment.webUrl.idm}/auth/login`;
  }
}
