import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateOrEditComponent } from './create-or-edit/create-or-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from './form/form.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { HierarchyTableComponent } from './hierarchy-table/hierarchy-table.component';
import { HierarchyBreadcrumbComponent } from './hierarchy-breadcrumb/hierarchy-breadcrumb.component';
import { InputComponent } from './input/input.component';
import { AgmCoreModule } from '@agm/core';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { HierarchyTabsComponent } from './hierarchy-tabs/hierarchy-tabs.component';
import { PermissionMatrixComponent } from './permission-matrix/permission-matrix.component';
import { UserProfileWidgetComponent } from './user-profile-widget/user-profile-widget.component';
import { AgmMapComponent } from './agm-map/agm-map.component';
import { DaySelectorComponent } from './day-selector/day-selector.component';
import { TableTopBarComponent } from './table-top-bar/table-top-bar.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PillMultiSelectComponent } from './pill-multi-select/pill-multi-select.component';
import { DateTimeModule } from './date-time/date-time.module';
import { OvAutoService } from '@ov-suite/services';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MapDeepObjects } from './pipes/deep-object.pipe';
import { NormaliseString } from './pipes/text-transform.pipe';

export interface ApiOptions {
  apiUrl?: string;
  imageUploadUrl?: string;
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    DateTimeModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule
    // HttpClientModule
  ],
  declarations: [
    AgmMapComponent,
    TableComponent,
    NavbarComponent,
    SidebarComponent,
    CreateOrEditComponent,
    FormComponent,
    BulkUploadComponent,
    HierarchyTableComponent,
    InputComponent,
    TreeSelectComponent,
    HierarchyBreadcrumbComponent,
    ImageUploadComponent,
    HierarchyTabsComponent,
    PermissionMatrixComponent,
    UserProfileWidgetComponent,
    DaySelectorComponent,
    TableTopBarComponent,
    FileUploadComponent,
    PillMultiSelectComponent,
    MapDeepObjects,
    NormaliseString
  ],
  exports: [
    AgmMapComponent,
    TableComponent,
    NavbarComponent,
    SidebarComponent,
    CreateOrEditComponent,
    FormComponent,
    BulkUploadComponent,
    InputComponent,
    HierarchyTableComponent,
    ImageUploadComponent,
    HierarchyBreadcrumbComponent,
    TreeSelectComponent,
    HierarchyTabsComponent,
    PermissionMatrixComponent,
    UserProfileWidgetComponent,
    PermissionMatrixComponent,
    DaySelectorComponent,
    TableTopBarComponent,
    FileUploadComponent,
    PillMultiSelectComponent,
    MapDeepObjects,
    NormaliseString
  ],
  providers: [OvAutoService]
})
export class UiModule {
  static forRoot(options: ApiOptions): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [{ provide: 'OPTIONS', useValue: options }]
    };
  }

  static forChild() {
    return {
      ngModule: UiModule
    };
  }
}

export { RouteInfo, ChildrenItems } from './sidebar/sidebar.model';
export { NavbarComponent } from './navbar/navbar.component';

export * from './form/form.component';
export * from './hierarchy-tabs/hierarchy-tabs.model';
