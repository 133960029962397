import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditTrailComponent } from './list/audit-trail.component';
import {RouterModule} from "@angular/router";
import {AuditTrailRoutes} from "./audit-trail.routing";
import {UiModule} from "@ov-suite/ui";
import { AuditTrailService } from "@ov-suite/services-angular";


@NgModule({
  declarations: [AuditTrailComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(AuditTrailRoutes),
    UiModule,
  ],
  providers: [
    AuditTrailService
    ]
})
export class AuditTrailModule { }
