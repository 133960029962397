<div class="outer">
  <table
    class="table"
    [ngClass]="{ 'table-striped': striped }"
    [ngbTooltip]="editableRows ? editItemTip : ''"
  >
    <thead>
      <tr>
        <th *ngIf="editableRows"></th>
        <th class="table-checkbox" *ngIf="selectableRows">
          <input
            [id]="'selectAll'"
            type="checkbox"
            [checked]="isAllSelected()"
            (change)="onSelect($event)"
          />
        </th>
        <th *ngIf="showIndex" scope="col">#</th>
        <th
          scope="col"
          *ngFor="let col of filteredColumnData; let i = index"
          [ngClass]="{ 'table-status-label': col.type === 'status' }"
          (click)="onColumnHeaderClick(col)"
        >
          {{ col?.title }}
          <i
            *ngIf="!col.disableSorting && order.column !== col.id"
            class="fa fa-fw fa-sort"
          ></i>
          <i
            *ngIf="order.column === col.id"
            class="fa fa-fw"
            [ngClass]="{
              'fa-sort-asc': order.direction === 'ASC',
              'fa-sort-desc': order.direction === 'DESC'
            }"
          ></i>
          <input
            *ngIf="columnHider"
            type="checkbox"
            [checked]="isColVisible(col.id)"
            (click)="toggleCol(col.id, $event)"
            class="col-hider"
          />
        </th>
        <th *ngFor="let extraCol of extraColumns.data">
          {{ extraCol }}
        </th>
        <!--To Cater for table cancel button.-->
        <th *ngIf="hasChanges()"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th *ngIf="selectableRows" class="table-checkbox" > </th>
        <td *ngFor="let col of filteredColumnData; let j = index">
          <input
            *ngIf="
              col.id !== 'fast_actions' &&
              col.id !== 'status' &&
              !col.disableFiltering
            "
            type="text"
            (change)="onFilterChange(col, $event)"
          />
        </td>
        <td *ngFor="let dummy of extraColumns.data"></td>
      </tr>
    </tbody>
    <tbody>
      <tr
        *ngFor="let item of data; let i = index"
        [ngClass]="{ selected: i === selectedIndex }"
      >
        <td *ngIf="editableRows" class="pl-0">
          <div
            class="edit-status-block"
            [ngClass]="{
              'bg-warning': item[isEditable],
              'bg-success': item[hasChanged] && !item[isEditable]
            }"
          ></div>
        </td>
        <th class="table-checkbox" *ngIf="selectableRows">
          <input
            [id]="'row' + i"
            type="checkbox"
            class="width-400"
            [checked]="item.isSelected"
            (change)="onSelect($event, item)"
          />
        </th>
        <th *ngIf="showIndex" scope="row">{{ i + 1 }}</th>
        <td
          *ngFor="let col of filteredColumnData; let j = index"
          (dblclick)="editItems(item)"
        >
          <ng-container [ngSwitch]="col?.type">
            <ng-container *ngSwitchCase="'string'">
              <span *ngIf="!item[isEditable]">
                {{ item[col?.key] }}
              </span>
              <ov-suite-input
                *ngIf="item[isEditable]"
                [id]="item.id + 'row' + i + 'col' + j"
                type="text"
                [disabled]="col?.key === 'id'"
                (ngModelChange)="itemChanged(item)"
                [(ngModel)]="item[col?.key]"
              ></ov-suite-input>
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <span *ngIf="!item[isEditable]">
                {{ item[col?.key] }}
              </span>
              <ov-suite-input
                *ngIf="item[isEditable]"
                [id]="item.id + 'row' + i + 'col' + j"
                type="number"
                (ngModelChange)="itemChanged(item)"
                [(ngModel)]="item[col?.key]"
              ></ov-suite-input>
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              <span *ngIf="!item[isEditable]">
                {{ getDate(item[col?.key]) }}
              </span>
              <ov-suite-input
                *ngIf="item[isEditable]"
                [id]="item.id + 'row' + i + 'col' + j"
                type="date"
                (ngModelChange)="itemChanged(item)"
                [(ngModel)]="item[col?.key]"
              ></ov-suite-input>
            </ng-container>
            <ng-container *ngSwitchCase="'date-time'">
              <span *ngIf="!item[isEditable]">
                {{ getDateTime(item[col?.key]) }}
              </span>
              <ov-suite-input
                *ngIf="item[isEditable]"
                [id]="item.id + 'row' + i + 'col' + j"
                type="date-time"
                (ngModelChange)="itemChanged(item)"
                [(ngModel)]="item[col?.key]"
              ></ov-suite-input>
            </ng-container>
            <ng-container *ngSwitchCase="'dropdown'">
              <span *ngIf="!item[isEditable]">
                {{ item[col?.key] ?
                  (item[col?.key] | displayKey: col?.displayKeys) : ''
                }}
              </span>
              <ov-suite-input
                *ngIf="item[isEditable]"
                [id]="item.id + 'row' + i + 'col' + j"
                type="dropdown"
                [label]="col?.displayKeys"
                [data]="dropdownData[col?.key]"
                (ngModelChange)="itemChanged(item)"
                [(ngModel)]="item[col?.key]"
              ></ov-suite-input>
            </ng-container>
            <ng-container *ngSwitchCase="'other'">
              <a
                *ngIf="col?.queryParams || col?.routerLink; else simple"
                [routerLink]="col?.routerLink(item)"
                [queryParams]="col?.queryParams(item)"
              >
                {{ col?.action(item) }}
              </a>
              <ng-template #simple>
                <div [innerHTML]="col?.action(item)"></div>
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="'buttons'">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button
                  *ngFor="let button of col?.buttons"
                  type="button"
                  class="btn btn-secondary"
                  [style.display]="
                    button.hide
                      ? button.hide(item)
                        ? 'none'
                        : 'block'
                      : 'block'
                  "
                  [ngClass]="button.classes"
                  (click)="
                    button.action
                      ? button.action(item, {
                          ngbModal: ngbModal,
                          apiService: service
                        })
                      : routerLink(button, item)
                  "
                >
                  {{ button?.title }}
                  <i *ngIf="button?.icon" [class]="button.icon" aria-hidden="true"></i>
                </button>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <div
                aria-hidden="true"
                class="fa fa-circle table-status-icon"
                [ngStyle]="{ color: item[col?.key]?.color }"
              ></div>
              {{ item[col?.key]?.name }}
            </ng-container>
          </ng-container>
        </td>
        <td
          *ngFor="let extraCol of extraColumns.data"
          (dblclick)="editItems(item)"
        >
          <span *ngIf="!item[isEditable]">
            {{
              !!item['column_' + extraCol]
                ? extraColumns.columnData?.formatter(item['column_' + extraCol])
                : extraColumns.columnData?.formatter(extraColumns.columnData?.rowAction(item, extraCol))
            }}
          </span>
          <ov-suite-input
            *ngIf="item[isEditable]"
            [id]="item.id + 'row' + i + 'col' + extraCol"
            [type]="extraColumns.columnData?.rowReturnType"
            (ngModelChange)="itemChanged(item)"
            [(ngModel)]="item['column_' + extraCol]"
          ></ov-suite-input>
        </td>
        <td *ngIf="item[hasChanged]">
          <button
            [ngbTooltip]="'Cancel change.'"
            (click)="cancel(item)"
            type="button"
            class="btn bg-transparent"
          >
            <span class="fa fa-trash text-info"></span>
          </button>
        </td>
      </tr>
      <tr *ngFor="let blank of filler; let i = index">
        <th *ngIf="selectableRows"></th>
        <th *ngIf="showIndex" scope="row" style="opacity:0;">{{ i + 1 }}</th>
        <td
          *ngFor="let col of filteredColumnData; let j = index"
          style="opacity:0;"
        >
          x
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="editableRows" class="add-new-row" (click)="addNewItem()">
    <span class="fa fa-plus-square"></span>&nbsp;Add new invoice item.
  </div>
  <div class="row" [ngClass]="{ 'table-footer': showFiller }">
    <div class="col-md-6 dev">

      Showing {{ data.length }} of {{ totalCount }} Entries
      <div class="float-right" *ngIf="!data.length && !loading">No Data...</div>
      <div class="float-right" *ngIf="loading">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    &nbsp; &nbsp;
      <mat-form-field>
        <mat-label>Page Size</mat-label>
        <mat-select matNativeControl (valueChange)="updatePageSize($event)">
          <mat-option value="10">10</mat-option>
          <mat-option value="20">20</mat-option>
          <mat-option value="30">30</mat-option>
          <mat-option value="40">40</mat-option>
          <mat-option value="50">50</mat-option>
          <mat-option value="100">100</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <nav
        *ngIf="totalPages > 1"
        aria-label="Page navigation example"
        class="float-right"
      >
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" aria-label="Previous" (click)="pageFirst()">
              <span aria-hidden="true">FIRST</span>
              <span class="sr-only">FIRST</span>
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Previous" (click)="pageBack()">
              <span aria-hidden="true">PREVIOUS</span>
              <span class="sr-only">PREVIOUS</span>
            </a>
          </li>
          <li
            *ngFor="let i of pages"
            class="page-item"
            [ngClass]="{ active: i === currentPage }"
            (click)="selectPage(i)"
          >
            <a class="page-link" aria-current="page"
              >{{ i + 1 }} <span class="sr-only">(current)</span></a
            >
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Next" (click)="pageForward()">
              <span aria-hidden="true">NEXT</span>
              <span class="sr-only">NEXT</span>
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Next" (click)="pageLast()">
              <span aria-hidden="true">LAST</span>
              <span class="sr-only">LAST</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
<!--<i aria-hidden="true" class="fas fa-square"></i>-->
