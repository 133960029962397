import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { VehicleClass, VehicleClassStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class VehicleClassService extends AutoService<VehicleClass> {
  constructor(private apollo: Apollo) {
    super(apollo, VehicleClass, 'VehicleClass', 'VehicleClasses', 'adminlink');
  }
}

@Injectable()
export class VehicleClassStatusService extends AutoService<VehicleClassStatus> {
  constructor(private apollo: Apollo) {
    super(
      apollo,
      VehicleClassStatus,
      'VehicleClassStatus',
      'VehicleClassStatuses', 'adminlink'
    );
  }
}
