import {
  OVField,
  OVForm,
  OVSidebar,
  OVTable,
  OVEntity,
  CompiledFieldData,
  FieldParamsQuery,
  getTaggedMethod
} from '@ov-suite/ov-metadata';
import { ColumnData } from '@ov-suite/helpers-shared';
import moment from 'moment';

const validateAccessCode = async (
  data: CompiledFieldData<string>,
  dataQuery: FieldParamsQuery
): Promise<[boolean, string]> => {
  const validator = getTaggedMethod('validator', AccessCode);
  if (data.value) {
    const response = await validator(data.value);
    const codeId = dataQuery('id');
    if (response.status === 200 && !codeId) {
      return [false, 'Access Code In use'];
    }
  }

  return [
    !(data?.value?.length < 5),
    'Access Code must be more than 4 characters'
  ];
};

@OVEntity('AccessCode', 'yardlink')
@OVForm([
  ['#Access Code Details'],
  ['accessCode', 'accessCodeGenBtn'],
  ['dateFrom', 'dateTo'],
  ['maxEntryCount', '']
])
@OVSidebar([[''], [''], ['id']])
@OVTable<AccessCode>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number'
  },
  {
    key: 'accessCode',
    title: 'Access Code',
    type: 'string'
  },
  {
    key: 'maxEntryCount',
    title: 'Max Entry Count',
    type: 'number'
  },
  {
    title: 'Used Count',
    type: 'other',
    action: accessCode => `${accessCode.usedCount ? accessCode.usedCount : 0}`,
    keys: ['usedCount']
  },
  {
    title: 'Valid From',
    type: 'other',
    action: accessCode => moment(accessCode.dateFrom).format('LLL'),
    keys: ['dateFrom']
  },
  {
    title: 'Expiry Date',
    type: 'other',
    action: accessCode => moment(accessCode.dateTo).format('LLL'),
    keys: ['dateTo']
  }
])
export class AccessCode {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'string',
    title: ' Access Code',
    placeholder: 'Required',
    tooltip: 'Access Code',
    required: true,
    validator: validateAccessCode
  })
  accessCode: string;

  @OVField({
    type: 'date',
    title: 'Valid From',
    tooltip: 'Code Valid From',
    placeholder: 'Required',
    required: true
  })
  dateFrom: Date;

  @OVField({
    type: 'date',
    title: 'Expiry Date',
    required: true,
    placeholder: 'Required',
    tooltip: 'Code Expiry Date'
  })
  dateTo: Date;

  @OVField({
    type: 'number',
    required: true,
    tooltip: 'Number of times code can be used',
    title: 'Max Entry Count'
  })
  maxEntryCount?: number;

  @OVField({
    type: 'number',
    required: false,
    tooltip: 'Number of uses',
    title: 'Uses'
  })
  usedCount?: number;

  @OVField({
    type: 'button',
    title: 'Generate Access Code',
    placeholder: 'Required',
    classes: ['btn', 'btn-primary', 'mt-4', 'btn-round', 'btn-block'],
    unnecessary: true,
    bulkIgnore: true,
    action: (data: CompiledFieldData[][]) => {
      let accessCode: CompiledFieldData;
      for (const fieldRow of data) {
        if (!accessCode) {
          accessCode = fieldRow.find(item => item.propertyKey === 'accessCode');
        }
      }
      // generate random access code
      accessCode.value = Math.random()
        .toString(36)
        .substring(6)
        .toUpperCase();
    }
  })
  accessCodeGenBtn: string;
}
