import { Component, OnInit, ViewChild } from '@angular/core';
import { Location, PopStateEvent } from '@angular/common';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import swal from 'sweetalert2';

import { NavbarComponent, RouteInfo } from '@ov-suite/ui';
import Auth from '@aws-amplify/auth';
import { getTransporterIds, hasFeaturePermission, PermissionAction } from '@ov-suite/authguard-angular';
import { FeaturesConfig } from '../../features.config';

//Menu Items
@Component({
  selector: 'ov-suite-app-layout',
  templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent implements OnInit {
  public routes: Promise<RouteInfo[]>;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  @ViewChild('sidebar', { static: false }) sidebar;
  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;

  constructor(private router: Router, private location: Location) {}

  ngOnInit() {
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>(
      document.querySelector('.sidebar .sidebar-wrapper')
    );
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
        this.navbar.sidebarClose();
      }
    });

    const html = document.getElementsByTagName('html')[0];
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      html.classList.add('perfect-scrollbar-on');
    } else {
      html.classList.add('perfect-scrollbar-off');
    }
    this.routes = this.getRoutes();
  }

  isMac(): boolean {
    const platform = navigator.platform.toUpperCase();
    return platform.includes('MAC') || platform.includes('IPAD');
  }

  signOut() {
    swal
      .fire({
        title: 'Are you sure you want to sign out?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No, take me back'
      })
      .then(reason => {
        if (reason.value) {
          Auth.signOut()
            .then(() => {
              this.router.navigate(['auth/login']).then();
            })
            .catch(() => {
              swal.fire({
                title: 'There was an issue signing out of your account',
                type: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              });
            });
        }
      })
      .catch();
  }

   getRoutes = async (): Promise<RouteInfo[]> => {
   return Promise.resolve(  [
      {
        path: '/dashboard',
        title: 'dashboard',
        icontype: 'nc-icon nc-chart-pie-36',
        type: 'link'
      },
      {
        path: '/',
        title: 'Invoice Management',
        type: 'sub',
        collapse: 'invoice',
        icontype: 'nc-icon nc-layout-11',
        children: [
          {
            hidden: await hasFeaturePermission(FeaturesConfig.Rates, PermissionAction.READ),
            path: 'rate',
            title: 'Rates Management',
            ab: 'R'
          },
          {
            hidden: await hasFeaturePermission(FeaturesConfig.Invoice, PermissionAction.READ),
            path: 'invoice',
            title: 'Invoice Management',
            ab: 'IM'
          },
          { path: 'shipping',
            hidden: await hasFeaturePermission(FeaturesConfig.Shipping, PermissionAction.READ),
            title: 'Shipping Management',
            ab: 'SM'
          },
        ]
      },
      // {
      //   path: '/yard',
      //   title: 'Yard Management',
      //   type: 'sub',
      //   collapse: 'yard',
      //   icontype: 'nc-icon nc-layout-11',
      //   children: [
      //     { path: 'shipping',
      //       hidden: await hasFeaturePermission(FeaturesConfig.Shipping, PermissionAction.READ),
      //       title: 'Shipping Management',
      //       ab: 'SM'
      //     },
      //   ]
      // },
      {
        path: '/',
        title: 'General setup',
        type: 'sub',
        hidden: await hasFeaturePermission(FeaturesConfig.Rates, PermissionAction.READ) && (await getTransporterIds()).length === 0,
        collapse: 'general-setup',
        icontype: 'nc-icon nc-layout-11',
        children: [
          { path: 'weight-band', title: 'Weight Band Management', ab: 'WB' },
          { path: 'vehicle-type', title: 'Vehicle Type Management', ab: 'VT' },
          { path: 'pickup-penalty-band', title: 'Pickup Penalty Management', ab: 'PP' },
          { path: 'drop-penalty-band', title: 'Drop Penalty Band Management', ab: 'DP' },
          { path: 'origin-type', title: 'Origin Type Management', ab: 'OT' },
          { path: 'cost-type', title: 'Cost Type Management', ab: 'CT' },
          { path: 'plant', title: 'Plant Management', ab: 'P' },
          { path: 'vat', title: 'Vat Management', ab: 'V' },
          { path: 'audit-trail', title: 'Audit Trail', ab: 'AT'}
        ]
      },
      // {
      //   path: '/help-faq',
      //   title: 'HELP & FAQs',
      //   type: 'link',
      //   icontype: 'nc-icon nc-email-85'
      // },
      // {
      //   path: '/support',
      //   title: 'SUPPORT',
      //   type: 'link',
      //   icontype: 'nc-icon nc-bank'
      // }
    ]);
  }
}
