import { Component } from '@angular/core';
import { Rate } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuditTrailUseService,
  CeramicTransporterService,
  CostTypeService,
  DropPenaltyBandService,
  PickupPenaltyBandService,
  PlantService,
  RateService,
  RateStatusService,
  ShippingRouteService,
  VehicleTypeService
} from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-rate',
  templateUrl: './rate-add-or-edit.component.html',
  styleUrls: ['./rate-add-or-edit.component.scss']
})
export class RateAddOrEditComponent extends AddOrEditHelper<Rate> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public rateService: RateService,
    private rateStatusService: RateStatusService,
    private plantService: PlantService,
    private shipmentRouteService: ShippingRouteService,
    private ceramicTransporterService: CeramicTransporterService,
    private costTypeService: CostTypeService,
    private vehicleTypeService: VehicleTypeService,
    private pickupPenaltyBandService: PickupPenaltyBandService,
    private dropPenaltyBandService: DropPenaltyBandService,
    private auditTrailUseService: AuditTrailUseService
  ) {
    super(route, router, Rate, rateService, ['rate']);
    this.dataSources = {
      ...this.dataSources
    };
    rateStatusService.list({}).then(response => {
      this.dataSources = {
        ...this.dataSources,
        status: response.data
      };
    });
    plantService.list({}).then(response => {
      this.dataSources = {
        ...this.dataSources,
        plant: response.data
      };
    });
    shipmentRouteService.list({orderColumn: 'routeCode', orderDirection: 'ASC'}).then(response => {
      this.dataSources = {
        ...this.dataSources,
        route: response.data
      };
    });
    costTypeService.list({}).then(response => {
      this.dataSources = {
        ...this.dataSources,
        costType: response.data
      };
    });
    vehicleTypeService.list({}).then(response => {
      this.dataSources = {
        ...this.dataSources,
        vehicleType: response.data
      };
    });
    pickupPenaltyBandService.list({}).then(response => {
      this.dataSources = {
        ...this.dataSources,
        pickupPenaltyBand: response.data
      };
    });
    dropPenaltyBandService.list({}).then(response => {
      this.dataSources = {
        ...this.dataSources,
        dropPenaltyBand: response.data
      };
    });
    ceramicTransporterService.list({orderColumn: 'transporter.name', orderDirection: 'ASC'}).then(response => {
      this.dataSources = {
        ...this.dataSources,
        transporter: response.data
      };
    });
  }

  saveRateCreate(event) {
    this.auditTrailUseService.generateAudit(null, event, "Rate Added").then();
  }
}
