import { Routes } from '@angular/router';
import { DropPenaltyBandAddOrEditComponent } from './add-or-edit/drop-penalty-band-add-or-edit.component';
import { DropPenaltyBandListComponent } from './list/drop-penalty-band-list.component';

export const DropPenaltyBandRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: DropPenaltyBandListComponent
      },
      {
        path: 'add',
        component: DropPenaltyBandAddOrEditComponent
      },
      {
        path: 'edit',
        component: DropPenaltyBandAddOrEditComponent
      }
    ]
  }
];
