<label class="mt-2" for="tree-select">
  {{ title }}
  <span
    *ngIf="tooltip"
    aria-hidden="true"
    class="fa fa-info-circle"
    placement="right-top"
    [ngbTooltip]="!!tooltip ? tipContent : ''"
  ></span>
  <ng-template #tipContent><div [innerHTML]="tooltip"></div></ng-template>
</label>
<div>
  <div id="tree-select" class="row ts" [ngClass]="{ 'border-danger': danger }">
    <div class="col-4 ts-left">
      <input
        class="form-control"
        placeholder="Search"
        [(ngModel)]="searchValue"
        (change)="onSearchChange($event)"
      />
      <div *ngIf="!this.flat" class="ts-header">
        <button *ngIf="filteredPath.length" class="ts-back" (click)="back()">
          <i aria-hidden="true" class="nc-icon nc-minimal-left"></i>
        </button>
        <div class="ts-title">{{ this.currentTitle }}</div>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of currentData"
            (click)="select(item)"
            [ngClass]="{
              'ts-selectable':
                !this.flat && selectionMap[item.id] !== 'selected'
            }"
          >
            <th scope="row">{{ item.id }}</th>
            <td>{{ item.name }}</td>
            <td>
              <ng-container [ngSwitch]="selectionMap[item.id]">
                <button
                  *ngSwitchCase="'not'"
                  class="btn btn-primary btn-round float-right ts-button text-center"
                  (click)="add($event, item)"
                >
                  <i aria-hidden="true" class="nc-icon nc-simple-add"></i>
                </button>
                <button
                  *ngSwitchCase="'partial'"
                  class="btn btn-warning btn-round float-right ts-button text-center"
                  (click)="cautionAdd($event, item)"
                >
                  <i aria-hidden="true" class="nc-icon nc-simple-add"></i>
                </button>
                <button
                  *ngSwitchCase="'selected'"
                  class="btn btn-danger btn-round float-right ts-button text-center"
                  (click)="remove($event, item)"
                >
                  <i aria-hidden="true" class="nc-icon nc-simple-delete"></i>
                </button>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-8 ts-right">
      <table
        *ngIf="multiValue.length; else noItems"
        class="table table-striped"
      >
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th *ngIf="withQuantity" scope="col">Quantity</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of multiValue">
            <th scope="row">
              {{ withQuantity ? item[quantityKey]?.id : item?.id }}
            </th>
            <td>{{ withQuantity ? item[quantityKey]?.name : item?.name }}</td>
            <td *ngIf="withQuantity">
              <span class="ts-quantity">
                <input
                  placeholder="Quantity"
                  type="number"
                  (change)="forceUpdate()"
                  [(ngModel)]="item.quantity"
                  [ngClass]="{
                    'border-danger': danger && (item.quantity || 0) <= 0
                  }"
                  min="0"
                />
              </span>
              <span
                *ngIf="danger && (item.quantity || 0) <= 0"
                class="text-danger"
              >
                Required
              </span>
            </td>
            <td>
              <button
                class="btn btn-danger btn-round float-right ts-button text-center"
                (click)="remove($event, item)"
              >
                <i aria-hidden="true" class="nc-icon nc-simple-delete"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noItems>
        <div class="ts-no-items-container">
          <span class="ts-no-items-text">No Items Selected</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
