import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { FeaturesConfig } from './features.config';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        data: { feature: { id: FeaturesConfig.Invoice } },
        path: 'invoice',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/invoice-management/invoice-management.module').then(
            m => m.InvoiceManagementModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Shipping } },
        path: 'shipping',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './modules/shipping-management/shipping-management.module'
          ).then(m => m.ShippingManagementModule)
      },
      {
        data: { feature: { id: FeaturesConfig.Rates } },
        path: 'rate',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/rate/rate.module').then(m => m.RateModule)
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'fuel-rate',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/fuel-rate/fuel-rate.module').then(
            m => m.FuelRateModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'pending-rate-changes',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './modules/pending-rate-changes/pending-rate-changes.module'
            ).then(m => m.PendingRateChangesModule)
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'cost-type',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/cost-type/cost-type.module').then(
            m => m.CostTypeModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'drop-penalty-band',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/drop-penalty-band/drop-penalty-band.module').then(
            m => m.DropPenaltyBandModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'pickup-penalty-band',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './modules/pickup-penalty-band/pickup-penalty-band.module'
            ).then(m => m.PickupPenaltyBandModule)
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'origin-type',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/origin-type/origin-type.module').then(
            m => m.OriginTypeModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'rate-change',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/rate-change/rate-change.module').then(
            m => m.RateChangeModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Rates } },
        path: 'rate-history',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/rate-history/rate-history.module').then(
            m => m.RateHistoryModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'vehicle-type',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/vehicle-type/vehicle-type.module').then(
            m => m.VehicleTypeModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'weight-band',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/weight-band/weight-band.module').then(
            m => m.WeightBandModule
          )
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'plant',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/plant/plant.module').then(m => m.PlantModule)
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'vat',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/vat/vat.module').then(m => m.VatModule)
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true},
        path: 'audit-trail',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/audit-trail/audit-trail.module').then(m => m.AuditTrailModule)
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
