import { Injectable } from '@angular/core';
import { Vat } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class VatService extends AutoService<Vat> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), Vat, 'Vat', 'Vat', 'ceramic-portal');
  }
}
