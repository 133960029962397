export function toTitleCase(text: string): string {
  return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function camelCaseToTitleCase(text: string): string {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function kebabCaseToNormalCase(text: string): string {
  return text.replace(/([_-])/g, ' ');
}

export function slashToNormalCase(text: string): string {
  return text.replace(/([`/])/g, ' ');
}

export function propKeyToTitle(text: string): string {
  return this.toTitleCase(text.replace('.', ' '));
}

export function mapDeepObjects(key: string, object: Record<string, unknown> = {}): unknown[] | unknown {
  return key.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, object);
}
