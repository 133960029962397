import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Delivery, DeliveryStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class DeliveryService extends AutoService<Delivery> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), Delivery, 'Delivery', 'Deliveries', 'ceramic-portal');
  }
}

@Injectable()
export class DeliveryStatusService extends AutoService<DeliveryStatus> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), DeliveryStatus, 'DeliveryStatus', 'DeliveryStatuses', 'ceramic-portal');
  }
}

