import { Injectable } from '@angular/core';
import { Rate, RateStatus } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class RateService extends AutoService<Rate> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), Rate, 'Rate', 'Rates', 'ceramic-portal');
  }
}

@Injectable()
export class RateStatusService extends AutoService<RateStatus> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), RateStatus, 'RateStatus', 'RateStatuses', 'ceramic-portal');
  }
}
