import {
  OVEntity,
  OVField,
  OVForm,
  OVSearchable,
  OVSidebar,
  OVTable
} from '@ov-suite/ov-metadata';
import { MapLocation } from '../map-location.model';
import { Vehicle } from '../adminlink/vehicle.model';

@OVEntity('Stop', 'executionlink')
@OVForm([
  ['#Stop Details'],
  ['orderId', 'customer'],
  ['plannedDate'],
  ['vehicle'],
  ['map']
])
@OVSidebar([['stopStatus'], ['id']])
@OVTable<Stop>([
  {
    key: 'orderId',
    title: 'Order ID',
    type: 'string',
    hideColumnKey: 'oid',
    id: 'test'
  },
  {
    key: 'customer',
    title: 'Customer Name',
    type: 'string'
  },
  {
    key: 'stopStatus',
    title: 'Status',
    type: 'string',
    startHidden: true
  },
  {
    key: 'plannedDate',
    title: 'Planned Date',
    type: 'string',
    hideColumnKey: 'pld',
    disableFiltering: true
  },
  {
    title: 'Vehicle',
    type: 'other',
    action: data => data?.vehicle?.name,
    keys: ['vehicle.name'],
    hideColumnKey: 'veh'
  }
])
export class Stop {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Order ID',
    placeholder: 'Required',
    required: true
  })
  orderId: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Customer',
    placeholder: 'Required',
    required: true
  })
  customer: string;

  @OVField({
    type: 'string',
    title: 'Description',
    required: false,
    generated: true,
    readonly: true
  })
  stopStatus?: string;

  @OVField({
    type: () => Vehicle,
    tooltip: 'Select a vehicle.',
    // keys: ['id', 'name'],
    title: 'Vehicle',
    selectionType: 'single',
    flat: true,
    required: true,
    looselyCoupled: true,
    bulkDependency: 'registration'
  })
  vehicle: Vehicle;

  @OVField({
    type: 'map',
    required: true
  })
  map: MapLocation;

  @OVField({
    type: 'date',
    title: 'Planned Date',
    required: true
  })
  plannedDate: string;

  @OVField({
    type: 'date',
    generated: true,
    readonly: true
  })
  completedDate?: Date;
}
