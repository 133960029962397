import {Injectable} from '@angular/core';
import Auth from "@aws-amplify/auth";
import {AuditTrailService} from "./audit-trail.service";
import {getCreate} from "@ov-suite/graphql-helpers";
import {AuditTrail} from "@ov-suite/adminlink-models";

@Injectable({
  providedIn: 'root'
})
export class AuditTrailUseService {
  userName: string;

  constructor(private auditTrailService: AuditTrailService) {
    Auth.currentUserInfo().then(user => {
      this.userName = user.username;
    });
  }

  async generateAudit(oldData, newData, feature) {
    const generatedObject: AuditTrail = new AuditTrail();
    generatedObject.username = this.userName;
    generatedObject.dateUpdated = new Date();
    generatedObject.featureUpdated = feature;
    generatedObject.fieldId = newData.id.toString();

    const transporter = newData.transporter;
    if (transporter != null) {
      generatedObject.transporter = transporter.transporter.name;
    }
    const invoice = newData.invoice;
    if (invoice != null) {
      generatedObject.invoiceNumber = invoice.reference;
      generatedObject.transporter = invoice.transporter.transporter.name;
    } else if (newData.invoiceNumber != null) {
      generatedObject.invoiceNumber = newData.invoiceNumber;
    } else if (newData.reference != null) {
      generatedObject.invoiceNumber = newData.reference;
    } else {
      generatedObject.invoiceNumber = 'N/A';
    }

    const rateName = newData.name;
    if (rateName != null) {
      generatedObject.rateName = rateName;
    } else {
      generatedObject.rateName = "N/A";
    }

    if (oldData != null) {
    const json = this.findDifferences(oldData, newData);
    Object.entries(json).forEach(([key, value]) => {
      if (value instanceof Object) {
        Object.entries(value).forEach(([keytwo, valuetwo]) => {
          if (!valuetwo.toString().includes('undefined')) {
            generatedObject.previousValue = valuetwo.toString().split('?')[0];
            generatedObject.newValue = valuetwo.toString().split('?')[1];
            generatedObject.fieldUpdated = keytwo.toString();
            this.insertRecord(generatedObject);
          }
        });
      } else if (!value.toString().includes('undefined')) {
        generatedObject.previousValue = value.toString().split('?')[0];
        generatedObject.newValue = value.toString().split('?')[1];
        generatedObject.fieldUpdated = key.toString();
        this.insertRecord(generatedObject);
      }
    });
    } else {
      generatedObject.previousValue = "N/A";
      generatedObject.newValue = "N/A";
      generatedObject.fieldUpdated = "Created New"
      this.insertRecord(generatedObject);
    }
  }


  insertRecord(data) {
    this.auditTrailService.create(getCreate(data)).then();
  }

   findDifferences(oldData, newData){
    const difference = (this.isArray(oldData) ? [] : {});
    this.recursiveDifferences(oldData, newData, difference);
    return difference;
  }

   recursiveDifferences(oldData, newData, node){
    for(const property in oldData){
      if(JSON.stringify(oldData[property]) !== JSON.stringify(newData[property])){
        if(typeof newData[property] !== 'object' || newData[property] == null){
          this.addProperty(property, newData[property] + '?' + oldData[property], node);
        } else {
          if(this.isArray(newData[property])){
            this.addProperty(property, [], node);
            this.recursiveDifferences(oldData[property], newData[property], node[property]);
          } else {
            this.addProperty(property, {}, node);
            this.recursiveDifferences(oldData[property], newData[property], node[property]);
          }
        }
      }
    }
  }

  addProperty(property, value, parent){
    parent[property] = value;
  }

   isArray(obj){
    return (Object.prototype.toString.call(obj) === '[object Array]');
  }

}
