import {
  OVField,
  OVTable,
  OVSearchable,
  OVEntity, getTaggedClass
} from '@ov-suite/ov-metadata';
import { FactoryArea, InventoryLocationCategory, ProductSku } from '../adminlink';
import { InventoryLocationPallet } from './inventory-location-pallet.model';

@OVEntity('InventoryLevelOutput', 'warehouselink')
export class InventoryLevelOutput {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
  @OVField({ type: 'number' })
  quantity: number;
}

@OVEntity('InventoryProductLocation', 'warehouselink')
@OVTable<InventoryProductLocation>([
  {
    key: 'id',
    title: 'ID',
    type: 'number'
  },
  {
    title: 'Category',
    type: 'other',
    action: (item) => item.category?.name,
    keys:['category', 'category.name']
  },
  {
    title: 'Area',
    type: 'other',
    action: (item) => item.factoryArea?.name,
    keys:['factoryArea', 'factoryArea.name']
  },
  {
    key: 'name',
    title: 'Location',
    type: 'string'
  },
  {
    title: 'SKU',
    type: 'other',
    action: (item)=> item.productSku?.sku,
    keys: ['productSku', 'productSku.sku']
  },
  {
    title: 'On Hand',
    type: 'other',
    action: item => {
      return `<i class="fa fa-circle text-${item?.inventoryLevelStatus?.color}"></i> <span> ${item.inventoryLevelStatus?.quantity} </span>`;
    },
    keys: ['inventoryLevelStatus', 'inventoryLevelStatus.quantity', 'inventoryLevelStatus.color']
  },
  {
    title: 'Pallets',
    keys: [],
    type: 'buttons',
    buttons: [
      {
        icon: 'fa fa-eye',
        classes: 'btn btn-secondary btn-xs',
        action: (item, { ngbModal }) => {
          const modalRef = ngbModal.open(
            getTaggedClass('get-pallet-view-popup', InventoryLocationPallet),
            { size: 'xl' }
          );
          modalRef.componentInstance.name = "View Pallet";
          modalRef.componentInstance.inventoryProductSku = item;
        },
      }
    ]
  },
])
export class InventoryProductLocation {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
  })
  name?: string;

  @OVSearchable()
  @OVField({
    type: ()=> ProductSku,
    title: 'SKU',
    tooltip: 'Stock Keeping Unit.',
    placeholder: 'Required',
  })
  productSku?: ProductSku;

  @OVField({
    type: ()=> FactoryArea,
    keys: ['id', 'name'],
    title: 'Area',
    required: false,
  })
  factoryArea?: FactoryArea;

  @OVField({
    type: ()=> InventoryLocationCategory,
    keys: ['id', 'name'],
    title: 'Category',
  })
  category?: InventoryLocationCategory;

  @OVField({
    type: () => InventoryLevelOutput,
    dropdown: true,
    title: 'Stock in Location',
    sidebar: true,
    generated: true
  })
  inventoryLevelStatus?: InventoryLevelOutput;

}
