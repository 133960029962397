<ov-suite-hierarchy-tabs *ngIf="!!options.tabs" [tabs]="options.tabs"> </ov-suite-hierarchy-tabs>
<div class="main-content">
  <ov-suite-hierarchy-breadcrumb
    *ngIf="isHierarchy"
    [ovAutoService]="ovAutoService"
    [formClass]="options.entity"
  ></ov-suite-hierarchy-breadcrumb>
  <ov-suite-hierarchy-table
    [title]="options.tableTitle"
    [formClass]="options.entity"
    [ovAutoService]="ovAutoService"
    [api]="options.api"
    [hideAddButton]="options.hideAdd"
    [hasBulkUpload]="!options.hideImport"

  >
  </ov-suite-hierarchy-table>
</div>
