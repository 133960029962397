import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FuelRateListComponent } from './list/fuel-rate-list.component';
import { UiModule } from '@ov-suite/ui';
import { TableTopNavMenuModule } from '../../components/table-top-nav-menu/table-top-nav-menu.module';
import { FuelRateChangeComponent } from './modals/fuel-rate-change/fuel-rate-change.component';
import { RateModule } from '../rate/rate.module';
import { FuelRateRoutes } from './fuel-rate.routing';
import {
  ClientService,
  DomainService,
  OriginTypeService
} from '@ov-suite/services-angular';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(FuelRateRoutes),
    FormsModule,
    UiModule,
    TableTopNavMenuModule,
    RateModule,
  ],
  declarations: [
    FuelRateChangeComponent,
    FuelRateListComponent,
  ],
  providers: [
    OriginTypeService,
    ClientService,
    DomainService
  ]
})
export class FuelRateModule {}
