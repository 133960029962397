<div class="main-content">
  <ov-suite-create-or-edit
    [service]="rateHistoryService"
    [formClass]="entity"
    [title]="'Rates History'"
    (save)="navigateBack()"
    [dataSources]="dataSources"
  >
  </ov-suite-create-or-edit>
</div>
