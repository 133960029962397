import { Routes } from '@angular/router';
import { ShippingListComponent } from './list/shipping-list.component';
import { ShippingAddOrEditComponent } from './add-or-edit/shipping-add-or-edit.component';
import { DeliveryListComponent } from './delivery-list/delivery-list.component';

export const ShippingManagementRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ShippingListComponent
      },
      {
        path: 'edit',
        component: ShippingAddOrEditComponent
      },
      {
        path: 'detail/:id',
        component: DeliveryListComponent
      }
    ]
  }
];
