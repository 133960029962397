import gql from 'graphql-tag';
import { Task } from '@ov-suite/helpers-shared';
import { User } from '@ov-suite/adminlink-models';

export function getUserQuery(keys: (keyof Task)[]) {
  return gql(`query GetUser($userId: String!) {
    getUser(userId: $userId) {
      ${keys.join(`\n`)}
     }
   }`);
}

export function listUsersQuery(keys: (keyof User)[]) {
  return gql(`query ListUsers($orderDirection: String, $orderColumn: String, $filter: String, $limit: Int, $offset: Int) {
    listUsersWithCount(orderDirection: $orderDirection, orderColumn: $orderColumn, filter: $filter, limit: $limit, offset: $offset) {
      data {
        ${keys.join(`\n`)}
      }
      totalCount
     }
   }`);
}

// export function taskActionMutation(keys: (keyof Task)[]) {
//   return gql(`mutation TaskAction($action:TaskActionsInput!, $taskId:String!) {
//     taskAction(action: $action, taskId: $taskId) {
//        ${keys.join(`\n`)}
//      }
//    }`);
// }
