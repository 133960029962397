import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import {
  ProductCategory,
  ProductCategoryStatus
} from '@ov-suite/adminlink-models';

@Injectable()
export class ProductCategoryService extends AutoService<ProductCategory> {
  constructor(private apollo: Apollo) {
    super(apollo, ProductCategory, 'ProductCategory', 'ProductCategories', 'adminlink');
  }
}

@Injectable()
export class ProductCategoryStatusService extends AutoService<
  ProductCategoryStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo,
      ProductCategoryStatus,
      'ProductCategoryStatus',
      'ProductCategoryStatuses', 'adminlink'
    );
  }
}
