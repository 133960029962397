import { Injectable } from '@angular/core';
import { CeramicFactory, Shipment } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { mapToClass, PageReturn } from '@ov-suite/ov-metadata';
import gql from 'graphql-tag';

@Injectable()
export class CeramicFactoryService extends AutoService<CeramicFactory> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), CeramicFactory, 'CeramicFactory', 'CeramicFactories', 'ceramic-portal');
  }

  async getAllFactoriesByShipment(
    id: number,
  ): Promise<PageReturn<CeramicFactory>> {
    const name = `listFactoriesByShipment`;
    return new Promise((resolve, reject) => {
      this.apollo.use('ceramic-portal')
        .query({
          query: gql(
            `query ` +
            `${name}($shipmentId: Int!) {\n` +
            `${name}(shipmentId: $shipmentId) {\n` +
            `data {\n` +
            ` id
              factory {
                id
                name
                factoryCode
              }` +
            `}\n` +
            `totalCount\n` +
            `\n}\n}`
          ),
          variables: { shipmentId: id},
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data[name].data;
          const totalCount = response.data[name].totalCount;
          const data = rawData.map(item => mapToClass(CeramicFactory, item));
          resolve({ data, totalCount });
        }, reject);
    });
  }
}
