<div class="modal-body pt-0 pr-3 pl-3 pb-2">
  <div class="row">
    <div class="col-md-12">
      <button type="button" class="btn btn-link float-right" (click)="close()">
        <span class="nc-icon nc-simple-remove"></span>
      </button>
      <h5 id="modal-basic-title" class="mb-0 mt-2 pt-1">
        <i class="text-success fa fa-check"></i>&nbsp;
        {{ name }}
      </h5>
    </div>
  </div>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <ov-suite-input
          id="rate"
          [type]="'date-time'"
          [title]="'Select New Validity From Date Range'"
          [tooltip]="'Valid From'"
          [(ngModel)]="validFrom"
        >
        </ov-suite-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ov-suite-input
          id="rateRange"
          [type]="'date-time'"
          [title]="'Select New Validity To Date Range'"
          [tooltip]="'Valid To'"
          [(ngModel)]="validTo"
        >
        </ov-suite-input>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-7">
          <div class="ml-3 mt-4 pt-2">
            Approving
            {{
              rateChanges.length === 1
                ? rateChanges[0].name
                : rateChanges.length + ' items'
            }}.
          </div>
        </div>
        <div class="col-md-5">
          <button
            *ngIf="!onSuccess"
            [disabled]="loading || rateChanges.length === 0"
            (click)="onSave()"
            class="btn btn-secondary float-right bottom"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span *ngIf="loading" class="sr-only">Loading...</span>
            <span *ngIf="!loading" class="">Approve</span>
          </button>
        </div>
      </div>
      <div *ngIf="!loading && onSuccess && errors.length === 0" class="row">
        <div class="text-center col-md-12">
          <h6><i class="text-success fa fa-check"></i>&nbsp; Approved</h6>
        </div>
      </div>
    </div>
  </div>
</div>
