<div class="logo">
  <a href="/" class="simple-text logo-mini">
    <div class="logo-image-small">
      <img src="./assets/img/logo.png" alt="logo" />
    </div>
  </a>

  <a href="/" class="simple-text logo-normal">
    {{ projectName }}
  </a>
</div>

<div class="sidebar-wrapper">
  <ov-suite-user-profile-widget></ov-suite-user-profile-widget>
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link' && menuitem.hidden != false">
        <i class="{{ menuitem.icontype }}"></i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <hr class="sidebar-border" *ngIf="menuitem.type === 'sub' && menuitem.hidden != false" />
      <a
        data-toggle="collapse"
        *ngIf="menuitem.type === 'sub' && menuitem.hidden != false"
        (click)="menuitem['isCollapsed'] = !menuitem['isCollapsed']"
      >
        <i class="{{ menuitem.icontype }}"></i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>
      <hr class="sidebar-border" *ngIf="menuitem.type === 'sub' && menuitem.hidden != false" />

      <!--Display the submenu items-->
      <div
        id="{{ menuitem.collapse }}"
        class="collapse"
        *ngIf="menuitem.type === 'sub'"
        [ngbCollapse]="!menuitem.isCollapsed"
      >
        <ul class="nav">
          <li
            routerLinkActive="active"
            *ngFor="let childitem of menuitem.children"
          >
            <a [routerLink]="[menuitem.path, childitem.path]" *ngIf="childitem.hidden != false">
              <span class="sidebar-mini-icon">{{ childitem.ab }}</span>
              <span class="sidebar-normal">{{ childitem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li routerLinkActive="active" (click)="signOut()">
      <a>
        <i aria-hidden="true" class="fa fa-sign-out"></i>
        <p>LOG OUT</p>
      </a>
    </li>
  </ul>
</div>
