import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  DomainService,
  WeightBandService
} from '@ov-suite/services-angular';
import { WeightBandAddOrEditComponent } from './add-or-edit/weight-band-add-or-edit.component';
import { WeightBandRoutes } from './weight-band.routing';
import { WeightBandListComponent } from './list/weight-band-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(WeightBandRoutes),
    FormsModule,
    UiModule
  ],
  declarations: [
    WeightBandListComponent,
    WeightBandAddOrEditComponent,
  ],
  providers: [
    WeightBandService,
    DomainService
  ]
})
export class WeightBandModule {}
