import { Injectable } from '@angular/core';
import { StakeholderLocation } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class StakeholderLocationYardService extends AutoService<
  StakeholderLocation
> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('yardlink'),
      StakeholderLocation,
      'StakeholderLocation',
      'StakeholderLocations', 'yardlink'
    );
  }
}
