import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerService } from './adminlink/customer/customer.service';
import { DomainService } from './adminlink/domain/domain.service';
import { FactoryService } from './adminlink/factory/factory.service';

@NgModule({
  imports: [CommonModule],
  providers: [CustomerService, DomainService, FactoryService]
})
export class ServicesAngularModule {}

export * from './adminlink/customer/customer.service';
export * from './adminlink/domain/domain.service';
export * from './adminlink/factory/factory.service';
export * from './warehouselink/inventory-product-sku/inventory-product-sku.service';
export * from './warehouselink/unit/unit.service';
export * from './warehouselink/inventory-threshold/inventory-threshold.service';
export * from './warehouselink/workflow-process/workflow-process.service';
export * from './yardlink/access-code/access-code.service';
