import { OVTagMethod } from '@ov-suite/ov-metadata';
import { Rate } from '@ov-suite/adminlink-models';
import { environment } from '@ov-suite/helpers-shared';
import { Injectable } from '@angular/core';
interface RateQuery {
  name: string,
  validFrom: string;
  validTo: string;
}

@Injectable({
  providedIn: 'root'
})
export class RateValidatorService {
  @OVTagMethod('rate-validator', Rate)
  async validateRate(rate: RateQuery): Promise<Response> {
    return await fetch(
      `${environment.apiUrl.ceramicPortal}/api/rate/check/${rate.name}/${rate.validFrom}/${rate.validTo}`
    );
  }
}
