import { Routes } from '@angular/router';
import { InvoiceListComponent } from './list/invoice-list.component';
import { InvoiceDetailComponent } from './detail/invoice-detail.component';

export const InvoiceManagementRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: InvoiceListComponent
      },
      {
        path: 'detail/:id',
        component: InvoiceDetailComponent
      }
    ]
  }
];
