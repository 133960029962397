import { Injectable } from '@angular/core';
import { CostType, RateChange } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class CostTypeService extends AutoService<CostType> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), CostType, 'CostType', 'CostTypes', 'ceramic-portal');
  }
}
