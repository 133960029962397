import {
  OVField,
  OVSearchable,
  OVEntity,
  OVForm,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('DropPenaltyBand', 'ceramic-portal')
@OVForm([
  ['#Drop Penalty Band'],
  ['dpBand', 'dpApplication'],
  ['dpFrom', 'dpTo', 'dropPenalty']
])
// @OVSidebar([['status'], ['id']])
@OVTable<DropPenaltyBand>([
  {
    key: 'id',
    title: 'Drop Penalty Band ID',
    type: 'string'
  },
  {
    key: 'dpBand',
    title: 'Drop Penalty Band',
    type: 'string'
  },
  {
    key: 'dpFrom',
    title: 'Drop Penalty From',
    type: 'number'
  },
  {
    key: 'dpTo',
    title: 'Drop Penalty To',
    type: 'number'
  },
  {
    key: 'dpApplication',
    title: 'Drop Penalty Application',
    type: 'string'
  },
  {
    key: 'dropPenalty',
    title: 'Drop Penalty',
    type: 'number'
  }
])
export class DropPenaltyBand {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Drop Penalty Band',
    placeholder: '-',
    required: true
  })
  dpBand: string;

  @OVField({
    type: 'number',
    title: 'Drop Penalty From',
    placeholder: '-',
    required: true
  })
  dpFrom: number;

  @OVField({
    type: 'number',
    title: 'Drop Penalty To',
    placeholder: '-',
    required: true
  })
  dpTo: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Drop Penalty Application',
    placeholder: 'Required',
    required: true
  })
  dpApplication: string;

  @OVField({
    type: 'number',
    title: 'Drop Penalty',
    placeholder: '-',
    required: true
  })
  dropPenalty: number;
}
