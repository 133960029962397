import { Injectable } from '@angular/core';
import { Plant, RateChange } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class PlantService extends AutoService<Plant> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), Plant, 'Plant', 'Plants', 'ceramic-portal');
  }
}
