import { Injectable } from '@angular/core';
import {
  CeramicTransporter,
  Rate,
  RateChange,
  Plant,
  CeramicVehicleType
} from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { mapToClass, PageReturn } from '@ov-suite/ov-metadata';
import gql from 'graphql-tag';

@Injectable()
export class RateChangeService extends AutoService<RateChange> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('ceramic-portal'),
      RateChange,
      'RateChange',
      'RateChanges', 'ceramic-portal'
    );
  }

  async createMultipleRateChanges(
    changes: Rate,
    ratesToChange: Rate[]
  ): Promise<PageReturn<RateChange>> {
    const name = `createMultipleRateChanges`;
    return new Promise((resolve, reject) => {
      this.apollo
        .use('ceramic-portal')
        .mutate({
          mutation: gql(`mutation
            ${name}($data: RateChangeCreateManyInput!) {
            ${name}(data: $data) {
              data {
                id
              }
              totalCount
            }
          }`),
          variables: {
            data: {
              rateChangeIdList: ratesToChange.map(rate => rate.id),
              rate: changes.rate,
              rateRange: changes.rateRange,
              pickupPenaltyBandId: changes.pickupPenaltyBand.id,
              dropPenaltyBandId: changes.dropPenaltyBand.id
            }
          }
        })
        .subscribe(
          response => this.mapPageReturn(response, name, resolve),
          reject
        );
    });
  }

  async updateMultipleRateChanges(
    changes: Partial<RateChange>,
    rateChangesToUpdate: RateChange[]
  ): Promise<PageReturn<RateChange>> {
    const name = `updateMultipleRateChanges`;
    return new Promise((resolve, reject) => {
      this.apollo
        .use('ceramic-portal')
        .mutate({
          mutation: gql(`mutation
            ${name}($data: RateChangeUpdateManyInput!) {
            ${name}(data: $data) {
              data {
                id
              }
              totalCount
            }
          }`),
          variables: {
            data: {
              rateChangeIdList: rateChangesToUpdate.map(rate => rate.id),
              ...changes
            }
          }
        })
        .subscribe(
          response => this.mapPageReturn(response, name, resolve),
          reject
        );
    });
  }

  async adjustFuelRates(
    rateAdjustment: number,
    adjustmentType: string,
    transporters: CeramicTransporter[],
    plants: Plant[],
    vehicleTypes: CeramicVehicleType[],
    applicationFrom: Date,
    applicationTo: Date,
    validFrom: Date,
    validTo: Date
  ): Promise<PageReturn<RateChange>> {
    const name = `adjustFuelRates`;
    return new Promise((resolve, reject) => {
      this.apollo
        .use('ceramic-portal')
        .mutate({
          mutation: gql(`mutation
            ${name}($data: FuelRateAdjustmentInput!) {
            ${name}(data: $data) {
              data {
                id
              }
              totalCount
            }
          }`),
          variables: {
            data: {
              rateAdjustment,
              adjustmentType,
              transporterIdList: transporters.map(t => t.id),
              plantIdList: plants.map(p => p.id),
              vehicleTypeIdList: vehicleTypes.map(v => v.id),
              applicationFrom,
              applicationTo,
              validFrom,
              validTo
            }
          }
        })
        .subscribe(
          response => this.mapPageReturn(response, name, resolve),
          reject
        );
    });
  }

  mapPageReturn(response, name, resolve) {
    const rawData = response.data[name].data;
    const totalCount = response.data[name].totalCount;
    const data = rawData.map(item => mapToClass(RateChange, item));
    resolve({ data, totalCount });
  }
}
