import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { User, UserStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class UserStatusService extends AutoService<UserStatus> {
  constructor(private apollo: Apollo) {
    super(apollo.use('idmlink'), UserStatus, 'UserStatus', 'UserStatuses', 'idmlink');
  }
}

@Injectable()
export class UserService extends AutoService<User> {
  constructor(private apollo: Apollo) {
    super(apollo.use('idmlink'), User, 'User', 'Users', 'idmlink');
  }
}
