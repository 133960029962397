import { Routes } from '@angular/router';
import { RateListComponent } from './list/rate-list.component';
import { RateAddOrEditComponent } from './add-or-edit/rate-add-or-edit.component';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { FeaturesConfig } from '../../features.config';

export const RateRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: RateListComponent
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'add',
        canActivate: [AuthGuard],
        component: RateAddOrEditComponent
      },
      {
        data: { feature: { id: FeaturesConfig.Rates }, blockTransporter: true },
        path: 'edit',
        canActivate: [AuthGuard],
        component: RateAddOrEditComponent
      }
    ]
  }
];
