import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  OriginTypeService,
  DomainService,
} from '@ov-suite/services-angular';
import { OriginTypeListComponent } from './list/origin-type-list.component';
import { OriginTypeAddOrEditComponent } from './add-or-edit/origin-type-add-or-edit.component';
import { OriginTypeRoutes } from './origin-type.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(OriginTypeRoutes),
    FormsModule,
    UiModule
  ],
  declarations: [
    OriginTypeListComponent,
    OriginTypeAddOrEditComponent
  ],
  providers: [
    OriginTypeService,
    DomainService
  ]
})
export class OriginTypeModule {}
