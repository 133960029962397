import {
  OVField,
  OVSearchable,
  OVEntity,
  OVForm,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('CostType', 'ceramic-portal')
@OVForm([['costType']])
// @OVSidebar([['status'], ['id']])
@OVTable<CostType>([
  {
    key: 'id',
    title: 'Cost ID',
    type: 'number'
  },
  {
    key: 'costType',
    title: 'Cost Type',
    type: 'string'
  }
])
export class CostType {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Cost Type',
    placeholder: 'Required',
    required: true
  })
  costType: string;
}
