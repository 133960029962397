import { Injectable } from '@angular/core';
import { ExitReason } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class ExitReasonYardService extends AutoService<ExitReason> {
  constructor(private apollo: Apollo) {
    super(apollo.use('yardlink'), ExitReason, 'ExitReason', 'ExitReasons', 'yardlink');
  }
}
