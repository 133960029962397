import { Component } from '@angular/core';
import { CostType } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CostTypeService,
} from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-cost-type-add',
  templateUrl: './cost-type-add-or-edit.component.html',
  styleUrls: ['./cost-type-add-or-edit.component.scss']
})
export class CostTypeAddOrEditComponent extends AddOrEditHelper<CostType> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public costTypeService: CostTypeService
  ) {
    super(route, router, CostType, costTypeService, ['cost-type']);
    this.dataSources = {
      ...this.dataSources,
    };
  }
}
