import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import Auth from '@aws-amplify/auth';
import { map } from 'rxjs/operators';
import { SessionStorage } from '@ov-suite/helpers-angular';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const standardHeaders = {
      DomainId: SessionStorage.getSelectedDomain() ?? ''
    };

    if (request.url.includes('graphql')) {
      const clone = request.clone({
        setHeaders: standardHeaders
      })
      return next.handle(clone);
    }
    return new Observable<HttpEvent<unknown>>(observer => {
      const session = from(Auth.currentSession());
      session
        .pipe(
          map(data =>
            request.clone({
              setHeaders: {
                Authorization: data?.getIdToken()?.getJwtToken() ?? '',
                ...standardHeaders
              }
            })
          )
        )
        .subscribe(
          httpRequest => {
            next.handle(httpRequest).subscribe(
              httpEvent => observer.next(httpEvent),
              err => observer.error(err),
            );
          },
          () => {
            next.handle(request).subscribe(
              httpEvent => observer.next(httpEvent),
              err => observer.error(err),
            );
          }
        );
    });
  }
}
