import { Routes } from '@angular/router';
import { CostTypeAddOrEditComponent } from './add-or-edit/cost-type-add-or-edit.component';
import { CostTypeListComponent } from './list/cost-type-list.component';

export const CostTypeRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: CostTypeListComponent
      },
      {
        path: 'add',
        component: CostTypeAddOrEditComponent
      },
      {
        path: 'edit',
        component: CostTypeAddOrEditComponent
      }
    ]
  }
];
