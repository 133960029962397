import { Routes } from '@angular/router';
import { FuelRateListComponent } from './list/fuel-rate-list.component';

export const FuelRateRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: FuelRateListComponent
      }
    ]
  }
];
