import Auth from '@aws-amplify/auth';

export enum PermissionAction {
  CREATE = 8,
  READ = 4,
  UPDATE = 2,
  DELETE = 1
}

 export const verifyPermission = (value: number, column: 1 | 2 | 4 | 8): boolean => {
    return (value | column) === value;
 }

export const hasAppPermission = async (appId: number): Promise<boolean> => {

  return Auth.currentSession().then(session=>{
    const currentUserTokenInfo = session.getIdToken().decodePayload();
    const _apps = 'lambdaAppIds';
    const apps = JSON.parse(currentUserTokenInfo[_apps]);
    const hasAppId = apps.find(id => id === appId);

    if(hasAppId) {
      return Promise.resolve(hasAppId === appId);
    }else {
      return Promise.resolve(false);
    }
  }).catch(error=> {
    // if there is no user default to true, so the iframe handler can take over
    return Promise.resolve(true);
  });
}

export const hasPermissionByFeature = async (feature: number): Promise<number> => {
  const session = await Auth.currentSession();
  const currentUserTokenInfo = session.getIdToken().decodePayload();
  const _permissions = 'lambdaPermissionIds';
  const permissions = JSON.parse(currentUserTokenInfo[_permissions]);
  const hasPerm = permissions.find(_feature => _feature.id === feature);
  return Promise.resolve(hasPerm.value);
}

export const hasFeaturePermission = async (feature: number, action: PermissionAction): Promise<boolean> => {
    const session = await Auth.currentSession();
    const currentUserTokenInfo = session.getIdToken().decodePayload();
    const _permissions = 'lambdaPermissionIds';
    const permissions = JSON.parse(currentUserTokenInfo[_permissions]);
    const hasPerm = permissions.find(_feature => _feature.id === feature);

    if(hasPerm) {
      if(verifyPermission(hasPerm.value, action)){
        return Promise.resolve(true);
      }
    }
    return Promise.resolve(false);
}

export const getTransporterIds = async (): Promise<number[]> => {
  const session = await Auth.currentSession();
  const currentUserTokenInfo = session.getIdToken().decodePayload();
  const transporters = 'lambdaTransporterIds';
  return JSON.parse(currentUserTokenInfo[transporters]);
}


