<div>
  <div class="card slide-in-right">
    <div class="card-title row" *ngIf="!showTopBar">
      <div class="col-md-4 ht-header">
        <h5>{{ title }}</h5>
      </div>
      <div class="col-md-8 row hierarchy-input-container">
        <div class="hierarchy-input">
          <ov-suite-input
            class="radius-4"
            [type]="'text'"
            [placeholder]="'Search'"
            (change)="onSearchChange($event)"
          ></ov-suite-input>
        </div>
        <div class="hierarchy-input">
          <button
            class="btn btn-dark btn-block btn-round"
            (click)="customize()"
          >
            {{ customizing ? 'Done' : 'Customize'
            }}<span *ngIf="!customizing" class="float-right mr-2"
              ><i aria-hidden="true" class="nc-icon nc-ruler-pencil"></i></span
            ><span *ngIf="customizing" class="float-right mr-2"
              ><i aria-hidden="true" class="nc-icon nc-check-2"></i
            ></span>
          </button>
        </div>
        <div *ngIf="hasBulkUpload" class="hierarchy-input">
          <ov-suite-bulk-upload
            *ngIf="(hasPermissionsSet && hasCreatePermission) || (!hasPermissionsSet)" [metadata]="metadata"
            [filterQuery]="filter"
            (hasUploaded)="uploaded($event)"
          ></ov-suite-bulk-upload>
        </div>
        <div *ngIf="!hideAddButton" class="hierarchy-input">
          <button
            *ngIf="(hasPermissionsSet && hasCreatePermission) || (!hasPermissionsSet)"
            class="btn btn-dark btn-block btn-round"
            (click)="add()"
          >
            Add<span class="float-right mr-2"
              ><i aria-hidden="true" class="nc-icon nc-simple-add"></i
            ></span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="showTopBar">
      <ov-suite-table-top-bar
        [barTitle]="title"
        [metadata]="metadata"
        [constructorName]="formClass?.name"
        [customizing]="customizing"
        (search)="onSearchChange($event)"
        (customize)="customize()"
        [filterQuery]="filter"
        [hideBulkUpload]="hasBulkUpload"
        (hasUploaded)="uploaded($event)"
      ></ov-suite-table-top-bar>
    </div>
    <div
      class="card-body"
      [ngClass]="{ 'pt-1 table-hover': showTopBar, 'show-scrollbar': true }"
    >
      <ov-suite-table
        [showIndex]="false"
        [striped]="!showTopBar"
        [selectableRows]="selectableRows"
        [showFiller]="showFiller"
        [data]="data"
        [loading]="loading"
        [columnData]="metadata?.table"
        [totalCount]="totalCount"
        [pageSize]="pageSize"
        (changePage)="changePage($event)"
        (itemSelect)="onItemSelected($event)"
        [select]="select"
        [back]="back"
        [service]="service"
        [hideColumnKeys]="hideColumnKeys"
        [keyStore]="metadata?.name"
        (filterChange)="filterChange($event)"
        (orderChange)="orderChange($event)"
        [columnHider]="customizing"
        [editableRows]="editableRows"
        (itemsEdited)="onItemEdit($event)"
        [dropdownData]="dropdownData"
        (changePageSize)="pageSizeInput($event)"
      ></ov-suite-table>
    </div>
  </div>
  <div *ngIf="showTopBar && !hideAddButton">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <button
          *ngIf="(hasPermissionsSet && hasCreatePermission) || (!hasPermissionsSet)"
          class="btn btn-dark w-25 float-right"
          (click)="add()"
        >
          Add<span class="float-right mr-2"
            ><i aria-hidden="true" class="nc-icon nc-simple-add"></i
          ></span>
        </button>
      </div>
    </div>
  </div>
</div>
