import {
  getTaggedClass,
  OVEntity,
  OVField,
  OVForm,
  OVSearchable,
  OVSidebar,
  OVTable
} from '@ov-suite/ov-metadata';
import { IRate } from '../interfaces/rate.interface';
import { ShippingRoute } from './ceramic-shipping-route.model';
import { RateChange } from './rate-change.model';
import { Rate } from './rate.model';
import { CeramicTransporter } from './ceramic-transporter.model';
import { Plant } from './plant.model';
import { CostType } from './cost-type.model';
import { PickupPenaltyBand } from './pickup-penalty-band.model';
import { DropPenaltyBand } from './drop-penalty-band.model';
import { VehicleType } from "../adminlink";
import { WeightBand } from './weight-band.model';
import moment from 'moment';

const isBaseRate = (rateHistory: RateHistory): boolean => {
  return !rateHistory?.rateChange?.changeStatus
}

@OVEntity('RateHistory', 'ceramic-portal')
@OVForm([
  ['#Rates Management'],
  ['rate', 'rateRange', 'distance'],
  ['plant', 'route'],
  ['costType', 'vehicleType'],
  ['pickupPenalty', 'dropPenalty'],
  ['validFrom', 'validTo']
])
@OVSidebar([['changeStatus'], ['id']])
@OVTable<RateHistory>([
  {
    key: 'id',
    hideColumnKey: 'id',
    title: 'Rate ID',
    type: 'string'
  },
  {
    key: 'name',
    title: 'Rate Name',
    type: 'string'
  },
  {
    title: 'Plant Name',
    type: 'other',
    action: data => data?.plant?.name,
    keys: ['plant.name']
  },
  {
    title: 'Route',
    type: 'other',
    action: data => data?.route?.routeCode,
    keys: ['route.routeCode']
  },
  {
    title: 'Route Name',
    type: 'other',
    action: data => data?.route?.name,
    keys: ['route.name']
  },
  {
    title: 'Transporter',
    type: 'other',
    action: data => data?.transporter?.transporter.name,
    keys: ['transporter.transporter.name']
  },
  {
    title: 'Type',
    type: 'other',
    action: data => data?.vehicleType?.name,
    keys: ['vehicleType.name']
  },
  {
    title: 'Distance',
    type: 'string',
    key: 'distance'
  },
  {
    title: 'Rate',
    type: 'other',
    action: data => {
      return !isBaseRate(data) ?
        `${data?.rate} &#x21AA; ${data?.rateChange?.rate}` :
        data.rate.toString()
    },
    keys: ['rate', 'rateChange.rate']
  },
  {
    key: 'rateRange',
    title: 'Weight Band',
    type: 'string'
  },
  {
    title: 'Pickup Penalty',
    type: 'other',
    action: data => {
      return !isBaseRate(data) ?
        `${data?.pickupPenaltyBand?.pickupPenalty} &#x21AA;
        ${data?.rateChange?.pickupPenaltyBand?.pickupPenalty}` :
        data?.pickupPenaltyBand?.pickupPenalty.toString();
    },
    keys: [
      'pickupPenaltyBand.pickupPenalty',
      'rateChange.pickupPenaltyBand.pickupPenalty'
    ]
  },
  {
    title: 'Drop Penalty',
    type: 'other',
    action: data => {
      return !isBaseRate(data) ?
      `${data?.dropPenaltyBand?.dropPenalty} &#x21AA;
      ${data?.rateChange?.dropPenaltyBand?.dropPenalty}` :
      data?.dropPenaltyBand?.dropPenalty.toString();
    },
    keys: [
      'dropPenaltyBand.dropPenalty',
      'rateChange.dropPenaltyBand.dropPenalty'
    ]
  },
  {
    title: 'Status',
    type: 'other',
    action: data => `<div class="badge ${
      data?.rateChange?.changeStatus === 'APPROVED'
        ? 'badge-success'
        : data?.rateChange?.changeStatus === 'FUEL_ADJUSTMENT' ||
          (!data?.rateChange?.changeStatus && moment().isBefore(data.validTo))
        ? 'badge-info'
        : 'badge-danger'
    }"> ${!data?.rateChange?.changeStatus ?
          moment().isAfter(data.validTo) ? 'EXPIRED' : 'ADDED'
        : data.rateChange.changeStatus}
    </div>`,
    keys: ['rateChange.changeStatus']
  },
  {
    title: 'Reason',
    type: 'string',
    hideColumnKey: 'reason',
    key: 'reason'
  },
  {
    title: 'Updated On',
    type: 'other',
    keys: ['updatedAt'],
    action: col => moment(col.updatedAt).format('YYYY-MM-DD'),
  },
  {
    title: 'Updated By',
    type: 'string',
    key: 'createdBy'
  },
  {
    title: 'Valid From',
    type: 'other',
    keys: ['validFrom'],
    action: col => moment(col.validFrom).format('YYYY-MM-DD'),
  },
  {
    title: 'Valid To',
    type: 'other',
    keys: ['validTo'],
    action: col => moment(col.validTo).format('YYYY-MM-DD'),
  },
  {
    title: 'View',
    hideColumnKey: 'view',
    type: 'buttons',
    buttons: [
      {
        classes: 'btn-link fa fa-newspaper-o text-primary',
        action: (item, services) => {
          const modalRef = services.ngbModal.open(
            getTaggedClass('rate-history-detail', RateHistory),
            { size: 'xl', windowClass: 'rate-detail' }
          );
          modalRef.componentInstance.rateHistory = item;
        }
      }
    ],
    keys: ['id']
  }
])
export class RateHistory implements IRate {
  // @SidebarField({ type: 'string', title: 'Unique ID', readonly: true })
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: () => RateChange,
    title: 'Rate Change',
    generated: true,
    keys: [
      'id',
      'rate',
      'pickupPenaltyBand.pickupPenalty',
      'pickupPenaltyBand.ppFrom',
      'pickupPenaltyBand.ppTo',
      'dropPenaltyBand.dropPenalty',
      'dropPenaltyBand.dpFrom',
      'dropPenaltyBand.dpTo',
      'changeStatus'
    ]
  })
  rateChange: RateChange;

  @OVField({
    type: () => Rate,
    title: 'Current Rate',
    generated: true
  })
  currentRate: Rate;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Rate Name',
    placeholder: '-',
    required: false
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Reason',
    placeholder: '-'
  })
  reason: string;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Rate',
    placeholder: '-'
  })
  rate: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Distance',
    placeholder: '-'
  })
  distance: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Weight Band',
    placeholder: '-'
  })
  rateRange: string;

  @OVSearchable()
  @OVField({
    type: () => CeramicTransporter,
    title: 'Transporter',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['name']
  })
  transporter: CeramicTransporter;

  @OVSearchable()
  @OVField({
    type: () => ShippingRoute,
    title: 'Route',
    placeholder: '-',
    selectionType: 'simple',
    flat: true
  })
  route: ShippingRoute;

  @OVSearchable()
  @OVField({
    type: () => Plant,
    title: 'Plant',
    placeholder: '-',
    selectionType: 'simple',
    flat: true
  })
  plant: Plant;

  @OVSearchable()
  @OVField({
    type: () => VehicleType,
    title: 'Vehicle Type',
    placeholder: '-',
    selectionType: 'simple',
    flat: true
  })
  vehicleType: VehicleType;

  @OVSearchable()
  @OVField({
    type: () => CostType,
    title: 'Cost Type',
    placeholder: '-',
    selectionType: 'simple',
    flat: true
  })
  costType: CostType;

  @OVSearchable()
  @OVField({
    type: () => PickupPenaltyBand,
    title: 'Pickup Penalty Band',
    placeholder: '-',
    selectionType: 'simple',
    flat: true
  })
  pickupPenaltyBand: PickupPenaltyBand;

  @OVSearchable()
  @OVField({
    type: () => DropPenaltyBand,
    title: 'Drop Penalty',
    placeholder: '-',
    selectionType: 'simple',
    flat: true
  })
  dropPenaltyBand: DropPenaltyBand;

  @OVSearchable()
  @OVField({
    type: () => WeightBand,
    title: 'Weight Band',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['weightBand'],
    required: true
  })
  weightBand: WeightBand;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Valid From',
    placeholder: '-',
    required: true
  })
  validFrom: Date;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Valid To',
    placeholder: '-',
    required: true
  })
  validTo: Date;

  @OVField({
    type: 'date',
    title: 'Created At',
    placeholder: '-',
    required: true
  })
  createdAt: Date;

  @OVField({
    type: 'date',
    title: 'Updated At',
    placeholder: '-',
    required: true
  })
  updatedAt: Date;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Created By',
    placeholder: '-'
  })
  createdBy: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Updated By',
    placeholder: '-'
  })
  updatedBy: string;
}
