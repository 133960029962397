import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Tag, TagStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class TagService extends AutoService<Tag> {
  constructor(private apollo: Apollo) {
    super(apollo, Tag, 'Tag', 'Tags', 'adminlink');
  }
}

@Injectable()
export class TagStatusService extends AutoService<TagStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, TagStatus, 'TagStatus', 'TagStatuses', 'adminlink');
  }
}
