import { Routes } from '@angular/router';
import { PendingRateChangesListComponent } from './list/pending-rate-changes-list.component';

export const RatePendingChangeRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: PendingRateChangesListComponent
      }
    ]
  }
];
