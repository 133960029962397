import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { InventoryProductSku, InventoryProductSkuStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class InventoryProductSkuStatusService extends AutoService<InventoryProductSkuStatus> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      InventoryProductSkuStatus,
      'InventoryProductSkuStatus',
      'InventoryProductSkuStatuses', 'warehouselink'
    );
  }
}

@Injectable()
export class InventoryProductSkuService extends AutoService<InventoryProductSku> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      InventoryProductSku,
      'InventoryProductSku',
      'InventoryProductSkus', 'warehouselink'
    );
  }
}
