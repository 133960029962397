import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Auth from '@aws-amplify/auth';
import { environment } from '@ov-suite/helpers-shared';
import { UserService } from '@ov-suite/services-angular';
import { User } from '@ov-suite/adminlink-models';
import {
  getTransporterIds,
  hasAppPermission,
  hasFeaturePermission,
  PermissionAction
} from './auth.helper';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private userService: UserService, private router: Router) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.activate(state);
  }

  getAppId(): string {
    const environmentKey = Object.keys(environment.webUrl)
      .find(key => window.origin.includes(environment.webUrl[key]));
    return environment.appId[environmentKey].toString();
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // Check App Level Access
    const appId = this.getAppId();
    if (appId) {
      const hasAPP = await hasAppPermission(Number(appId));
      if (!hasAPP) {
        return Promise.resolve(false);
      }
    }

    if (next.data?.blockTransporter) {
      const transporterIds = await getTransporterIds();
      if (transporterIds.length) {
        return Promise.resolve(false);
      }
    }

    if (next.data?.feature) {
      const r = await hasFeaturePermission(
        next.data.feature.id,
        PermissionAction.READ
      );
      console.assert(r, `Your not Authorized to view this feature`);
      if (!r) {
        return Promise.resolve(false);
      }
    }
    return this.activate(state);
  }

  activate(
    state: RouterStateSnapshot,
    next?: ActivatedRouteSnapshot
  ): Promise<boolean> {
    if (!!document.getElementById('authFrame')) {
      return Promise.resolve(true);
    }

    return new Promise(resolve => {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.id = 'auth-frame';
      iframe.src = 'https://dev.account.ovsuite.com/auth.html';
      iframe.addEventListener('load', () => {
        Auth.currentSession()
          .then((cognitoUserSession: CognitoUserSession) => {
            const sessionValid = cognitoUserSession.isValid();
            if (!sessionValid) {
              window.location.href = `${environment.webUrl.idm}/auth/login?redirect=${window.location.href}`;
            }
            resolve(cognitoUserSession.isValid());
          })
          .catch(() => {
            window.location.href = `${environment.webUrl.idm}/auth/login?redirect=${window.location.href}`;
            resolve(false);
          });
      });
      document.body.appendChild(iframe);
    });
  }

  checkSession(): Promise<boolean> {
    this.getCurrentUser().then(user => {
      console.log(user);
    });
    return Auth.currentSession()
      .then((cognitoUserSession: CognitoUserSession) =>
        cognitoUserSession.isValid()
      )
      .catch(() => false);
    // return Promise.resolve(true);
  }

  getCurrentUser(): Promise<User> {
    return Auth.currentUserInfo().then(res => {
      const userEmail = res.attributes['email'];
      return (
        this.userService
          // .list(`"User"."email" ILIKE '%${userEmail}%'`, 1, 0, 'id', 'ASC')
          .list({
            search: { email: [userEmail] },
            limit: 1
          })
          .then(user => user.data[0])
      );
    });
  }
}
