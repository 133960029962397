import { Component, OnInit } from '@angular/core';
import { Delivery } from '@ov-suite/adminlink-models';
import { DeliveryService } from '@ov-suite/services-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { getFieldMetadata, getTaggedClass } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ov-suite-delivery-list',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.css']
})
export class DeliveryListComponent implements OnInit {
  id: string;
  formClass = Delivery;
  filter: Record<string, unknown[]>;
  selectedItems: Delivery[] = [];
  metadata = getFieldMetadata(this.formClass);

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public deliveryService: DeliveryService,
    private modalService: NgbModal
  ) {
    this.route.params.subscribe(param => {
      this.id = param['id'];
    });
  }

  ngOnInit() {
    // filter by shipment
    this.filter = { 'shipmentDelivery.shipmentId': [this.id] };
  }

  onItemSelect(data) {
    this.selectedItems = data;
  }

  // TODO please refactor this code to bulk update the POD links
  onUpload() {
    const modalRef = this.modalService.open(
      getTaggedClass('file-upload-popup', Object),
      { size: 'lg' }
    );
    modalRef.componentInstance.name = 'FileUploadComponent';
    modalRef.componentInstance.fileURLChange.subscribe(async url => {
      // for each selected item upload POD
      for (const row of this.selectedItems) {
        const shippingDelivery = new Delivery();
        shippingDelivery.id = row.id;
        shippingDelivery.podFileUrl = url;
        await this.deliveryService.update(shippingDelivery);
      }
    });
  }
}
