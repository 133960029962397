import { Component, OnInit } from '@angular/core';
import { Rate } from '@ov-suite/adminlink-models';
import { RateService } from '@ov-suite/services-angular';
import { getFieldMetadata } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { RateChangeComponent } from '../modals/change-rate/rate-change.component';
import { FuelRateChangeComponent } from '../../fuel-rate/modals/fuel-rate-change/fuel-rate-change.component';
import { getTransporterIds, hasFeaturePermission, PermissionAction } from '@ov-suite/authguard-angular';
import { FeaturesConfig } from '../../../features.config';

@Component({
  selector: 'ov-suite-rate-list',
  templateUrl: './rate-list.component.html',
  styleUrls: ['./rate-list.component.scss']
})
export class RateListComponent implements OnInit {
  // Class - Required
  formClass = Rate;
  selectedItems: Rate[] = [];
  tabs = [];
  activeTab = '';
  metadata = getFieldMetadata(this.formClass);
  hideColumnKeys: string[] = [];
  canAddNewRate: boolean;
  canUpdateRates: boolean;

  constructor(
    public rateService: RateService,
    private ngbModal: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    getTransporterIds().then(res => {
      for (const columData of getFieldMetadata(Rate).table) {
        if (
          columData.hideColumnKey &&
          columData.hideColumnKey !== 'view' &&
          columData.hideColumnKey !== 'rateName'
        ) {
          this.hideColumnKeys.push(columData.hideColumnKey);
        }
      }
      if (res.length) {
        this.hideColumnKeys.push('fast_actions');
        this.tabs = [
          { title: 'CURRENT RATES', key: 'rate' },
          { title: 'HISTORY', key: 'rate-history' }
        ];
      } else {
        hasFeaturePermission(FeaturesConfig.Rates, PermissionAction.CREATE).then(result=> {
          this.canAddNewRate = result;
        });

        hasFeaturePermission(FeaturesConfig.Rates, PermissionAction.UPDATE).then(result=> {
          this.canUpdateRates = result;
        });
        this.tabs = [
          { title: 'CURRENT RATES', key: 'rate' },
          { title: 'FUEL RATES', key: 'fuel-rate' },
          { title: 'NEW REQUESTS', key: 'rate-change' },
          { title: 'PENDING APPROVAL', key: 'pending-rate-changes' },
          { title: 'HISTORY', key: 'rate-history' }
        ];

      }
      if (this.router.url === '/rate') {
        this.activeTab = this.tabs[0].key;
      } else {
        this.activeTab = this.tabs[1].key;
      }
    });

  }

  onItemSelect(event) {
    this.selectedItems = event;
  }

  swapRates() {
    if (this.activeTab === 'rate') {
      const modalRef = this.ngbModal.open(
        RateChangeComponent,
        { size: 'xl' }
      );
      modalRef.componentInstance.name = 'Rate Change';
      modalRef.componentInstance.ratesToChange = this.selectedItems;
    } else {
      const modalRef = this.ngbModal.open(
        FuelRateChangeComponent,
        { size: 'xl' }
      );
      modalRef.componentInstance.name = 'Adjust Fuel Rate';
      modalRef.componentInstance.ratesToChange = this.selectedItems;
    }
  }

  onTabPress(event) {
    this.router.navigate([event]).then();
  }

  add() {
    this.router.navigate(['rate', 'add']).then();
  }
}
