import {
  OVField,
  OVTable,
  OVEntity,
  OVSearchable
} from '@ov-suite/ov-metadata';
import { CeramicInvoice } from './ceramic-invoice.model';
import { Shipment } from './shipment.model';
import { CeramicFactory } from './ceramic-factory.model';
import { SharedCost } from './shared-cost.model';
import { CostType } from './cost-type.model';
import swal from 'sweetalert2';
const hideApproval = (item: CeramicInvoiceItem) => {
  return item.invoice.hardLockOn &&
  (item.changeStatus === InvoiceItemStatus.APPROVED ||
    item.changeStatus === InvoiceItemStatus.DEFAULT ||
    item.changeStatus === InvoiceItemStatus.REJECTED)
}

@OVEntity('CeramicInvoiceItemStatus', 'ceramic-portal')
export class CeramicInvoiceItemStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

export enum InvoiceItemStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  DEFAULT = 'DEFAULT'
}

@OVEntity('InvoiceItem', 'ceramic-portal')
@OVTable<CeramicInvoiceItem>([
  {
    keys: ['changeStatus'],
    title: 'Status',
    type: 'other',
    action: data => `<div class="badge ${
      data?.changeStatus === 'APPROVED' || data?.changeStatus === 'DEFAULT'
        ? 'badge-success'
        : data?.changeStatus === 'PENDING'
        ? 'badge-info'
        : 'badge-danger'
    }"> ${data?.changeStatus}
    </div>`,
  },
  {
    key: 'id',
    title: 'ID',
    type: 'string'
  },
  {
    key: 'costType',
    title: 'Invoice Item',
    type: 'dropdown',
    displayKeys: ['costType']
  },
  {
    key: 'shipment',
    title: 'Shipment No.',
    type: 'dropdown',
    displayKeys: ['shipmentCode']
  },
  {
    title: 'Total Cost',
    type: 'other',
    keys: ['totalCost'],
    action: (data) => 'R' + (data.totalCost ?? '0'),
  },
  {
    keys: ['invoicePrice'],
    title: 'Invoice Price (Excl Vat)',
    type: 'other',
    action: (data) => 'R' + (data.invoicePrice ?? '0'),
  },
  {
    keys: ['invoicePriceInclVat'],
    title: 'Invoice Price (Incl Vat)',
    type: 'other',
    action: (data) => 'R' + (data.invoicePriceInclVat ?? '0'),
  },
  {
    key: 'baseRate',
    title: 'Base Rate',
    type: 'number'
  },
  {
    key: 'adjustedRate',
    title: 'Adjusted Rate',
    type: 'number'
  },
  {
    keys: ['penaltyCost'],
    title: 'Penalty Cost',
    type: 'other',
    action: (data) => 'R' + (data.penaltyCost ?? '0'),
  },
  {
    key: 'transporterFleetNo',
    title: 'Transporter Fleet No.',
    type: 'string'
  },
  {
    key: 'transporterReference',
    title: 'Transporter Reference No.',
    type: 'string'
  },
  {
    key: 'description',
    title: 'Description',
    type: 'string'
  },
  {
    title: 'Total Weight',
    type: 'other',
    action: data => `${data.changeStatus !== InvoiceItemStatus.DEFAULT ?
      data?.sharedCosts.reduce((start, sc) => start + sc.netWeight, 0) :
      data?.totalWeight
    }`,
    keys: [
      'sharedCosts.id',
      'sharedCosts.netWeight',
      'totalWeight',
      'changeStatus',
    ],
  },
  {
    startHidden: true,
    disableFiltering: true,
    id: 'factories',
    key: 'factories',
    type: 'column',
    columnKey: 'factory.factoryCode',
    rowAction: (data, column) => {
      return data?.sharedCosts?.find(
        cost => cost?.factory?.factory?.factoryCode === column
      )?.amount;
    },
    keys: [
      'sharedCosts.id',
      'sharedCosts.factory.id',
      'sharedCosts.factory.factory.id',
      'sharedCosts.factory.factory.factoryCode',
      'sharedCosts.factory.factory.name',
      'sharedCosts.amount'
    ],
    formatter: (data: number) => {
      return data ? 'R' + Math.round(data * 100) / 100 : '';
    },
    rowReturnType: 'number'
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actions',
    buttons: [
      {
        classes: 'btn btn-link nc-icon nc-check-2 text-primary',
        hide: hideApproval,
        action: (item, { apiService }) => {
          item.changeStatus = InvoiceItemStatus.APPROVED;
          const { changeStatus, approverNote, id } = item;
          apiService.update({
            id,
            changeStatus,
            approverNote
          } as CeramicInvoiceItem).then(() => {
            swal
              .fire({
                title: 'Approved',
                type: 'success',
                confirmButtonText: 'Done',
              }).then().catch();
          });
        }
      },
      {
        classes: 'btn btn-link nc-icon nc-simple-remove text-primary',
        hide: hideApproval,
        action: (item, { apiService }) => {
          swal
            .fire({
              title: 'Reject Reason',
              type: 'warning',
              showCancelButton: true,
              input: 'text',
              confirmButtonText: 'Reject',
              cancelButtonText: 'cancel'
            })
            .then(reason => {
              if (reason.value) {
                item.approverNote = reason.value;
                item.changeStatus = InvoiceItemStatus.REJECTED;
                const { changeStatus, approverNote, id } = item;
                apiService.update({
                  id,
                  changeStatus,
                  approverNote
                } as CeramicInvoiceItem).then(() => {
                  swal
                    .fire({
                      title: 'Declined',
                      type: 'success',
                      confirmButtonText: 'Done',
                    }).then().catch();
                });
              }
            }).catch();
        }
      }
    ],
    keys: ['invoice.hardLockOn']
  }
])
export class CeramicInvoiceItem {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: () => CeramicInvoice,
    title: 'Invoice',
    placeholder: 'Required',
    required: false,
    keys: ['id', 'hardLockOn', 'softLockOn']
  })
  invoice: CeramicInvoice;

  @OVSearchable()
  @OVField({
    type: () => CostType,
    title: 'Invoice Item',
    placeholder: 'Required',
    required: false
  })
  costType: CostType;

  @OVSearchable()
  @OVField({
    type: () => Shipment,
    keys: ['id', 'shipmentCode', 'netWeight'],
    title: 'Shipment',
    placeholder: 'Required',
    required: false
  })
  shipment: Shipment;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: '',
    placeholder: 'Required',
    required: false
  })
  additionalCost: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: '',
    placeholder: 'Required',
    required: false
  })
  transporterFleetNo: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: '',
    placeholder: 'Required',
    required: false
  })
  transporterReference: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: '',
    placeholder: 'Required',
    required: false
  })
  description: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: '',
    placeholder: 'Required',
    required: false
  })
  changeStatus: InvoiceItemStatus;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: '',
    placeholder: 'Required',
    required: false
  })
  approverNote: string;

  @OVField({
    type: () => [CeramicFactory],
    title: 'Factories',
    generated: true,
    keys: [
      'id',
      'factory.id',
      'factory.factoryCode',
      'factory.name']
  })
  factories: CeramicFactory[];

  @OVField({
    type: () => [SharedCost],
    title: 'SharedCosts',
    keys: [
      'id',
      'factory.id',
      'factory.factory.id',
      'factory.factory.factoryCode',
      'factory.factory.name',
      'amount',
      'netWeight'
    ]
  })
  sharedCosts: SharedCost[];

  @OVField({
    type: 'number',
    title: 'Applied Rate ID',
  })
  appliedRateId: number;

  @OVField({
    type: 'number',
    title: 'Base Rate',
  })
  baseRate: number;

  @OVField({
    type: 'number',
    title: 'Adjusted Rate',
  })
  adjustedRate: number;

  @OVField({
    type: 'number',
    title: 'Penalty Cost',
  })
  penaltyCost: number;

  @OVField({
    type: 'number',
    title: 'Total Cost',
  })
  totalCost: number;

  @OVField({
    type: 'number',
    title: 'Invoice Price (Excl Vat)',
  })
  invoicePrice: number;

  @OVField({
    type: 'number',
    title: 'Invoice Price (Incl Vat)',
  })
  invoicePriceInclVat: number;

  @OVField({
    type: 'number',
    title: 'Total Weight',
  })
  totalWeight: number;

  @OVField({
    type: 'number',
    title: 'Pickup Penalty',
  })
  pickupPenalty: number;

  @OVField({
    type: 'number',
    title: 'Drop Penalty',
  })
  dropPenalty: number;

  @OVField({
    type: 'number',
    title: 'Route Cost',
  })
  routeCost: number;

}
