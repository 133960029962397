import { Component, OnInit } from '@angular/core';
import { CeramicVehicleType } from '@ov-suite/adminlink-models';
import { VehicleTypeService } from '@ov-suite/services-angular';
import { HierarchyTab } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-vehicle-type-list',
  templateUrl: './vehicle-type-list.component.html',
  styleUrls: ['./vehicle-type-list.component.scss']
})
export class VehicleTypeListComponent {
  parentId?: number;

  // Class - Required
  formClass = CeramicVehicleType;

  constructor(public vehicleTypeService: VehicleTypeService) {}
}
