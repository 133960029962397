import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Client } from '@ov-suite/adminlink-models';

@Injectable()
export class ClientService extends AutoService<Client> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), Client, 'Client', 'Clients', 'ceramic-portal');
  }
}
