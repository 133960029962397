import { Component } from '@angular/core';

@Component({
  selector: 'ov-suite-fuel-rate-list',
  templateUrl: './fuel-rate-list.component.html',
  styleUrls: ['./fuel-rate-list.component.scss']
})
export class FuelRateListComponent {
  constructor() {}
}
