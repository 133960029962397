import { OVField, OVEntity, OVForm, OVTable } from '@ov-suite/ov-metadata';

@OVEntity('WeightBand', 'ceramic-portal')
@OVForm([['weightFrom', 'weightTo'], ['weightBand']])
// @OVSidebar([['status'], ['id']])
@OVTable<WeightBand>([
  {
    key: 'id',
    title: 'Weight ID',
    type: 'string'
  },
  {
    key: 'weightBand',
    title: 'Weight Band',
    type: 'string'
  },
  {
    key: 'weightFrom',
    title: 'Weight From',
    type: 'number'
  },
  {
    key: 'weightTo',
    title: 'Weight To',
    type: 'number'
  }
])
export class WeightBand {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Weight Band',
    placeholder: '-',
    required: true
  })
  weightBand: string;

  @OVField({
    type: 'number',
    title: 'Weight From',
    placeholder: '-',
    required: true
  })
  weightFrom: number;

  @OVField({
    type: 'number',
    title: 'Weight To',
    placeholder: '-',
    required: true
  })
  weightTo: number;
}
