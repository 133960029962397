import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function getFormByKey(
  keys: ('id' | 'key' | 'json')[] = ['json']
): DocumentNode {
  return gql(
    `query GetFormByKey($key: String!) {\n getFormByKey(key: $key) {\n
      id
      key
      json
    \n}\n}`
  );
}
