import { Routes } from '@angular/router';
import { PickupPenaltyBandAddOrEditComponent } from './add-or-edit/pickup-penalty-band-add-or-edit.component';
import { PickupPenaltyBandListComponent } from './list/pickup-penalty-band-list.component';

export const PickupPenaltyBandRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: PickupPenaltyBandListComponent
      },
      {
        path: 'add',
        component: PickupPenaltyBandAddOrEditComponent
      },
      {
        path: 'edit',
        component: PickupPenaltyBandAddOrEditComponent
      }
    ]
  }
];
