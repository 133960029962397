import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import {
  AbstractValueAccessor,
  MakeProvider
} from '../input/abstruct-value-accessor';

import { MapsAPILoader, AgmGeocoder, GeocoderResult } from '@agm/core';
import { MapLocation } from '@ov-suite/adminlink-models';
import { google } from 'google-maps';

@Component({
  selector: 'ov-suite-agm-map',
  templateUrl: './agm-map.component.html',
  styleUrls: ['./agm-map.component.scss'],
  providers: [MakeProvider(AgmMapComponent)],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AgmMapComponent extends AbstractValueAccessor<MapLocation>
  implements OnInit {
  @Input() id: string;
  @Input() danger: boolean;
  @Input() disabled: boolean;

  @ViewChild('mapSearch')
  public searchElementRef: ElementRef;
  latitude = -26;
  longitude = 22;
  zoom = 6;
  address: string;
  private geoCoder: AgmGeocoder;
  private searchTimeout = null;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
    super();
  }

  writeValue(value: MapLocation) {
    this.val = value;
    this.onChange(value);
    if (value) {
      this.address = value.address;
      this.latitude = value.latitude;
      this.longitude = value.longitude;
      this.zoom = 12;
    }
  }

  valueChange(item: MapLocation) {
    this.writeValue(item);
  }

  ngOnInit() {
    this.initialiseMap();
  }

  initialiseMap() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new AgmGeocoder(this.mapsAPILoader);

      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {}
      );
      autocomplete.addListener('place_changed', () => {
        if (autocomplete.getPlace())
          this.ngZone.run(() => {
            //get the place result
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom
            this.address = place.formatted_address;
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18;
            this.emitCoOrdinates();
          });
      });
    });
  }

  locationChange() {
    this.searchTimeout = null;
    this.searchTimeout = setTimeout(() => {
      this.getAddress(this.latitude, this.longitude);
    }, 500);
  }

  emitCoOrdinates() {
    const map = new MapLocation();
    map.address = this.address;
    map.longitude = this.longitude;
    map.latitude = this.latitude;
    this.valueChange(map);
  }

  markerDragEnd($event) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder
      .geocode({ location: { lat: latitude, lng: longitude } })
      .subscribe((results: GeocoderResult[]) => {
        if (results && results.length > 0) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          this.address = '';
        }
        this.emitCoOrdinates();
      });
  }
}
