import { Injectable } from '@angular/core';
import {
  CeramicInvoiceItem,
  CeramicInvoiceItemStatus
} from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class CeramicInvoiceItemService extends AutoService<CeramicInvoiceItem> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('ceramic-portal'),
      CeramicInvoiceItem,
      'InvoiceItem',
      'InvoiceItems', 'ceramic-portal'
    );
  }
}

@Injectable()
export class CeramicInvoiceItemStatusService extends AutoService<
  CeramicInvoiceItemStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('ceramic-portal'),
      CeramicInvoiceItemStatus,
      'InvoiceItemStatus',
      'InvoiceItemStatuses', 'ceramic-portal'
    );
  }
}
