import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { AutoService } from '@ov-suite/services';
import { buildQuery } from '@ov-suite/graphql-helpers';
import {
  getFieldMetadata,
  FieldMetadata,
  SearchableMetadata,
  Constructor,
  GenericHierarchy,
  getSearchableMetadata
} from '@ov-suite/ov-metadata';
import {
  AbstractValueAccessor,
  MakeProvider
} from '../input/abstruct-value-accessor';

type GenericHierarchyType = GenericHierarchy;

export interface WithQuantity<T> {
  quantity: number;

  [key: string]: T | number;
}

@Component({
  selector: 'ov-suite-pill-multi-select',
  templateUrl: './pill-multi-select.component.html',
  styleUrls: ['./pill-multi-select.component.scss'],
  providers: [MakeProvider(PillMultiSelectComponent)],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PillMultiSelectComponent<T extends GenericHierarchyType>
  extends AbstractValueAccessor<
    T | T[] | WithQuantity<T> | WithQuantity<T>[] | (T | WithQuantity<T>)[]
  >
  implements OnInit {
  @Input() title = '';

  @Input() tooltip = '';

  @Input() danger = false;

  @Input() flat = false;

  @Input() single = false;

  @Input() queryType = 'postgres';

  @Input() displayKeys: string[] = ['name'];

  private _withQuantity = false;

  public listVisible = false;

  @Input() set withQuantity(input: boolean) {
    this._withQuantity = !!input;
    if (this.metadata) {
      this.quantityKey = this.metadata.fields.find(
        item => item.propertyKey !== 'quantity' && item.propertyKey !== 'id'
      )?.propertyKey;
    }
  }

  get withQuantity() {
    return this._withQuantity;
  }

  get multiValue() {
    if (Array.isArray(this.val)) {
      return this.val;
    }
    if (!this.val) {
      return [];
    }
    return [this.val];
  }

  name: string;
  _formClass: Constructor;
  quantityKey: string;

  @Input() set formClass(formClass: Constructor | Constructor[]) {
    this._formClass = Array.isArray(formClass) ? formClass[0] : formClass;
    if (formClass) {
      this.metadata = getFieldMetadata(this._formClass);
      this.name = this.metadata.name;
      if (this.withQuantity) {
        this.quantityKey = this.metadata.fields.find(
          item => item.propertyKey !== 'quantity' && item.propertyKey !== 'id'
        ).propertyKey;
      }
    }
    if (this.name) {
      this.searchableMetadata = getSearchableMetadata(this._formClass);
    }
  }

  get formClass() {
    return this._formClass;
  }

  metadata: FieldMetadata;
  searchableMetadata: SearchableMetadata;

  currentParentId: number | string = null;

  _currentData: T[] = [];
  set currentData(data: T[]) {
    this._currentData = data;
    if (data?.length) {
      this.currentTitle = data[0].parent?.name ?? 'All';
    }
    this.calculateSelection();
  }

  get currentData() {
    return this._currentData;
  }

  currentTitle = this.title;

  filteredPath: number[] = [];

  selectedData: (T | WithQuantity<T>)[] = [];

  searchValue: string;
  searchQuery = '';

  selectionMap: {
    [key: number]: 'selected' | 'not' | 'partial' | 'unavailable';
  } = {};

  _service: AutoService<T>;
  @Input() set service(service: AutoService<T>) {
    this._service = service;
    if (service) {
      this.reset();
    }
  }

  get service() {
    return this._service;
  }

  constructor() {
    super();
  }

  ngOnInit() {}

  calculateSelection() {
    this.currentData?.forEach(item => {
      this.selectionMap[item.id] = this.getSelectionType(item);
    });
  }

  getSelectionType(item: T): 'selected' | 'not' | 'partial' | 'unavailable' {
    if (this.flat) {
      return this.multiValue.some(entry => entry.id === item.id)
        ? 'selected'
        : 'not';
    }
    const path = item.path.slice(0, -1).split('.');
    path.pop();
    const unavailable = this.multiValue.some(entry =>
      path.includes(entry.id.toString())
    );
    if (unavailable) {
      return 'unavailable';
    }
    const found = this.multiValue.filter(entry =>
      this.withQuantity
        ? (<T>(<WithQuantity<T>>entry)[this.quantityKey]).path.includes(
            item.id + '.'
          )
        : (<T>entry).path.includes(item.id + '.')
    );
    if (found?.length) {
      if (found.some(entry => entry.id === item.id)) {
        return 'selected';
      }
      return 'partial';
    }
    return 'not';
  }

  writeValue(
    value:
      | T
      | T[]
      | WithQuantity<T>
      | WithQuantity<T>[]
      | (T | WithQuantity<T>)[]
  ) {
    this.val = value;
    this.onChange(value);
    this.calculateSelection();
  }

  getSearch(input?: number | string): string {
    const query = this.flat
      ? ''
      : buildQuery(this.name, { parentId: { eq: input ?? null } });
    return this.searchQuery ? this.searchQuery : query;
  }

  async select(item: T) {
    if (!this.flat && this.selectionMap[item.id] === 'not') {
      this.filteredPath = item.path
        .slice(0, -1)
        .split('.')
        .map(entry => Number(entry));
      this.currentTitle = item.name;
      this.searchValue = '';
      this.searchQuery = '';
      this.service
        .list({}) //this.getSearch(item.id), 100, 0, 'id', 'ASC' // todo convert this to new system
        .then(response => {
          this.currentData = response.data;
          this.currentParentId = item.id;
        });
    }
  }

  async back() {
    if (this.flat) {
      return;
    }
    this.filteredPath.pop();
    const parentId = this.filteredPath.length
      ? this.filteredPath[this.filteredPath.length - 1]
      : undefined;
    this.service
      .list({}) //this.getSearch(parentId), 100, 0, 'id', 'ASC' // todo convert this to new system
      .then(response => {
        this.currentData = response.data;
        this.currentParentId = parentId ?? null;
      });
  }

  async reset() {
    this.service
      .list({}) //this.getSearch(), 100, 0, 'id', 'ASC' // todo convert this to new system
      .then(response => {
        this.currentData = response.data;
      });
  }

  onSearchChange(event: Event): void {
    if ((<HTMLInputElement>event?.target)?.value) {
      this.search((<HTMLInputElement>event.target).value);
    } else {
      this.searchQuery = '';
      this.reset();
    }
  }

  search(text: string): void {
    let filter = '';
    this.searchableMetadata.fields.forEach(field => {
      if (filter) {
        filter += ` OR "${this.name}"."${field.propertyKey}" ILIKE '%${text}%'`;
      } else {
        filter = `"${this.name}"."${field.propertyKey}" ILIKE '%${text}%'`;
      }
    });

    this.searchQuery = `(${filter})`;

    this.reset();
  }

  async cautionAdd(event, item: T) {
    event?.stopPropagation();
    if (
      item &&
      confirm('This will replace other items further down the tree')
    ) {
      await this.add(event, item);
    }
    return;
  }

  async add(event, item: T) {
    event?.stopPropagation();
    let actualItem: T | WithQuantity<T> = item;

    if (this.withQuantity) {
      actualItem = { quantity: 1, [this.quantityKey]: item };
    }

    if (this.single) {
      this.writeValue(actualItem);
      return;
    }
    this.selectedData.push(actualItem);
    let val;
    if (!this.val) {
      val = [actualItem];
    } else {
      if (this.flat) {
        val = [...this.multiValue, actualItem];
      } else {
        val = [
          ...this.multiValue.filter(existing =>
            this.withQuantity
              ? !existing[this.quantityKey].path.includes(`${actualItem.id}.`)
              : !(<T>existing).path.includes(`${actualItem.id}.`)
          ),
          actualItem
        ];
      }
    }

    this.writeValue(val);
    this.toggleList();
  }

  remove(event, item: T | WithQuantity<T>) {
    event?.stopPropagation();
    if (this.single) {
      this.writeValue(null);
      return;
    }
    this.writeValue(
      this.multiValue.filter(selected =>
        this.withQuantity
          ? (<T>(<WithQuantity<T>>selected)[this.quantityKey])?.id !==
            (<T>(<WithQuantity<T>>item)[this.quantityKey])?.id
          : (<T>selected).id !== (<T>item).id
      )
    );
  }

  forceUpdate() {
    this.onChange(this.value);
  }

  toggleList() {
    this.listVisible = !this.listVisible;
  }
}
