import { Injectable } from '@angular/core';
import {
  CeramicInvoice,
  CeramicInvoiceStatus
} from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class CeramicInvoiceService extends AutoService<CeramicInvoice> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('ceramic-portal'),
      CeramicInvoice,
      'Invoice',
      'Invoices',
      'ceramic-portal'
    );
  }
}

@Injectable()
export class CeramicInvoiceStatusService extends AutoService<
  CeramicInvoiceStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('ceramic-portal'),
      CeramicInvoiceStatus,
      'InvoiceStatus',
      'InvoiceStatuses',
      'ceramic-portal'
    );
  }
}
