// Auto Grahpql
import { Apollo, ApolloBase } from 'apollo-angular';
import {
  DynamicService,
  listWithCountQueryKeys,
  createMutationKeys,
  updateMutationKeys,
  deleteMutationKeys,
  getQueryKeys,
  getAncestorQueryKeys,
  listQueryKeys
} from '@ov-suite/graphql-helpers';
import {
  Constructor,
  FieldMetadata,
  GenericHierarchy,
  getFieldMetadata,
  mapToClass,
  PageReturn
} from '@ov-suite/ov-metadata';
import { OvAutoServiceListParams } from '../..';

export interface DataSources<
  T extends GenericHierarchy = { id: number | string }
> {
  [key: string]: T[] | AutoService<T> | unknown;
}

export class AutoService<T extends GenericHierarchy>
  implements DynamicService<T> {
  metadata: FieldMetadata<T>;

  override: string;

  constructor(
    private internalApollo: Apollo | ApolloBase,
    private entity: Constructor<T>,
    private name: string,
    private plural: string = `${name}s`,
    private defaultApi: string
  ) {
    this.metadata = getFieldMetadata(entity);
  }

  async list(
    params: Omit<OvAutoServiceListParams<T>, 'entity'>
  ): Promise<PageReturn<T>> {
    const {
      offset = 0,
      limit = 100,
      query,
      search,
      filter,
      keys = [],
      orderColumn = 'id',
      orderDirection = 'ASC'
    } = params;
    const name = `list${this.name}`;
    return new Promise((resolve, reject) => {
      this.internalApollo
        .query({
          query: listQueryKeys({ name, input: this.entity, metadata: this.metadata, specificKeys: keys, api: this.defaultApi }),
          variables: {
            params: {
              limit,
              offset,
              orderColumn,
              orderDirection,
              search,
              filter,
              query
            }
          },
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data[name].data;
          const totalCount = response.data[name].totalCount;
          const data = rawData.map(item => mapToClass(this.entity, item));
          resolve({ data, totalCount });
        }, reject);
    });
  }

  async get(id: number): Promise<T> {
    const name = `get${this.name}`;
    return new Promise((resolve, reject) => {
      this.internalApollo
        .query({
          query: getQueryKeys({ name, input: this.entity, metadata: this.metadata, api: this.defaultApi }),
          variables: { id },
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(this.entity, rawData);
          resolve(data);
        }, reject);
    });
  }

  async create(item: Omit<T, 'id'>): Promise<T> {
    const name = `create${this.name}`;
    return new Promise((resolve, reject) => {
      this.internalApollo
        .mutate({
          mutation: createMutationKeys({ name, input: this.entity, metadata: this.metadata, api: this.defaultApi }),
          variables: { data: item }
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(this.entity, rawData);
          resolve(data);
        });
    });
  }

  // TODO require ID
  async update(item: Partial<T>): Promise<T> {
    const name = `update${this.name}`;
    return new Promise((resolve, reject) => {
      this.internalApollo
        .mutate({
          mutation: updateMutationKeys({ name, input: this.entity, metadata: this.metadata, api: this.defaultApi } ),
          variables: { data: item }
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(this.entity, rawData);
          resolve(data);
        }, reject);
    });
  }

  async delete(id: number | string): Promise<void> {
    const name = `delete${this.name}`;
    return new Promise((resolve, reject) => {
      this.internalApollo
        .mutate({
          mutation: deleteMutationKeys({name}),
          variables: { id }
        })
        .subscribe(() => resolve(), reject);
    });
  }

  async listAncestors(id: number): Promise<T[]> {
    const name = `list${this.name}Ancestors`;
    return new Promise((resolve, reject) => {
      this.internalApollo
        .query({
          query: getAncestorQueryKeys({ name, input: this.entity, metadata: this.metadata, api: this.defaultApi }),
          variables: { id },
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = rawData.map(item => mapToClass<T>(this.entity, item));
          resolve(data);
        }, reject);
    });
  }
}
