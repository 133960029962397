import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { OvGenericModuleParams } from '../ov-generic.types';
import { OvAutoService } from '@ov-suite/services';

@Component({
  selector: 'ov-suite-factory',
  templateUrl: './ov-generic-add-or-edit.component.html',
  styleUrls: ['./ov-generic-add-or-edit.component.scss']
})
export class OvGenericAddOrEditComponent implements OnInit {
  data: unknown;

  parentId?: number;
  id?: number;

  constructor(
    @Inject('ENTITY_OPTIONS') public options: OvGenericModuleParams,
    public ovAutoService: OvAutoService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.has('parentId')) {
        this.parentId = Number(params.get('parentId')) ?? null;
      } else if (params.has('id')) {
        this.id = Number(params.get('id')) ?? null;
        if (this.id) {
          this.ovAutoService
            .get(this.options.entity, this.options.api, this.id)
            .then(response => {
              this.data = response;
            });
        }
      } else {
        this.route.url.subscribe(url => {
          if (url?.length && url[0].path === 'edit') {
            this.router.navigate(this.options.route);
          }
        });
      }
    });
  }

  navigateBack() {
    const options: NavigationExtras = {};
    if (this.parentId) {
      options.queryParams = { parentId: this.parentId };
    }
    this.router.navigate(this.options.route, options);
  }
}
