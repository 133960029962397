import {
  FieldMetadata,
  FieldParamsConstructor,
  getFieldMetadata,
  Constructor,
  GenericHierarchy,
  ConstructorReturn, getTypeMetadata, HasId
} from '@ov-suite/ov-metadata';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import {
  PageGet,
  SingleCreate,
  SingleDelete,
  SingleGet,
  SingleUpdate
} from '@ov-suite/ov-metadata';

export const library = {
  ne: '!=',
  eq: '=',
  equals: '=',
  like: 'ILIKE',
  is: 'IS',
  and: 'AND',
  or: 'OR'
};

export interface Query {
  [key: string]: unknown;
}

export interface DynamicService<T extends GenericHierarchy> {
  list: PageGet<T>;
  get: SingleGet<T>;
  create: SingleCreate<T>;
  update: SingleUpdate<T>;
  delete: SingleDelete<T>;
}

export function getUpdate<T extends GenericHierarchy>(
  newItem: T,
  oldItem: T
): Partial<T> & { id: string | number } {
  const update = { ...newItem };
  const metadata = getFieldMetadata(<Constructor<T>>newItem.constructor);
  metadata.fields.forEach(field => {
    if (field.unnecessary) {
      delete update[field.propertyKey];
    } else if (field.readonly) {
      delete update[field.propertyKey];
    } else if ((<FieldParamsConstructor>field).withQuantity) {
      delete update[field.propertyKey];
      if (newItem[field.propertyKey]) {
        update[field.propertyKey + 'QuantityList'] = newItem[
          field.propertyKey
        ].map(obj => {
          const { id, quantity, ...otherFields } = obj;
          const quantityField = { id, quantity };
          Object.keys(otherFields).forEach(key => {
            quantityField[key + 'Id'] = otherFields[key].id;
          });
          return quantityField;
        });
      }
    } else if (
      typeof field.type === 'function' &&
      Array.isArray(field.type())
    ) {
      delete update[field.propertyKey];
      if (newItem[field.propertyKey]) {
        update[field.propertyKey + 'IdList'] = newItem[field.propertyKey].map(
          obj => obj.id
        );
      }
    } else if (
      typeof field.type === 'function' &&
      (typeof field.type() === 'function' || typeof field.type() === 'string')
    ) {
      const value = update[field.propertyKey];
      if (value?.id) {
        if (newItem[field.propertyKey]?.id !== oldItem[field.propertyKey]?.id) {
          update[field.propertyKey + 'Id'] = value.id;
        }
      }
      delete update[field.propertyKey];
    } else if (field.type === 'permission') {
      delete update[field.propertyKey];

      update[field.propertyKey + 'IdList'] = newItem[field.propertyKey].map(
        userTypeFeature => ({
          id: userTypeFeature.id,
          featureId: userTypeFeature.feature.id,
          permission: userTypeFeature.permission
        })
      );
    } else if (field.type === 'boolean') {
      if (!!newItem[field.propertyKey] === !!oldItem[field.propertyKey]) {
        delete update[field.propertyKey];
      } else {
        update[field.propertyKey] = !!update[field.propertyKey];
      }
    } else if (newItem[field.propertyKey] === oldItem[field.propertyKey]) {
      delete update[field.propertyKey];
    }
  });

  update.id = oldItem.id;
  return update;
}

export function getCreate<T extends GenericHierarchy>(item: T): T {
  const output = { ...item };
  const metadata = getFieldMetadata<T>(<Constructor<T>>item.constructor);
  metadata.fields.forEach(field => {
    if (field.unnecessary) {
      delete output[field.propertyKey];
    } else if (field.generated) {
      delete output[field.propertyKey];
    } else if ((<FieldParamsConstructor>field).withQuantity) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[field.propertyKey + 'QuantityList'] = item[
          field.propertyKey
        ].map(obj => {
          const { quantity, ...otherFields } = obj;
          const quantityField = { quantity };
          Object.keys(otherFields).forEach(key => {
            quantityField[key + 'Id'] = otherFields[key].id;
          });
          return quantityField;
        });
      }
    } else if (
      typeof field.type === 'function' &&
      Array.isArray(field.type())
    ) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[field.propertyKey + 'IdList'] = item[field.propertyKey].map(
          obj => obj.id
        );
      }
    } else if (field.type === 'permission') {
      delete output[field.propertyKey];
      output[field.propertyKey + 'IdList'] = item[field.propertyKey].map(
        userTypeFeature => ({
          featureId: userTypeFeature.feature.id,
          permission: userTypeFeature.permission
        })
      );
    } else if (
      typeof field.type === 'function' &&
      (typeof field.type() === 'function' || typeof field.type() === 'string')
    ) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[field.propertyKey + 'Id'] = item[field.propertyKey].id;
      }
    } else if (field.type === 'boolean') {
      output[field.propertyKey] = !!output[field.propertyKey];
    }
  });

  return output;
}

interface ListWithCountQueryKeysParams<T> {
  name: string,
  input: Constructor<T>,
  metadata: FieldMetadata<T>,
  specificKeys?: string[],
  api?: string,
}

export function listWithCountQueryKeys<T extends HasId>(params: ListWithCountQueryKeysParams<T>): DocumentNode {
  const {
    name,
    input,
    metadata,
    specificKeys,
    api,
  } = params;
  return gql(
    `query ` +
      `${name}($orderDirection: String, $orderColumn: String, $filter: String, $limit: Int, $offset: Int) {\n` +
      `${name}(orderDirection: $orderDirection, orderColumn: $orderColumn, filter: $filter, limit: $limit, offset: $offset) {\n` +
      `data {\n` +
      (specificKeys
        ? getSpecificKeys({ input: [input], keys: specificKeys})
        : getAllKeys({ name, input: [input], metadata, api })) +
      `}\n` +
      `totalCount\n` +
      `\n}\n}`
  );
}

interface ListQueryKeysParams<T> {
  name: string,
  input: Constructor<T>,
  metadata: FieldMetadata<T>,
  specificKeys?: string[],
  api?: string,
}

export function listQueryKeys<T extends HasId>(params: ListQueryKeysParams<T>): DocumentNode {
  const {
    name,
    input,
    metadata,
    specificKeys,
    api,
  } = params;
  return gql(
    `query ` +
      `${name}($params: ListParamsInput!) {\n` +
      `${name}(params: $params) {\n` +
      `data {\n` +
      (specificKeys?.length
        ? getSpecificKeys({ input: [input], keys: specificKeys, metadata, api })
        : getAllKeys({ name, input: [input], metadata, api })) +
      `}\n` +
      `totalCount\n` +
      `\n}\n}`
  );
}

export function getQueryString(documentNode: DocumentNode): string {
  return documentNode.loc && documentNode.loc.source.body;
}

interface GetAncestorQueryKeysParams<T> {
  name: string,
  input: Constructor<T>,
  metadata: FieldMetadata<T>,
  api?: string,
}

export function getAncestorQueryKeys<T>(params: GetAncestorQueryKeysParams<T>): DocumentNode {
  const {
    name,
    input,
    metadata,
    api,
  } = params;
  return gql(
    `query ${name}($id: Int!) {\n ${name}(id: $id) {\nid\nname\nchildren { id name }\n}\n}`
  );
}

interface GetQueryKeysParams<T> {
  name: string,
  input: Constructor<T>,
  metadata: FieldMetadata<T>,
  api?: string,
}

export function getQueryKeys<T extends HasId>(params: GetQueryKeysParams<T>): DocumentNode {
  const {
    name,
    input,
    metadata,
    api,
  } = params;
  return gql(
    `query ${name}($id: Int!) {\n ${name}(id: $id) {\n${getAllKeys({ name, input: [input], metadata, api })}\n}\n}`
  );
}

interface GetByIdsQueryKeysParams<T> {
  name: string,
  input: Constructor<T>,
  metadata: FieldMetadata<T>,
  api?: string,
}

export function getByIdsQueryKeys<T extends HasId>(params: GetByIdsQueryKeysParams<T>): DocumentNode {
  const {
    name,
    input,
    metadata,
    api,
  } = params;
  return gql(
    `query ${name}($ids: [Int!]!) {\n ${name}(ids: $ids) {\n${getAllKeys({ name, input: [input], metadata, api })}\n}\n}`
  );
}

interface GetQueryKeysStringParams<T> {
  name: string,
  input: Constructor<T>,
  metadata: FieldMetadata<T>,
  api?: string,
}

export function getQueryKeysString<T extends HasId>(params: GetQueryKeysStringParams<T>): DocumentNode {
  const {
    name,
    input,
    metadata,
    api,
  } = params;
  return gql(
    `query ${name}($id: String!) {\n ${name}(id: $id) {\n${getAllKeys({ name, input: [input], metadata, api })}\n}\n}`
  );
}

interface CreateMutationKeysParams<T> {
  name: string,
  input: Constructor<T>,
  metadata: FieldMetadata<T>,
  api?: string,
}

export function createMutationKeys<T extends HasId>(params: CreateMutationKeysParams<T>): DocumentNode {
  const {
    name,
    input,
    metadata,
    api,
  } = params;
  return gql(
    `mutation ` +
      `${name}($data: ${metadata.name}CreateInput!) {\n` +
      `${name}(data: $data) {\n` +
      getAllKeys({ name, input: [input], metadata, api }) +
      `\n}\n}`
  );
}

interface UpdateMutationKeysParams<T> {
  name: string,
  input: Constructor<T>,
  metadata: FieldMetadata<T>,
  api?: string,
}

export function updateMutationKeys<T extends HasId>(params: UpdateMutationKeysParams<T>): DocumentNode {
  const {
    name,
    input,
    metadata,
    api,
  } = params;
  return gql(
    `mutation ` +
      `${name}($data: ${metadata.name}UpdateInput!) {\n` +
      `${name}(data: $data) {\n` +
      getAllKeys({ name, input: [input], metadata, api }) +
      `\n}\n}`
  );
}

interface DeleteMutationKeysParams<T> {
  name: string
}

export function deleteMutationKeys<T>(params: DeleteMutationKeysParams<T>): DocumentNode {
  const { name } = params;
  return gql(`mutation ${name}($id: Int!) {\n ${name}(id: $id)\n}`);
}

interface GetAllKeysParams<T> {
  name: string,
  input: (Constructor<T> | Constructor<T>[])[];
  metadata: FieldMetadata<T>;
  noRepeat?: boolean;
  api?: string;
}

export function getAllKeys<T extends HasId>(params: GetAllKeysParams<T>): string {
  const {
    name,
    input,
    metadata,
    noRepeat = false,
    api
  } = params;
  const lines: string[] = [];

  const currentApi = (!metadata.api || metadata.api === 'shared') && api !== 'unknown' ? api : metadata.api;

  const fields = metadata.fields.filter(item => !item.unnecessary && (!item.apis.length || item.apis.includes(currentApi)));

  fields.forEach(field => {
    if (typeof field.type === 'string') {
      switch (field.type) {
        case 'boolean':
        case 'date':
        case 'date-time':
        case 'number':
        case 'string':
        case 'json':
        case 'image':
          lines.push(field.propertyKey);
          break;
        case 'map':
          lines.push(field.propertyKey);
          lines.push(' {\n address\nlongitude\nlatitude\n}\n');
          break;
        case 'permission':
          lines.push(field.propertyKey);
          lines.push(' {\nid\n feature {\n id\n}\npermission\n}\n');
        // Ignore case "Title"
      }
    } else {
      // Subtype
      if (!noRepeat) {
        if (field.idOnly) {
          lines.push(field.propertyKey);
        } else {
          const { metadata: newMetadata } = typeof field.type === 'function' ? getTypeMetadata(field.type) : null;
          lines.push(field.propertyKey + ' { ');
          if ((<FieldParamsConstructor>field)?.keys?.length) {
            lines.push(
              (<FieldParamsConstructor>field).keys
                .filter(key => !key.includes('.'))
                .join('\n')
            );
            const deepKeys = (<FieldParamsConstructor>field)?.keys.filter(key =>
              key.includes('.')
            );
            if (deepKeys.length) {
              deepKeys.forEach(key => {
                const arrayKeys = key.split('.');
                let gqlLine = arrayKeys.join(' {\n');
                gqlLine += '\n}'.repeat(arrayKeys.length - 1);
                lines.push(gqlLine);
              });
            }
          } else {
            const { entity: fieldType } = getTypeMetadata(field.type);
            lines.push(
              getAllKeys(
                {
                  name,
                  input: [...input, [fieldType]],
                  metadata: newMetadata,
                  noRepeat: input.some(item => item.toString() === fieldType.toString())
                })
            );
          }
          lines.push('}');
        }
      }
    }
  });

  return lines.join('\n');
}

interface GetSpecificKeysParams<T> {
  name?: string,
  input: (Constructor<T> | Constructor<T>[])[];
  keys: string[]
  metadata?: FieldMetadata<T>;
  noRepeat?: boolean;
  api?: string;
}

export function getSpecificKeys<T extends HasId>(params: GetSpecificKeysParams<T>): string {
  const {
    input,
    keys,
    metadata,
    name,
    noRepeat = false,
    api,
  } = params;
  const lines: string[] = [];

  const localMetadata =
    metadata ??
    getFieldMetadata(
      Array.isArray(input) ? (input[0] as Constructor<T>) : input
    );
  const localName = name ?? localMetadata.plural;

  const currentApi = (!metadata.api || metadata.api === 'shared') && api !== 'unknown' ? api : metadata.api;

  let fields = localMetadata.fields.filter(item => !item.unnecessary && (!item.apis.length || item.apis.includes(currentApi)));

  const currentKeys = keys.map(key => key.split('.')[0]);

  fields = fields.filter(field => currentKeys.includes(field.propertyKey));

  fields.forEach(field => {
    if (typeof field.type === 'string') {
      switch (field.type) {
        case 'boolean':
        case 'date':
        case 'date-time':
        case 'number':
        case 'string':
        case 'json':
        case 'image':
          lines.push(field.propertyKey);
          break;
        case 'map':
          lines.push(field.propertyKey);
          lines.push(' {\n address\nlongitude\nlatitude\n}\n');
          break;
        case 'permission':
          lines.push(field.propertyKey);
          lines.push(' {\nid\n feature {\n id\n}\npermission\n}\n');
        // Ignore case "Title"
      }
    } else {
      // Subtype
      if (!noRepeat) {
        if (field.idOnly) {
          lines.push(field.propertyKey);
        } else {
          const { metadata: newMetadata } = getTypeMetadata(field.type);
          lines.push(field.propertyKey + ' { ');
          if ((<FieldParamsConstructor>field)?.keys?.length) {
            lines.push(
              (<FieldParamsConstructor>field).keys
                .filter(key => !key.includes('.'))
                .join('\n')
            );
            const deepKeys = (<FieldParamsConstructor>field)?.keys.filter(key =>
              key.includes('.')
            );
            if (deepKeys.length) {
              deepKeys.forEach(key => {
                const arrayKeys = key.split('.');
                let gqlLine = arrayKeys.join(' {\n');
                gqlLine += '\n}'.repeat(arrayKeys.length - 1);
                lines.push(gqlLine);
              });
            }
          } else {
            const { entity: fieldType } = typeof field.type === 'function' ? getTypeMetadata(field.type) : null;
            const newKeys = keys
              .filter(key => key.startsWith(field.propertyKey))
              .map(key =>
                key
                  .split('.')
                  .slice(1)
                  .join('.')
              )
              .filter(key => !!key);

            if (!newKeys.length) {
              lines.push(
                getAllKeys({
                  name: localName,
                  input: [...input, fieldType],
                  metadata: newMetadata,
                  noRepeat: input.some(item => item.toString() === fieldType.toString())
                })
              );
            } else {
              lines.push(
                getSpecificKeys({
                  input: [...input, fieldType],
                  keys: newKeys,
                  metadata: newMetadata,
                  name: localName,
                  noRepeat: input.some(item => item.toString() === fieldType.toString())
                })
              );
            }
          }
          lines.push('}');
        }
      }
    }
  });

  return lines.join('\n');
}

export function buildQuery(
  alias: string,
  variables: { [key: string]: unknown }
): string {
  return buildLine(alias, variables);
}

export function buildLine(
  alias: string,
  variables: Query,
  prev?: string
): string {
  const output: string[] = [];
  if (!prev || library[prev] === 'AND' || library[prev] === 'OR') {
    output.push('(');
  }
  let firstKey: string;
  const keys = Object.keys(variables);
  keys.forEach((key, index) => {
    if (!firstKey) {
      firstKey = key;
    }
    const sym = library[key];
    const value = variables[key];
    const type = typeof value;
    if (sym) {
      if (sym === '=' && value === null) {
        output.push('IS');
      } else if (sym === '!=' && value === null) {
        output.push('IS NOT');
      } else {
        if (sym === 'AND' || sym === 'OR') {
          output.unshift('(');
          output.push(')');
        }
        output.push(sym);
      }
    } else {
      output.push(`"${alias}"."${key}"`);
    }
    if (type === 'string') {
      output.push(`"${value}"`);
    }
    if (type === 'number') {
      output.push(value?.toString());
    }
    if (type === 'boolean') {
      output.push(value?.toString());
    }
    if (type === 'object') {
      if (!!value) {
        const inner = buildLine(alias, <Query>value, key);
        output.push(inner);
      } else {
        output.push('NULL');
      }
    }
  });
  if (!prev || library[prev] === 'AND' || library[prev] === 'OR') {
    output.push(')');
  }
  return output.join(' ');
}
