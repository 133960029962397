import { Injectable } from '@angular/core';
import { CeramicVehicleType } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class VehicleTypeService extends AutoService<CeramicVehicleType> {
  constructor(private apollo: Apollo) {
    super(
      apollo,
      CeramicVehicleType,
      'VehicleType',
      'VehicleTypes',
      'adminlink'
    );
  }
}
