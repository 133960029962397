import {
  OVField,
  OVSearchable,
  OVEntity,
  OVForm,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('OriginType', 'ceramic-portal')
@OVForm([['classCode', 'className']])
// @OVSidebar([['status'], ['id']])
@OVTable<OriginType>([
  {
    key: 'id',
    title: 'Origin ID',
    type: 'string'
  },
  {
    key: 'classCode',
    title: 'Class Code',
    type: 'string'
  },
  {
    key: 'className',
    title: 'Class Name',
    type: 'string'
  }
])
export class OriginType {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Class Code',
    placeholder: 'Required',
    required: true
  })
  classCode: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Class Name',
    placeholder: 'Required',
    required: true
  })
  className: string;
}
