import { Component, OnInit } from '@angular/core';
import { OVTag } from '@ov-suite/ov-metadata';
import { RateChange } from '@ov-suite/adminlink-models';
import { NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RateChangeService } from '@ov-suite/services-angular';
import { getCreate, getUpdate } from '@ov-suite/graphql-helpers';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import {AuditTrailUseService} from "@ov-suite/services-angular";

@OVTag('rate-approval-popup', RateChange)
@Component({
  selector: 'ov-suite-rate-approval-modal',
  templateUrl: './rate-approval.component.html',
  styleUrls: ['./rate-approval.component.scss'],
  providers: [NgbAlertConfig]
})
export class RateApprovalComponent implements OnInit {
  name = '';
  rateChanges: RateChange[] = [];
  validFrom: Date = new Date();
  validTo: Date = new Date();
  loading: boolean;
  onSuccess: boolean;
  errors = [];

  constructor(
    protected alertConfig: NgbAlertConfig,
    private rateChangeService: RateChangeService,
    protected modalService: NgbModal,
    private router: Router,
    private auditTrailUseService: AuditTrailUseService
  ) {
    alertConfig.type = 'success';
    alertConfig.dismissible = false;
  }

  ngOnInit(): void {
    if (Object.keys(this.rateChanges[0]).includes('isSelected')) {
      this.rateChanges = cloneDeep(this.rateChanges);
      this.rateChanges.forEach(rate => delete rate['isSelected']);
    }
  }

  onSave() {
    this.loading = true;
    this.onSuccess = false;
    const change = this.rateChanges[0];
    const old = cloneDeep(change);
    change.validFrom = this.validFrom;
    change.validTo = this.validTo;
    change.changeStatus = 'APPROVED';
    for (const rate of this.rateChanges) {
      const newRate = cloneDeep(rate);
      newRate.validFrom = this.validFrom;
      newRate.validTo = this.validTo;
      newRate.changeStatus = 'APPROVED';
      this.auditTrailUseService.generateAudit(newRate, rate, "Rate Change Approved")
        .then();
    }
    this.rateChangeService
      .updateMultipleRateChanges(getUpdate(change, old), this.rateChanges)
      .then(res => {
        if (!res.data.length) {
          this.errors.push('Not updated something went wrong');
        }
        this.loading = false;
        this.onSuccess = true;
      })
      .catch(() => {
        this.loading = false;
        this.onSuccess = true;
      });
  }

  close = () => {
    this.rateChanges = [];
    this.router.onSameUrlNavigation = 'reload';
    this.modalService.dismissAll();
  };
}
