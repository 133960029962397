import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { EquipmentType, EquipmentTypeStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class EquipmentTypeService extends AutoService<EquipmentType> {
  constructor(private apollo: Apollo) {
    super(apollo, EquipmentType, 'EquipmentType', 'EquipmentTypes', 'adminlink');
  }
}

@Injectable()
export class EquipmentTypeStatusService extends AutoService<
  EquipmentTypeStatus
> {
  constructor(private apollo: Apollo) {
    super(
      apollo,
      EquipmentTypeStatus,
      'EquipmentTypeStatus',
      'EquipmentTypeStatuses',
      'adminlink'
    );
  }
}
