import { Component } from '@angular/core';
import { CeramicVehicleType } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleTypeService } from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-vehicle-type-add',
  templateUrl: './vehicle-type-add-or-edit.component.html',
  styleUrls: ['./vehicle-type-add-or-edit.component.scss']
})
export class VehicleTypeAddOrEditComponent extends AddOrEditHelper<
  CeramicVehicleType
> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public vehicleTypeService: VehicleTypeService
  ) {
    super(route, router, CeramicVehicleType, vehicleTypeService, [
      'vehicle-type'
    ]);
    this.dataSources = {
      ...this.dataSources
    };
  }
}
