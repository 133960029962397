import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Unit } from '@ov-suite/adminlink-models';

@Injectable()
export class UnitService extends AutoService<Unit> {
  constructor(private apollo: Apollo) {
    super(apollo.use('warehouselink'), Unit, 'Unit', 'Units', 'warehouselink');
  }
}
