import { Component, OnInit } from '@angular/core';
import { OVTag } from '@ov-suite/ov-metadata';
import {
  DropPenaltyBand,
  PickupPenaltyBand,
  Rate,
  RateChange
} from '@ov-suite/adminlink-models';
import { NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DropPenaltyBandService,
  PickupPenaltyBandService,
  RateChangeService
} from '@ov-suite/services-angular';
import {AuditTrailUseService} from "@ov-suite/services-angular";
import { cloneDeep } from 'lodash';

@OVTag('rate-change-popup', Rate)
@Component({
  selector: 'ov-suite-rate-change-modal',
  templateUrl: './rate-change.component.html',
  styleUrls: ['./rate-change.component.scss'],
  providers: [NgbAlertConfig]
})
export class RateChangeComponent implements OnInit {
  ratesToChange: Rate[] = [];
  rateValues: Rate;
  pickupPenalties: PickupPenaltyBand[] = [];
  dropPenalties: DropPenaltyBand[] = [];
  name = '';
  loading: boolean;
  onSuccess: boolean;
  errors: [];

  constructor(
    protected alertConfig: NgbAlertConfig,
    private rateChangeService: RateChangeService,
    private pickupPenaltyBandService: PickupPenaltyBandService,
    private dropPenaltyBandService: DropPenaltyBandService,
    protected modalService: NgbModal,
    private auditTrailUseService: AuditTrailUseService
  ) {
    alertConfig.type = 'success';
    alertConfig.dismissible = false;
  }

  ngOnInit(): void {
    this.rateValues = Object.assign({}, this.ratesToChange[0]);

    this.dropPenaltyBandService.list({}).then(res => {
      this.dropPenalties = res.data;
    });
    this.pickupPenaltyBandService.list({}).then(res => {
      this.pickupPenalties = res.data;
    });
  }

  onSave() {
    for (const rate of this.ratesToChange) {
      const newRate = cloneDeep(rate);
      newRate.rate = this.rateValues.rate;
      newRate.rateRange = this.rateValues.rateRange;
      newRate.pickupPenaltyBand = this.rateValues.pickupPenaltyBand;
      newRate.dropPenaltyBand = this.rateValues.dropPenaltyBand;
      this.auditTrailUseService.generateAudit(newRate, rate, "Rate Change Request")
        .then();
    }
    this.loading = true;
    this.onSuccess = false;
    this.rateChangeService
      .createMultipleRateChanges(this.rateValues, this.ratesToChange)
      .then(res => {
        this.loading = false;
        this.onSuccess = true;
      })
      .catch(() => {
        this.loading = false;
        this.onSuccess = true;
      });
  }

  close = () => {
    this.ratesToChange = [];
    this.rateValues = null;
    this.pickupPenalties = [];
    this.dropPenalties = [];
    this.modalService.dismissAll();
  };

  removeItem(rateToChange: Rate) {
    this.ratesToChange = this.ratesToChange.filter(
      rate => rate.id !== rateToChange.id
    );
  }
}
