import { OVField, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity('Client', 'ceramic-portal')
export class Client {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Client Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Client Code',
    placeholder: 'Required',
    required: true
  })
  clientCode: string;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Domain ID',
    tooltip: '',
    placeholder: 'Required',
    required: false
  })
  domainId: number;
}
