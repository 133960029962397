import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HierarchyTab } from './hierarchy-tabs.model';

@Component({
  selector: 'ov-suite-hierarchy-tabs',
  templateUrl: './hierarchy-tabs.component.html',
  styleUrls: ['./hierarchy-tabs.component.scss']
})
export class HierarchyTabsComponent implements OnInit {
  active: string;
  @Input() tabs: HierarchyTab[] = [];

  constructor(private readonly router: Router) {}

  ngOnInit() {
    // set initial value
    this.active = this.router.url;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.active = event.url;
      }
    });
  }
}
