import { Routes } from '@angular/router';
import { VatAddOrEditComponent } from './add-or-edit/vat-add-or-edit.component';
import { VatListComponent } from './list/vat-list.component';

export const VatRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: VatListComponent
      },
      {
        path: 'add',
        component: VatAddOrEditComponent
      },
      {
        path: 'edit',
        component: VatAddOrEditComponent
      }
    ]
  }
];
