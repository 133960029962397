import { Injectable } from '@angular/core';
import { EntryReason } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class EntryReasonYardService extends AutoService<EntryReason> {
  constructor(private apollo: Apollo) {
    super(apollo.use('yardlink'), EntryReason, 'EntryReason', 'EntryReasons', 'yardlink');
  }
}
