import { Routes } from '@angular/router';
import { WeightBandAddOrEditComponent } from './add-or-edit/weight-band-add-or-edit.component';
import { WeightBandListComponent } from './list/weight-band-list.component';

export const WeightBandRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: WeightBandListComponent
      },
      {
        path: 'add',
        component: WeightBandAddOrEditComponent
      },
      {
        path: 'edit',
        component: WeightBandAddOrEditComponent
      }
    ]
  }
];
