<div class="modal-body pt-0 pr-3 pl-3 pb-2">
  <div class="row">
    <div class="col-md-12">
      <h5 id="modal-basic-title" class="mb-0 mt-2 pt-1">
        <button
          type="button"
          class="btn btn-link"
          aria-label="Close"
          (click)="close()"
        >
          <span aria-hidden="true" class="nc-icon nc-minimal-left"></span>
        </button>
        {{ name }}
      </h5>
    </div>
  </div>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <ov-suite-input
          id="rate"
          type="text"
          [title]="'Reason For Rejection'"
          [tooltip]="'Reason'"
          [(ngModel)]="reason"
        >
        </ov-suite-input>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-7">
          <div class="ml-3 mt-4 pt-2">
            Rejecting
            {{
              rateChanges.length === 1
                ? rateChanges[0].name
                : rateChanges.length + ' items'
            }}.
          </div>
        </div>
        <div class="col-md-5">
          <button
            *ngIf="!onSuccess"
            [disabled]="loading || rateChanges.length === 0"
            (click)="onSave()"
            class="btn btn-secondary float-right bottom"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span *ngIf="loading" class="sr-only">Loading...</span>
            <span *ngIf="!loading" class="">Reject</span>
          </button>
        </div>
      </div>
      <div *ngIf="!loading && onSuccess" class="row">
        <div class="text-center col-md-12">
          <h6><i class="text-success fa fa-check"></i>&nbsp; Rejected</h6>
        </div>
      </div>
    </div>
  </div>
</div>
