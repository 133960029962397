import { OVField, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity('Terms', 'ceramic-portal')
export class Terms {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: false
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Description',
    placeholder: 'Required',
    required: false
  })
  description: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Week Start',
    placeholder: 'Required',
    required: false
  })
  weekStart: string;

  @OVSearchable()
  @OVField({
    type: 'boolean',
    title: 'Soft Lock On',
    tooltip: '',
    placeholder: 'Required',
    required: false
  })
  softLockOn: boolean;

  @OVSearchable()
  @OVField({
    type: 'boolean',
    title: 'Hard Lock On',
    tooltip: '',
    placeholder: 'Required',
    required: false
  })
  hardLockOn: boolean;
}
