import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  RateService,
  DomainService,
  RateHistoryService,
  RateChangeService
} from '@ov-suite/services-angular';
import { RateChangeListComponent } from './list/rate-change-list.component';
import { RateChangeRoutes } from './rate-change.routing';
import { RateChangeAddOrEditComponent } from './add-or-edit/rate-change-add-or-edit.component';
import { TableTopNavMenuModule } from '../../components/table-top-nav-menu/table-top-nav-menu.module';
import { RateApprovalComponent } from './modals/rate-approval/rate-approval.component';
import { RateDeclineComponent } from './modals/rate-decline/rate-decline.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(RateChangeRoutes),
    FormsModule,
    UiModule,
    TableTopNavMenuModule
  ],
  declarations: [
    RateChangeListComponent,
    RateChangeAddOrEditComponent,
    RateApprovalComponent,
    RateDeclineComponent
  ],
  providers: [
    RateService,
    RateChangeService,
    RateHistoryService,
    DomainService
  ]
})
export class RateChangeModule {}
