import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { InventoryThreshold } from '@ov-suite/adminlink-models';

@Injectable()
export class InventoryThresholdService extends AutoService<InventoryThreshold> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      InventoryThreshold,
      'InventoryThreshold',
      'InventoryThresholds', 'warehouselink'
    );
  }
}
