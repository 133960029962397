<div class="modal-body pt-0 pr-3 pl-3">
  <div class="row">
    <div class="col-md-12">
      <button type="button" class="btn btn-link float-left mr-3" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="nc-icon nc-minimal-left"></span>
      </button>
      <h5 id="modal-basic-title" class="mb-0 mt-2 pt-1">
        {{name}}
      </h5>
    </div>
  </div>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div>Lanes</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="badge badge-light mr-3 text-primary" *ngFor="let rateToChange of ratesToChange">
          {{rateToChange.route.name}}
          <span class="fa fa-close ml-2" (click)="removeItem(rateToChange)"></span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ov-suite-input
          id="rate"
          type="number"
          [title]="'Rate'"
          [tooltip]="'Rate'"
          required="true"
          [(ngModel)]="rateValues.rate"
        >
        </ov-suite-input>
      </div>
      <div class="col-md-6">
        <ov-suite-input
          id="rateRange"
          type="text"
          [title]="'Weight Band'"
          required="true"
          [tooltip]="'Weight Band'"
          [(ngModel)]="rateValues.rateRange"
        >
        </ov-suite-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ov-suite-input
          id="pickupPenalty"
          type="dropdown"
          required="true"
          [title]="'Pickup Penalty Range'"
          [tooltip]="'Pickup Penalty Range'"
          [data]="pickupPenalties"
          [label]="['ppBand']"
          [(ngModel)]="rateValues.pickupPenaltyBand"
        >
        </ov-suite-input>
      </div>
      <div class="col-md-6">
        <ov-suite-input
          id="pickupRange"
          type="text"
          required="true"
          [title]="'Pickup Penalty'"
          [tooltip]="'Pickup Penalty'"
          [placeholder]="rateValues.pickupPenaltyBand.pickupPenalty"
          [disabled]="true"
        >
        </ov-suite-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ov-suite-input
          id="dropPenalty"
          type="dropdown"
          required="true"
          [title]="'Drop Penalty Range'"
          [tooltip]="'Drop Penalty Range'"
          [data]="dropPenalties"
          [label]="['dpBand']"
          [(ngModel)]="rateValues.dropPenaltyBand"
        >
        </ov-suite-input>
      </div>
      <div class="col-md-6">
        <ov-suite-input
          id="dropPenaltyRange"
          type="text"
          required="true"
          [title]="'Drop Penalty'"
          [tooltip]="'Drop Penalty'"
          [placeholder]="rateValues.dropPenaltyBand.dropPenalty"
          [disabled]="true"
        >
        </ov-suite-input>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-12">
      <button
        *ngIf="!onSuccess"
        [disabled]="loading ||
          ratesToChange.length === 0 ||
          !rateValues.dropPenaltyBand?.id ||
          !rateValues.pickupPenaltyBand?.id"
        (click)="onSave()"
        class="btn btn-secondary float-right">
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span *ngIf="loading" class="sr-only">Loading...</span>
        <span *ngIf="!loading" class="">Submit Request</span>
      </button>
      <div *ngIf="!loading && onSuccess" class="row">
        <div class="text-center col-md-12">
          <h6>
            <i class="text-success fa fa-check"></i>&nbsp;
            Submitted
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
