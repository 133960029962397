import {
  OVField,
  OVSearchable,
  OVEntity,
  OVForm,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('Vat', 'ceramic-portal')
@OVForm([
  ['country', 'vat'],
  ['validFrom', 'validTo']
])
// @OVSidebar([['status'], ['id']])
@OVTable<Vat>([
  {
    key: 'id',
    title: 'Vat ID',
    type: 'number'
  },
  {
    key: 'country',
    title: 'Cost Type',
    type: 'string'
  },
  {
    key: 'vat',
    title: 'Vat',
    type: 'number'
  },
  {
    key: 'validFrom',
    title: 'Valid From',
    type: 'date'
  },
  {
    key: 'validTo',
    title: 'Valid To',
    type: 'date'
  }
])
export class Vat {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Country',
    placeholder: 'Required',
    required: true
  })
  country: string;

  @OVField({
    type: 'number',
    title: 'Vat',
    placeholder: '-',
    required: true
  })
  vat: number;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Valid From',
    placeholder: '-',
    required: true
  })
  validFrom: Date;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Valid To',
    placeholder: '-',
    required: true
  })
  validTo: Date;
}
