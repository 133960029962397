import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  PlantService, ShipmentService
} from '@ov-suite/services-angular';
import { PlantRoutes } from './plant.routing';
import { PlantListComponent } from './list/plant-list.component';
import { PlantAddOrEditComponent } from './add-or-edit/plant-add-or-edit.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(PlantRoutes),
    FormsModule,
    UiModule
  ],
  declarations: [
    PlantListComponent,
    PlantAddOrEditComponent
  ],
  entryComponents: [],
  exports: [
    PlantAddOrEditComponent
  ],
  providers: [
    PlantService,
    ShipmentService
  ]
})
export class PlantModule {}
