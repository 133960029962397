import { Injectable } from '@angular/core';
import { WeightBand } from '@ov-suite/adminlink-models';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';

@Injectable()
export class WeightBandService extends AutoService<WeightBand> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), WeightBand, 'WeightBand', 'WeightBands', 'ceramic-portal');
  }
}
