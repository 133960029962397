<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="primary">
    <ov-suite-sidebar
      [projectName]="'Italtile Logistics'"
      [routes]="routes"
    ></ov-suite-sidebar>
  </div>
  <div class="main-panel">
    <ov-suite-navbar [routes]="routes"></ov-suite-navbar>
    <router-outlet></router-outlet>
  </div>
</div>
