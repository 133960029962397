import { Component } from '@angular/core';
import { PickupPenaltyBand } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PickupPenaltyBandService
} from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-pickup-penalty-band-add',
  templateUrl: './pickup-penalty-band-add-or-edit.component.html',
  styleUrls: ['./pickup-penalty-band-add-or-edit.component.scss']
})
export class PickupPenaltyBandAddOrEditComponent extends AddOrEditHelper<PickupPenaltyBand> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public pickupPenaltyBandService: PickupPenaltyBandService,
  ) {
    super(route, router, PickupPenaltyBand, pickupPenaltyBandService, ['pickup-penalty-band']);
    this.dataSources = {
      ...this.dataSources,
    };
  }
}
