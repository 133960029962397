import {
  OVField,
  OVForm,
  OVSidebar,
  OVTable,
  OVSearchable,
  OVEntity
} from '@ov-suite/ov-metadata';

@OVEntity('DomainStatus', 'shared')
export class DomainStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Domain', 'shared')
@OVForm([['Domain Details'], ['domainId', 'name'], ['description']])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<Domain>([
  {
    key: 'domainId',
    title: 'Domain ID',
    type: 'string'
  },
  {
    key: 'name',
    title: 'Domain Name',
    type: 'string'
  },
  {
    type: 'other',
    title: 'Number of Sub Domains',
    action: item => item?.childrenCount?.toString() ?? '0',
    routerLink: () => [],
    keys: ['childrenCount'],
    queryParams: (item: { id: number }) => ({ parentId: item.id }),
    disableFiltering: true
  },
  {
    type: 'status',
    title: 'Status',
    key: 'status',
    id: 'status',
    orderKey: 'status.name'
  }
])
export class Domain {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Domain Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Domain ID',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true
  })
  domainId: string;

  @OVField({ type: () => Domain, title: 'Parent', bulkDependency: 'domainId' })
  parent?: Domain;

  @OVField({ type: () => Domain })
  children?: Domain[];

  @OVField({ type: 'number', generated: true, readonly: true })
  childrenCount?: number;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: () => DomainStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true,
    apis: ['adminlink']
  })
  status?: DomainStatus;

  @OVField({ type: 'string', generated: true, readonly: true })
  path: string;
}
