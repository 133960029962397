import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InvoiceManagementRoutes } from './invoice-management.routing';
import { InvoiceListComponent } from './list/invoice-list.component';
import { UiModule } from '@ov-suite/ui';
import { TableTopNavMenuModule } from '../../components/table-top-nav-menu/table-top-nav-menu.module';
import {
  CeramicFactoryService,
  CeramicInvoiceItemService,
  CeramicInvoiceService,
  CostTypeService,
  RateHistoryService,
  ShipmentService,
  VatService
} from '@ov-suite/services-angular';
import { InvoiceDetailComponent } from './detail/invoice-detail.component';
import { AddInvoiceItemComponent } from './modal/add-invoice-item/add-invoice-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(InvoiceManagementRoutes),
    FormsModule,
    UiModule,
    TableTopNavMenuModule
  ],
  declarations: [
    InvoiceListComponent,
    InvoiceDetailComponent,
    AddInvoiceItemComponent
  ],
  providers: [
    CeramicInvoiceService,
    CeramicInvoiceItemService,
    RateHistoryService,
    CostTypeService,
    ShipmentService,
    CeramicFactoryService,
    VatService
  ],
  entryComponents: [AddInvoiceItemComponent]
})
export class InvoiceManagementModule {}
