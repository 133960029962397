import { Component, OnInit } from '@angular/core';
import { Vat } from '@ov-suite/adminlink-models';
import { VatService } from '@ov-suite/services-angular';
import { HierarchyTab } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-vat-list',
  templateUrl: './vat-list.component.html',
  styleUrls: ['./vat-list.component.scss']
})
export class VatListComponent {
  parentId?: number;

  // Class - Required
  formClass = Vat;

  constructor(public vatService: VatService) {}
}
