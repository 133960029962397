import {Injectable} from '@angular/core';
import {AutoService} from "@ov-suite/services";
import {AuditTrail, RateChange} from "@ov-suite/adminlink-models";
import {Apollo} from "apollo-angular";

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService extends AutoService<AuditTrail> {
  constructor(private apollo: Apollo) {
    super(apollo.use('ceramic-portal'), AuditTrail,
      'AuditTrail', 'AuditTrails', 'ceramic-portal');
  }
}
