import { Component, OnInit, Inject } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { ApiOptions } from '@ov-suite/ui';
import { environment } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-user-profile-widget',
  templateUrl: './user-profile-widget.component.html',
  styleUrls: ['./user-profile-widget.component.scss']
})
export class UserProfileWidgetComponent implements OnInit {
  userAvatar: string;
  userFullName = 'Loading';

  constructor(@Inject('OPTIONS') private options: ApiOptions) {}

  ngOnInit() {
    Auth.currentUserInfo().then(user => {
      if (user) {
        this.userAvatar = user.attributes.picture;
        this.userFullName = (user.attributes['name']|| user.attributes['family_name']) ? `${user.attributes['name']} ${user.attributes['family_name']}`: 'Not Available';
      } else {
        this.userFullName = 'Not Available';
      }
    });
  }

  navigate() {
    window.location.assign(environment.webUrl.idm + '/user');
  }
}
