import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { InventoryLocationPallet } from '@ov-suite/adminlink-models';

@Injectable()
export class InventoryLocationPalletService extends AutoService<InventoryLocationPallet> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      InventoryLocationPallet,
      'InventoryLocationPallet',
      'InventoryLocationPallets', 'warehouselink'
    );
  }
}
