import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import {
  Feature,
  UserType,
  UserTypeStatus,
  App,
  UserTypeFeature
} from '@ov-suite/adminlink-models';

@Injectable()
export class UserTypeService extends AutoService<UserType> {
  constructor(private apollo: Apollo) {
    super(apollo.use('idmlink'), UserType, 'UserType', 'UserTypes', 'idmlink');
  }
}

@Injectable()
export class UserTypeStatusService extends AutoService<UserTypeStatus> {
  constructor(private apollo: Apollo) {
    super(
      apollo.use('idmlink'),
      UserTypeStatus,
      'UserTypeStatus',
      'UserTypeStatuses', 'idmlink'
    );
  }
}

@Injectable()
export class FeatureService extends AutoService<Feature> {
  constructor(private apollo: Apollo) {
    super(apollo.use('idmlink'), Feature, 'Feature', 'Features', 'idmlink');
  }
}

@Injectable()
export class AppService extends AutoService<App> {
  constructor(private apollo: Apollo) {
    super(apollo.use('idmlink'), App, 'App', 'Apps', 'idmlink');
  }
}

@Injectable()
export class UserTypeFeatureService extends AutoService<UserTypeFeature> {
  constructor(private apollo: Apollo) {
    super(apollo.use('idmlink'), UserTypeFeature, 'UserTypeFeature', 'UserTypeFeatures', 'idmlink');
  }
}
