import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Customer, CustomerStatus } from '@ov-suite/adminlink-models';

@Injectable()
export class CustomerService extends AutoService<Customer> {
  constructor(private apollo: Apollo) {
    super(apollo, Customer, 'Customer', 'Customers', 'adminlink');
  }
}

@Injectable()
export class CustomerStatusService extends AutoService<CustomerStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, CustomerStatus, 'CustomerStatus', 'CustomerStatuses', 'adminlink');
  }
}
