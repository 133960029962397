import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import 'rxjs/operator/filter';
import { filter } from 'rxjs/operators';
import { RouteInfo } from '../..';
import { environment } from '@ov-suite/helpers-shared';
import Auth from '@aws-amplify/auth';
import { Domain } from '@ov-suite/adminlink-models';
import { SessionStorage } from '@ov-suite/helpers-angular';
import { OvAutoService } from '@ov-suite/services';

interface INavbarDomains {
  id: number;
  title: string;
  url: string;
  hasPermission: boolean;
}

@Component({
  selector: 'ov-suite-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  misc = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
    sidebar_mini_active: false
  };
  toggleApps = false;
  toggleDomains = false;

  @Input() routes: Promise<RouteInfo[]>;

  listTitles: RouteInfo[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  sidebarVisible: boolean;
  // private _router: Subscription;
  public open = false;
  navbarApps: INavbarDomains[] = [];
  domains: Domain[] = [];
  selectedDomainId: number;

  @ViewChild('ov-suite-navbar', { static: false }) button;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
    private ovAutoService: OvAutoService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.generateNavbarDomains();
    this.routes.then(data => {
      this.listTitles = data;
    });

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      this.misc.sidebar_mini_active = true;
    }
    // this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
    //   const $layer = document.getElementsByClassName('close-layer')[0];
    //   if ($layer) {
    //     $layer.remove();
    //   }
    // });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const $layer = document.getElementsByClassName('close-layer')[0];
        if ($layer) {
          $layer.remove();
        }
      });
  }

  generateNavbarDomains() {
    // todo Fix this: Domains Parse Issue
    Auth.currentSession().then(session => {
      this.setDefaultApps();
      const currentUserTokenInfo = session.getIdToken().decodePayload();
      const apps = 'lambdaAppIds';
      const domains = 'lambdaDomainIds';


      if (
        !!currentUserTokenInfo[apps] &&
        currentUserTokenInfo[apps].length > 0
      ) {
        currentUserTokenInfo[apps] = JSON.parse(currentUserTokenInfo[apps]);
        currentUserTokenInfo[apps].forEach(appId => {
          this.navbarApps.forEach(app => {
            if (app.id === appId) {
              app.hasPermission = true;
            }
          });
        });
      } else {
        this.navbarApps.forEach(app => {
            app.hasPermission = true;
        });
      }

      // `"Domain"."id" IN (${domainsToQuery.join(',')})`, 10000,0, 'id', 'ASC'

      if (
        !!currentUserTokenInfo[domains] &&
        currentUserTokenInfo[domains].length > 0
      ) {
        const domainsList: Domain[] = JSON.parse(currentUserTokenInfo[domains] ?? '[]');
        const domainsToQuery: number[] = domainsList.map(domain => domain.id);
        if (!!domainsToQuery && domainsToQuery.length > 0) {
          this.ovAutoService
            .getAll(Domain, domainsToQuery, 'adminlink')
            .then(result => {
              this.domains = result;
              const selectedDomain = SessionStorage.getSelectedDomain();

              if (!selectedDomain && this.domains.length > 0) {
                SessionStorage.setSelectedDomain(this.domains[0].id.toString());
              }
              this.selectedDomainId = +SessionStorage.getSelectedDomain();
            });
        }
      }
    });
  }

  setDefaultApps() {
    this.navbarApps = [
      {
        id: environment.appId.idm,
        title: 'IDM Link',
        url: environment.webUrl.idm,
        hasPermission: false
      },
      {
        id: environment.appId.admin,
        title: 'Admin Link',
        url: environment.webUrl.admin,
        hasPermission: false
      },
      {
        id: environment.appId.warehouse,
        title: 'Warehouse Link',
        url: environment.webUrl.warehouse,
        hasPermission: false
      },
      {
        id: environment.appId.yard,
        title: 'Yard Link',
        url: environment.webUrl.yard,
        hasPermission: false
      },
      {
        id: environment.appId.execution,
        title: 'Execution Link',
        url: environment.webUrl.execution,
        hasPermission: false
      },
      {
        id: environment.appId.ceramicPortal,
        title: 'Ceramic Portal',
        url: environment.webUrl.ceramicPortal,
        hasPermission: false
      }
    ];
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const misc = this.misc;

    if (this.misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function() {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function() {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function() {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  // isMobileMenu() {
  //   if (window.outerWidth < 991) {
  //     return false;
  //   }
  //   return true;
  // }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(function() {
      toggleButton.classList.add('toggled');
    }, 500);
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName('main-panel')[0]
    );
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName('main-panel')[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(() => {
        mainPanel.style.position = '';
      }, 500);
    }
  }

  sidebarToggle() {
    // var toggleButton = this.toggleButton;
    // var body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    let title = this.location.prepareExternalUrl(this.location.path());
    if (title.charAt(0) === '#') {
      title = title.slice(1);
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      const parent = this.listTitles[item];
      if (parent.path === title) {
        return parent.title;
      } else if (parent.children) {
        const children_from_url = title.split('/')[2];
        for (let current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return (
              (parent.title || '') + ' - ' + parent.children[current].title
            );
          }
        }
      }
    }
    return 'dashboard';
  }

  navigate(url: string) {
    window.location.assign(url);
  }

  setDomain(id: number) {
    SessionStorage.setSelectedDomain(id.toString());
    location.reload();
  }

  navigateBack() {
    this.location.back();
  }

}
