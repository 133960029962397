import { Component } from '@angular/core';
import { DropPenaltyBand } from '@ov-suite/adminlink-models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DropPenaltyBandService,
} from '@ov-suite/services-angular';
import { AddOrEditHelper } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-drop-penalty-band',
  templateUrl: './drop-penalty-band-add-or-edit.component.html',
  styleUrls: ['./drop-penalty-band-add-or-edit.component.scss']
})
export class DropPenaltyBandAddOrEditComponent extends AddOrEditHelper<DropPenaltyBand> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dropPenaltyBandService: DropPenaltyBandService,
  ) {
    super(route, router, DropPenaltyBand, dropPenaltyBandService, ['drop-penalty-band']);
    this.dataSources = {
      ...this.dataSources,
    };
  }
}
