import {
  OVEntity,
  OVField,
  OVForm,
  OVSidebar,
  OVTable
} from '@ov-suite/ov-metadata';
import { Plant } from './plant.model';
import { Factory } from '../adminlink/factory.model';
import { OriginType } from './origin-type.model';
import { Client } from './client.model';

@OVEntity('CeramicFactory', 'ceramic-portal')
@OVForm([['#Factory'], ['code']])
@OVSidebar([['id']])
@OVTable<CeramicFactory>([
  {
    key: 'id',
    title: 'Factory ID',
    type: 'string'
  }
])
export class CeramicFactory {
  // @SidebarField({ type: 'string', title: 'Unique ID', readonly: true })
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: () => Factory,
    title: 'Factory',
    placeholder: '-',
    selectionType: 'single',
    keys: ['id', 'name', 'factoryCode']
  })
  factory: Factory;

  @OVField({
    type: () => OriginType,
    title: 'Origin Type',
    placeholder: '-',
    selectionType: 'single'
  })
  originType: OriginType;

  @OVField({
    type: () => Plant,
    title: 'Plant',
    placeholder: '-',
    selectionType: 'single'
  })
  plant: Plant;

  @OVField({
    type: () => Client,
    title: 'Client',
    placeholder: '-',
    selectionType: 'single'
  })
  client: Client;
}
