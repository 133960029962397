import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input
} from '@angular/core';
import {
  AbstractValueAccessor,
  MakeProvider
} from '../input/abstruct-value-accessor';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from './format-datepicker';

@Component({
  selector: 'ov-suite-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [MakeProvider(DateTimeComponent),
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeComponent extends AbstractValueAccessor<Date>
  implements OnInit {
  date: Date;
  time = '00:00:00';
  @Input() disabled;
  @Input() showTime = true;

  ngOnInit(): void {}

  writeValue(value: Date) {
    if (!!value) {
      this.val = value;
      this.date = value;
      this.time = this.getTimeFromDate(value);
      this.onChange(this.setDate(this.date, this.time));
    }
  }

  set value(date: Date) {
    this.val = date;
    this.date = date;
    this.time = this.getTimeFromDate(date);
  }

  get value(): Date {
    return this.setDate(this.date, this.time);
  }

  valueChange() {
    console.log(this.date, this.time);
    this.onChange(this.setDate(this.date, this.time));
  }

  setDate(date: Date, time: string): Date {
    if (!!date && !!time && time.length > 0) {
      const timeArray = time.split(':');
      const newDate = new Date(date); // Reinitialisation must be done for some reason. Throws setUTCHours is not a function
      newDate.setHours(+timeArray[0], +timeArray[1], 0, 0);
      return newDate;
    }
    return null;
  }

  getTimeFromDate(date: Date): string {
    if (!!date) {
      const newDate = new Date(date); // Throws getUTCHours is not a function
      return [
        this.setFrontDigit(newDate.getHours().toString()),
        this.setFrontDigit(newDate.getMinutes().toString())
      ].join(':');
    }
    return '00:00';
  }

  setFrontDigit(timeValue: string) {
    return timeValue.length === 1 ? '0' + timeValue : timeValue;
  }
}
