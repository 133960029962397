import { OVField, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';
import { Terms } from './terms.model';
import { Transporter } from '../adminlink';

@OVEntity('CeramicTransporter', 'ceramic-portal')
export class CeramicTransporter {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: () => Transporter,
    title: 'Transporter',
    placeholder: 'Required',
    required: true,
  })
  transporter: Transporter;

  @OVSearchable()
  @OVField({
    type: () => Terms,
    title: 'Client Code',
    placeholder: 'Required',
    required: true,
    selectionType: 'simple'
  })
  terms: Terms[];

  @OVSearchable()
  @OVField({
    type: () => ['Shipment'],
    title: 'Shipment',
    placeholder: '-',
    required: false,
    keys: ['shipmentCode']
  })
  shipment: unknown[];

  @OVSearchable()
  @OVField({
    type: () => 'Rate',
    title: 'Rate',
    placeholder: '-',
    required: false,
  })
  rate: unknown;
}
