import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableTopNavTab } from './table-top-nav-menu-tabs.model';
import { Constructor, FieldMetadata } from '@ov-suite/ov-metadata';

@Component({
  selector: 'ov-suite-table-nav-menu',
  templateUrl: './table-top-nav-menu.component.html',
  styleUrls: ['./table-top-nav-menu.component.css']
})
export class TableTopNavMenuComponent implements OnInit {

  constructor() { }

  @Input() active = "";
  @Input() tabs: TableTopNavTab[] = [];
  @Input() title = "";
  @Input() backButtonLink: string | string[];
  @Output() tabClick = new EventEmitter<string>();
  @Input() downloadConstructor: Constructor;
  @Input() metadata: FieldMetadata;
  @Input() filterQuery: Record<string, string[]>;
  trigger = 0;

  ngOnInit() {
  }

  onTabClick(key) {
    this.active = key;
    this.tabClick.emit(key);
  }

  //TODO Refactor to Service
  onDownload(){
    this.trigger++;
  }

}
