import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'displayKey'})
export class MapDeepObjects implements PipeTransform {
  transform(object: {}, key: string): number {
    if (key.includes('.')) {
      return key.split('.').reduce((prev, curr) => {
        return prev ? prev[curr] : null;
      }, object || self);
    }
    return object[key];
  }
}
